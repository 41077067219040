import styled from 'styled-components';

export const DownloadContainer = styled.div`
  width: 100%;
  ${'' /* background: white; */}
  border-radius: 20px;
  ${'' /* box-shadow: 0px 0px 7px rgb(0 0 0 / 10%); */}
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 20px;
  flex-wrap: wrap;
`;

export const DownloadTextContainer = styled.div`
  width: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  background: white;
  padding: 30px;
  box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
  p {
    font-size: 14px;
    font-weight: 500;
    color: var(--grey);
    line-height: 1.5;
    margin: 0 0 10px;
  }

  h5 {
    font-size: 16px;
    margin-top: 10px;
    a {
      color: var(--key-color-2);
      text-decoration: underline;
    }
  }
`;

export const DownloadWrapper = styled.div`
  display: flex;
  flex: 1 1 300px;
  flex-grow: 2;
  flex-direction: column;
  align-items: center;
  align-content: center;
  sjustify-content: center;
  padding: 30px 0 20px;
  background: ${props => (props.recommended ? 'var(--key-color-2)' : 'var(--grey-3)')};
  border-radius: 20px;
  margin: 10px;
  position: relative;
  max-width: 350px;

  h5 {
    color: ${props => (props.recommended ? 'white' : 'var(--grey-1)')};
    font-size: 18px;
    text-align: center;
  }
  svg {
    color: ${props => (props.recommended ? 'white' : 'var(--grey-1)')};
    width: 150px;
  }

  a {
    text-decoration: none;
    width: 100%;
    height: 100%;
    bottom: 0;
    position: absolute;
  }
`;

export const StepContainer = styled.div`
  width: 100%;
  background: white;
  padding: 10px 30px;
  box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  h2 {
    color: var(--grey);
    padding: 20px 0;
    span {
      color: var(--grey-2);
      font-size: 20px;
    }
  }
`;

export const LineDiv = styled.div`
  height: 2px;
  width: 100%;
  background: var(--grey-2);
  margin: 20px 0;
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h5 {
    font-size: 18px;
    margin-bottom: 5px;
    width: 100%;
    span {
      color: var(--key-color-2);
      font-size: 20px;
    }
  }

  img {
    max-width: 500px;
    border-radius: 10px;
    margin-left: 10px;
  }

  @media (max-width: 768px) {
    img {
      width: 400px;
    }
  }

  div {
    width: 100%;
    display: flex;
    align-items: center;
    align-content: space-between;
    justify-content: space-between;
    border-radius: 20px;
    padding: 10px;
    img {
      max-width: 400px;
    }
    p {
      font-size: 14px;
      margin-right: 10px;
      line-height: 1.5;
      width: 60%;
    }
    ul {
      width: 60%;
      li {
        padding: 5px 0;
        font-size: 14px;
        line-height: 1.4;
        span {
          font-weight: bold;
        }
      }
    }
    a {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      cursor: pointer;
      width: 200px;
      padding: 20px;
      background: ${props => (props.recommended ? 'var(--key-color-2)' : 'var(--grey-3)')};
      border-radius: 20px;
      transition: 0.4s;
      &:hover {
        transition: 0.4s;
        transform: scale(1.1);
      }
      svg {
        color: ${props => (props.recommended ? 'white' : 'var(--grey-1)')};
        width: 100px;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      img {
        width: 200px;
        margin: 10px 0;
      }
      ul {
        width: 100%;
        padding-bottom: 10px;
      }
      p {
        width: 100%;
      }
    }
  }
`;
