import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';
import Form from '../../components/Form';
import { Check } from 'styled-icons/boxicons-regular/Check';
import { PWForgetContainer, FormWrapper, TextWrapper, IconWrapper } from './style';
import { ArrowLeft2 } from 'styled-icons/icomoon/ArrowLeft2';

const PasswordForgetPage = props => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [mailSent, setMailSent] = useState(false);

  const onSubmit = e => {
    e.preventDefault();
    props.firebase
      .doPasswordReset(email)
      .then(() => {
        setMailSent(true);
      })
      .catch(err => {
        setError(err);
      });
  };

  const isInValid = email === '';

  return (
    <PWForgetContainer>
      <img src={require('../../assets/fictive_logo_dark.svg')} alt="fictive reality logo" />
      {!mailSent ? (
        <FormWrapper>
          <IconWrapper>
          <Link to={ROUTES.SIGN_IN}><ArrowLeft2 /></Link> 
          </IconWrapper>
          <TextWrapper>
            <h1>Forgot your password?</h1>
            <p>Please enter your email adress to request a password reset.</p>
          </TextWrapper>
          <Form
            buttonText={'Send'}
            onSubmit={onSubmit}
            isInValid={isInValid}
            inputs={[
              {
                type: 'text',
                name: 'email',
                label: 'Email:',
                value: email,
                onChange: setEmail,
              },
            ]}
          />
          {error && <p>{error.message}</p>}
        </FormWrapper>
      ) : (
        <FormWrapper>
          <IconWrapper>
            <Link to={ROUTES.SIGN_IN}><ArrowLeft2 /></Link>
          </IconWrapper>
          <TextWrapper>
            <div>
              <Check />
            </div>
            <h1>Password Reset Email Sent</h1>
            <p>
              We have sent a reset password email to <span>{email}</span>. <br /> Please click the
              reset password link to set your new password.
            </p>
          </TextWrapper>
        </FormWrapper>
      )}
    </PWForgetContainer>
  );
};

const PasswordForgetLink = () => (
  <p style={{color: 'var(--grey-2)'}}>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot your Password?</Link>
  </p>
);

export default withFirebase(PasswordForgetPage);

export { PasswordForgetLink };
