import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';
import { Nav, NavContainer, UserInfoContainer, ListItem } from './style';
import { SuperAdmin } from '../../styles/icons';
import { TheaterMasks } from 'styled-icons/fa-solid/TheaterMasks';
import { Home } from 'styled-icons/fa-solid/Home';
import { ListUl } from 'styled-icons/fa-solid/ListUl';
import {PlayCircle} from 'styled-icons/boxicons-regular/PlayCircle';
import SignOutButton from '../SignOut';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

const Navigation = props => {
  return (
    <div>
      <AuthUserContext.Consumer>
        {authUser =>
          authUser &&
          props.location.pathname !== '/signin' && (
            <NavigationAuth
              location={props.location.pathname}
              authUser={authUser}
              history={props.history}
            />
          )
        }
      </AuthUserContext.Consumer>
    </div>
  );
};

const NavigationAuth = ({ location, authUser, history }) => {
  useEffect(() => {
    if (location) {
      document.title = 'Fictive Reality - ' + location.replace('/', '');
    }
  }, [location]);

  return (
    <Nav>
      <NavContainer>
        <img src={require('../../assets/fictive_logo_key_color.svg')} alt="fictive reality logo" />
        <ul>
          <ListItem selected={location === '/home'} data-for="home" data-tip={'<h5>Home</h5>'}>
            <Link to={ROUTES.HOME}>
              <Home />
            </Link>
            <ReactTooltip place="right" multiline={true} html={true} id="home"></ReactTooltip>
          </ListItem>
          <ListItem
            selected={location === '/scenarios'}
            data-for="scenarios"
            data-tip={'<h5>Scenarios</h5>'}
          >
            <Link to={ROUTES.SCENARIOS}>
              <TheaterMasks />
            </Link>
            <ReactTooltip place="right" multiline={true} html={true} id="scenarios"></ReactTooltip>
          </ListItem>
          <ListItem
            selected={location === '/sessions'}
            data-for="sessions"
            data-tip={'<h5>Sessions</h5>'}
          >
            <Link to={ROUTES.SESSIONS}>
              <ListUl style={{ width: '25px' }} />
            </Link>
            <ReactTooltip place="right" multiline={true} html={true} id="sessions"></ReactTooltip>
          </ListItem>
          {authUser &&
            authUser.claims &&
            (authUser.claims.clientAdmin || authUser.claims.superAdmin) && (
              <ListItem
                selected={location.includes('/admin')}
                data-for="admin"
                data-tip={'<h5>Admin</h5>'}
              >
                <Link to={ROUTES.ADMIN}>
                  <SuperAdmin />
                </Link>
                <ReactTooltip place="right" multiline={true} html={true} id="admin"></ReactTooltip>
              </ListItem>
            )}
          <ListItem selected={location === '/downloads'} data-for="downloads" data-tip={'<h5>Get started</h5>'}>
            <Link to={ROUTES.DOWNLOADS}>
              <PlayCircle />
            </Link>
            <ReactTooltip place="right" multiline={true} html={true} id="downloads"></ReactTooltip>
          </ListItem>
        </ul>
        {authUser && authUser.displayName && (
          <UserInfoContainer
            data-for="role"
            data-tip={
              authUser.claims && authUser.claims.superAdmin
                ? '<h5>Super Admin</h5> </b> <h6>' + authUser.displayName + '</h6>'
                : authUser.claims.clientAdmin
                ? '<h5>Client Admin</h5> </b> <h6>' + authUser.displayName + '</h6>'
                : '<h5>' + authUser.displayName + '</h5>'
            }
          >
            <ReactTooltip place="right" multiline={true} html={true} id="role"></ReactTooltip>
            <h1>
              {authUser.displayName.charAt(0).toUpperCase()}
            </h1>
          </UserInfoContainer>
        )}
        <SignOutButton dataCy={'mainLogout'} history={history} />
      </NavContainer>
    </Nav>
  );
};

export default withRouter(Navigation);

/* {authUser.displayName.split(' ').length > 1 &&
    authUser.displayName
    .split(' ')[1]
    .charAt(0)
    .toUpperCase()} */