import styled from 'styled-components';
import { smoothIn } from '../../styles/keyframes';

export const HomePageContainer = styled.div`
  width: 80%;
  padding: 0 0 150px;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: relative;
  > div:first-child {
    padding: 40px 0 0;
    width: 100%;
    margin-bottom: 20px;

    > h1 {
      color: var(--grey);
      font-weight: bold;
      padding: 0;
      margin: 0;
      font-size: 2em;
      line-height: 1;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 0 10px 150px;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  width: 100%;
  min-height: 250px;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

export const MainWrapper = styled.div`
  position: relative;
  overflow: hidden;
  flex: ${props => (props.flex ? props.flex : '1 1 780px')};
  height: 250px;
  min-width: 310px;
  background: ${props => (props.background ? props.background : 'white')};
  width: 50%;
  margin: ${props => (props.margin ? props.margin : '0')};
  border-radius: 20px;
  box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
  justify-content: center;
  padding: 30px;

  h2:first-letter {
    text-transform: uppercase;
  }

  img {
    width: 50%;
    position: absolute;
    right: 0;
    top: auto;
  }

  p {
    line-height: 1.5;
    color: white;
    font-size: 16px;
    font-weight: 600;
    width: 60%;
    ${'' /* max-width: 450px; */}

    span {
      text-decoration: underline;
      font-weight: 700;
    }
  }

  h3 {
    margin-bottom: 10px;
  }

  ul {
    width: 100%;
  }

  @media (max-width: 1000px) {
    margin: 5px 0;
    flex: 1 1 400px;
    height: auto;
  }

  @media (max-width: 768px) {
    img {
      display: none;
    }

    p {
      width: 80%;
    }
  }
`;

export const ScoreListItem = styled.li`
  margin: 10px 0;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: var(--grey);
  svg {
    color: ${props => (props.scoreColor ? props.scoreColor : 'var(--grey-1)')};
    border-radius: 10px;
    width: 35px;
    margin-right: 10px;
  }
  span {
    color: var(--grey-2);
    padding: 0 5px;
    text-decoration: underline;
  }
`;

export const LeaderBoardContainer = styled.div`
  z-index: 1;
  background: white;
  width: 100%;
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  transition: 0.6s ease-in-out;
`;

export const LeaderBoardUL = styled.ul`
  width: 100%;
  padding: 20px 10px;
  min-height: 300px;
  li {
    background: white;
    width: 100%;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    align-content: space-evenly;
    justify-content: space-evenly;
    border-bottom: 4px solid #f9fbfc;
    animation: ${smoothIn} 0.6s;

    svg {
      color: var(--grey-1);
      width: 18px;
      min-width: 18px;
      margin: 0 2px;
    }

    h3 {
      margin-right: 20px;
      font-size: 14px;
      width: 65px;
    }

    h2 {
      font-size: 16px;
      margin: 0 25px;
      color: var(--grey-1);
      min-width: 160px;
      text-transform: lowercase;
      span {
        display: block;
      }
      span::first-letter {
        text-transform: capitalize !important;
      }
      }
      h2::first-letter {
      text-transform: capitalize;
      }
    }

    h4 {
      font-size: 16px;
      margin: 0 25px;
      color: var(--grey-1);
      white-space: no-wrap;
      width: 200px;
      span {
        color: var(--grey-2);
        padding: 0 10px;
      }
    }
  }
  li:first-child {
    svg {
      color: gold;
      width: 30px;
      margin: 0;

    }
  }
  li:last-child {
    border: none;
  }
  li:nth-child(2) {
    svg {
      color: silver;
      width: 26px;
      margin: 0;
    }
  }
  li:nth-child(3) {
    svg {
      color: #cd7f32;
      width: 18px;
    }
  }
  li:nth-child(4) {
    svg {
      color: #cd7f32;
      width: 18px;
    }
  }
  li:nth-child(5) {
    svg {
      color: #cd7f32;
      width: 18px;
    }
  }

  @media (max-width: 768px) {
    li {
      h2 {
        min-width: unset;
      }
    }
  }
`;

export const CurrentUserContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  position: relative;

  button {
    outline: none;
    background: transparent;
    padding: 8px 20px;
    border-radius: 20px;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
    color: var(--grey-1);
    border: 2px solid var(--grey-1);
    transition: 0.4s;
    position: absolute;
    bottom: -70px;

    &:hover {
      transition: 0.4s;
      transform: scale(1.04);
    }
  }
`;

export const ShowMoreButton = styled.button`
  outline: none;
  background: transparent;
  padding: 8px 20px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  color: var(--grey-1);
  border: 2px solid var(--grey-1);
  transition: 0.4s;
  margin: 10px 0 0;

  &:hover {
    transition: 0.4s;
    transform: scale(1.04);
  }
`;

export const CurrentUserWrapper = styled.div`
  background: var(--grey-5);
  width: 100%;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  align-content: space-evenly;
  justify-content: space-evenly;
  border-radius: 20px;
  animation: ${smoothIn} 0.6s;
  margin-top: 5px;

  svg {
    color: var(--grey-1);
    width: 18px;
    min-width: 18px;
  }

  h3 {
    margin-right: 20px;
    font-size: 14px;
    width: 65px;
  }

  h2 {
    font-size: 16px;
    margin: 0 25px;
    color: var(--grey-1);
    min-width: 160px;
  }

  h4 {
    font-size: 16px;
    margin: 0 25px;
    color: var(--grey-1);
    white-space: no-wrap;
    width: 200px;
    span {
      color: var(--grey-2);
      padding: 0 10px;
    }
  }
`;

export const PlayButton = styled.button`
  background: var(--key-color-2-gradient);
  animation: ${smoothIn} 0.6s;
  position: absolute;
  bottom: -35px;
  right: -35px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  box-shadow: 5px 10px 20px rgb(0 0 0 / 30%);
  transition: 0.4s;
  &:hover {
    transition: 0.4s;
    transform: scale(1.1);
  }
  svg {
    width: 40px;
    color: white;
    margin-left: 10px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const TopScoreContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: space-between;
  flex-wrap: no-wrap;
  @media (max-width: 1200px) {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;

export const TopScoreWrapper = styled.button`
  border: 3px solid ${props => (props.border ? props.border : 'white')};
  outline: none;
  cursor: pointer;
  display: flex;
  background: white;
  width: 32%;
  min-width: 295px;
  margin: 5px;
  padding: 10px 15px;
  border-radius: 20px;
  box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
  align-items: center;
  height: calc(100% - 10px);
  transition: 0.4s;
  :first-child {
    margin: 0;
  }
  :last-child {
    margin: 0;
  }
  h2 {
    line-height: 1;
    margin-left: 10px;
    font-size: 22px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-contet: flex-start;
    text-align: left;
    span {
      font-size: 16px;
      line-height: 1.5;
      color: var(--grey-2);
    }
  }
  svg {
    padding: 10px;
    background: ${props => (props.color ? props.color : 'white')};
    border-radius: 50%;
    max-width: 60px;
    width: auto;
    height: auto;
    color: white;
  }

  &:hover {
    transition: 0.4s;
    box-shadow: 0px 0px 7px rgb(129 105 255);
  }

  @media (max-width: 1200px) {
    height: auto;
    width: 100%;
    margin: 5px 0;
  }
`;

export const HelpContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 88px;
  width: 100%;
  background: white;
  display: flex;
  align-items: center;
  padding: 15px 40px;
  box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
  overflow: hidden;
  animation: ${smoothIn} 0.6s;
  z-index: 5;

  h2 {
    margin-right: 10px;
    color: var(--key-color-2);
    font-size: 18px;
    white-space: nowrap;
  }

  h4 {
    color: var(--grey-1);
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;

    a {
      text-decoration: underline;
      cursor: pointer;
      transition: 0.4s;
      &:visited {
        color: var(--grey-1);
      }
      &:hover {
        transition: 0.4s;
        color: var(--key-color-2);
      }
    }
  }

  img {
    position: absolute;
    width: 90%;
    height: 100%;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  @media (max-width: 768px) {
    left: 0;
    flex-direction: column;
  }
`;
