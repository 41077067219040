export const formatSessionLength = (sessionLen, showNumber) => {
  let seconds = Math.floor((sessionLen / 1000) % 60);
  let minutes = Math.floor((sessionLen / (1000 * 60)) % 60);
  let hours = Math.floor((sessionLen / (1000 * 60 * 60)) % 24);

  return `${hours >= 1 ? hours + 'h' : ''} ${minutes >= 1 ? minutes + 'm' : ''} ${
    seconds >= 1 ? seconds + 's' : showNumber ? '0' : '-'
  }`;
};

export const formatSessionDate = sessionDate => {
  if (sessionDate && sessionDate.length !== '') {
    let newDate = new Date(sessionDate).toString();
    let dateArr = newDate.slice(0, 21).split(' ');
    return `${dateArr[2]} ${dateArr[1]} ${dateArr[3]} ${dateArr[4]}`;
  } else {
    return '-';
  }
};

export const averageSessionLength = sessions => {
  let sessionLength = 0;
  sessions.forEach(session => {
    if (session.session_length) {
      sessionLength += parseInt(session.session_length);
    }
  });
  let average = Math.floor(sessionLength / sessions.length);

  return average;
};

export const getEventData = (events, exchangesCount, bestAnswers) => {
  let playerEvents = events.filter(event => event.event_type === 'PLAYER_STATEMENT');
  if (playerEvents && playerEvents.length >= 1) {
    let playerScores = playerEvents
      .map(ps => {
        if (
          bestAnswers &&
          ps.parameter &&
          bestAnswers[ps.parameter.slice(9, ps.parameter.length)] === true
        ) {
          return 30;
        } else {
          return ps.score;
        }
      })
      .filter(score => score !== null && score !== undefined);
    let sum = (a, b) => a + b;
    let tempSkillLevel = 0;
    if (playerScores && playerScores.length >= 1) {
      tempSkillLevel = (
        (playerScores.reduce(sum) * 100) /
        (parseInt(exchangesCount, 10) * 20)
      ).toFixed(0);
    }
    return { skillLevel: tempSkillLevel, scores: playerScores };
  } else {
    return 0;
  }
};

const getBestAnswers = (sessionId, userExchanges) => {
  if (sessionId && userExchanges) {
    let bestAnswers = {};
    Object.keys(userExchanges).forEach(key => {
      if (userExchanges[key] && userExchanges[key].audioAnswer && userExchanges[key].audioAnswer === sessionId) {
        bestAnswers[key] = true;
      }
    });
    if (bestAnswers) {
      return bestAnswers;
    }
  } else {
    return null;
  }
};

const sortableDateFormat = (date) => {
  if(date) {
    let dateFormat = new Date(date);
    return dateFormat;
  } else {
    return null;
  }
}

export const mapSessionsData = (sessions, scenarios, user) => {
  if (sessions && sessions.length >= 1) {
    const mappedSessions = sessions.map(session => {
      let sessScenario = scenarios.find(scen => scen && scen.id === session.scenario_id);
      let eventDataObj = null;
      let bestAnswers = null;
      let exchangesCount =
        sessScenario && sessScenario.settings && sessScenario.settings.exchangesCount;
      if (
        user &&
        user.scenarios &&
        sessScenario &&
        user.scenarios[sessScenario.id] &&
        user.scenarios[sessScenario.id].exchanges
      ) {
        bestAnswers = getBestAnswers(session.id, user.scenarios[sessScenario.id].exchanges);
      }
      if (exchangesCount && session.events && session.events.length >= 1) {
        eventDataObj = getEventData(
          session.events,
          exchangesCount,
          bestAnswers ? bestAnswers : null,
        );
      }
      return {
        scenarioName:
          sessScenario && sessScenario.settings && sessScenario.settings.scenarioName
            ? sessScenario.settings.scenarioName
            : session.scenario_id,
        date: sortableDateFormat(session.date),
        sessionLength: session.session_length,
        // skillLevel:
        //   eventDataObj && eventDataObj.skillLevel ? eventDataObj.skillLevel + '%' : '0%',
        scores:
          eventDataObj && eventDataObj.scores && eventDataObj.scores.length >= 1
            ? eventDataObj.scores
            : null,
        exchangesCount: exchangesCount ? exchangesCount : null,
        userId: session.user_id,
        sessionId: session.id,
        clientId: session.clientId ? session.clientId : session.client_id,
      };
    });
    return mappedSessions;
  } else {
    return null;
  }
};
