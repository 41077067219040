import React from 'react';
import {
  ButtonContainer,
} from './style';

const MenuButton = (props) => {

  const handleClick = () => {
    props.history.push(props.path);
    props.menuToggle();
  }

  return (
    <ButtonContainer
      height={props.height}
      onClick={() => handleClick()}
      history={props.history.location.pathname}
      title={props.title}
      path={props.path}
    >
      {props.image}
      <p>{props.title}</p>
    </ButtonContainer>
  );
};

export default MenuButton;