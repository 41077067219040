import React, { useEffect } from 'react';
import { useTable, useSortBy, useExpanded, usePagination } from 'react-table';
import { StyledTable, PaginationContainer } from './style';
import { ChevUp, ChevDown } from '../../styles/icons';

const DataTable = ({
  columns,
  data,
  updateMandatory,
  updateVisibility,
  renderRowSubComponent,
  initialSort,
  updateData,
  clientToEdit,
  originalData,
  paginationOff,
  hiddenColumns
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    setPageSize,
    // pageIndex
    state: { pageSize },
    prepareRow,
    visibleColumns,
    // defaultColumn,
  } = useTable(
    {
      columns,
      data,
      originalData,
      updateMandatory,
      updateVisibility,
      clientToEdit,
      updateData,
      sortDescFirst: true,
      autoResetPage: false,
      autoResetSortBy: false,
      initialState: {
        // pageIndex: 0,
        pageSize: paginationOff ? data.length : 5,
        sortBy: initialSort ? initialSort : [{ id: 'name', desc: true }],
        hiddenColumns: hiddenColumns ? hiddenColumns : []
      },
    },
    useSortBy,
    useExpanded,
    usePagination,
  );

  useEffect(() => {
    if((data && data.length <= 5) || (data && data.length < pageSize)) {
      setPageSize(data.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data])

  return (
    <>
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th key={index} {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                    <span style={{ cursor: 'pointer' }}>
                      {column.isSorted ? column.isSortedDesc ? <ChevDown /> : <ChevUp /> : ''}
                    </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <React.Fragment key={index}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td key={index} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
        </tbody>
      </StyledTable>
      {!paginationOff && <PaginationContainer>
        {data.length >= 5 && <h5>{'Showing ' + pageSize + ' / ' + data.length + ' rows'}</h5>}
        {data.length < 5 && <h5>{'Showing ' + data.length + ' / ' + data.length + ' rows'}</h5>}
        {pageSize < data.length && data.length >= 5 && (
          <button onClick={() => setPageSize(data.length)}>Show all</button>
        )}
        {pageSize >= data.length && data.length > 5 && (
          <button onClick={() => setPageSize(5)}>Show less</button>
        )}
      </PaginationContainer>}
    </>
  );
};

export default DataTable;
