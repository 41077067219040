import React from 'react';
import { Vr, Dash, SuperAdmin } from '../../styles/icons';
// import StageListItem from '../StageListItem';
import MenuButton from '../MenuButton';
import SignOutButton from '../SignOut';
import {
  SliderContainer,
  OriginalMenuContainer,
  ContentWrapper,
  ContentContainer,
  // WalkthroughWrapper,
  // WalkthroughContainer,
  // NewMenu,
  // User,
} from './style';
import * as ROUTES from '../../constants/routes';

// const array = [
//   { number: 1, text: 'Prepare' },
//   { number: 2, text: 'VR' },
//   { number: 3, text: 'Review' },
//   { number: 4, text: 'VR' },
//   { number: 5, text: 'Results' },
//   { number: 6, text: 'Meeting' },
// ];

const SideMenu = props => {
  // const renderWalkthrough = () => {
  //   return array.map(e => {
  //     return <StageListItem key={e.number} item={e} />;
  //   });
  // };
  //console.log('sidemenuprops', props.authUser.claims.clientAdmin);

  //temp solution awaiting a bigger structural fix
  // const maybeRenderRightSide = () => {
  //   if (props.username !== null || undefined) {
  //     return (
  //       <NewMenu>
  //         <User>
  //           <Acc />
  //           {props.username}
  //         </User>
  //         {/* <WalkthroughWrapper>
  //           <WalkthroughContainer>{renderWalkthrough()}</WalkthroughContainer>
  //         </WalkthroughWrapper> */}
  //       </NewMenu>
  //     );
  //   }
  // };

  // const showSuperuser = () => {
  //   if (true) {
  //     return (
  //       <MenuButton
  //         menuToggle={props.menuToggle}
  //         history={props.history}
  //         title={'SuperUser'}
  //         path={ROUTES.SUPERUSER}
  //         image={<Vr />}
  //       />
  //     );
  //   }
  // };

  return (
    <SliderContainer data-cy="sidemenu" open={props.openMenu}>
      <OriginalMenuContainer>
        <img style={{width: '50px'}} src={require('../../assets/fictive-logo.svg')} alt="fictive reality logo" />
        <ContentWrapper>
          <ContentContainer>
            <MenuButton
              menuToggle={props.menuToggle}
              history={props.history}
              title={'Home'}
              path={'/home'}
              image={<Dash style={{ marginBottom: '4px' }} />}
            />
            <MenuButton
              menuToggle={props.menuToggle}
              history={props.history}
              title={'Scenarios'}
              path={ROUTES.SCENARIOS}
              image={<Vr />}
            />
            <MenuButton
              menuToggle={props.menuToggle}
              history={props.history}
              title={'Admin'}
              path={ROUTES.ADMIN}
              image={<SuperAdmin />}
            />
          </ContentContainer>
          <SignOutButton dataCy={"optLogout"}/>
        </ContentWrapper>
      </OriginalMenuContainer>
      {/* {maybeRenderRightSide()} */}
    </SliderContainer>
  );
};

export default SideMenu;
