import styled from 'styled-components';

export const SessionsListContainer = styled.div`
  width: 80%;
  min-height: 100vh;
  max-width: 1400px;
  padding: 0 0 30px;

  h2 {
    text-align: left;
    color: var(--grey);
    padding: 30px 0;
  }

  ${'' /* table {
    width: 100%;
    min-width: 500px;
    font-size: 14px;
  } */}

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 10px 30px;
  }
`;

export const SessionsWidgetContainer = styled.div`
  width: 100%;
  overflow-x: scroll;

  table {
    width: 100%;
    font-size: 14px;
  }
`;
