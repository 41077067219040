import React, { useEffect, useState, useContext, useMemo } from 'react';
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import { compose } from 'recompose';
import Loading from '../../components/Loading';
import * as ROUTES from '../../constants/routes';
import { AdminFilterContext } from '../../components/AdminFilter/AdminFilterContext';
import { ScoreMeter } from '../../components/ScenarioFollowUp';
import { PageTitle } from '../Admin/AdminHeader';
import {
  formatSessionDate,
  formatSessionLength,
  mapSessionsData,
} from '../../helpFunctions/session';
import DataTable from '../../components/DataTable';
import { SessionsListContainer, SessionsWidgetContainer } from './style';
import { FrButton, FrDiv } from '../../components/DesignSystem/style.js';
import { SESSIONS } from '../../constants/loadingMessages';
import { withRouter } from 'react-router-dom';

const SessionsList = props => {
  const { state } = useContext(AdminFilterContext);
  const [sessionsData, setSessionsData] = useState(null);
  const [clientUsersObj, setClientUsersObj] = useState(null);
  const [sharedSessions, setSharedSession] = useState(null);

  const sessionsColumn = useMemo(
    () => [
      {
        Header: 'Username',
        id: 'userId',
        Cell: ({ row }) => (
          <>
            {clientUsersObj && clientUsersObj[row.original.userId] ? (
              <h4>{clientUsersObj[row.original.userId].displayName}</h4>
            ) : (
              <h4>{row.original.userId}</h4>
            )}
          </>
        ),
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ row }) => formatSessionDate(row.original.date),
      },
      {
        Header: 'Scenario',
        accessor: 'scenarioName',
        Cell: ({ row }) => <h5>{row.original.scenarioName}</h5>,
      },
      {
        Header: 'Length',
        accessor: 'sessionLength',
        Cell: ({ row }) => formatSessionLength(row.original.sessionLength),
      },
      {
        Header: 'Grading',
        Cell: ({ row }) => (
          <ScoreMeter scores={row.original.scores} exchangesCount={row.original.exchangesCount} />
        ),
      },
      {
        Header: () => null,
        accessor: 'view',
        Cell: ({ row }) => (
          <FrDiv alignItems={'center'} justifyContent={'flex-end'} width={'100%'}>
            <FrButton
              onClick={() =>
                props.history.push(
                  `${ROUTES.SESSIONS}/${row.original.clientId}:${row.original.userId}:${row.original.sessionId}`,
                )
              }
              padding={'6px 26px'}
              background={'transparent'}
              border={'2px solid var(--grey-2)'}
              color={'var(--grey-2)'}
              boxShadow={'transparent'}
              disabled={!row.original.clientId || !row.original.userId || !row.original.sessionId}
            >
              View
            </FrButton>
          </FrDiv>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientUsersObj],
  );

  const getAllClientSessions = (sharedSessionsList, client) => {
    props.firebase.clientSessions(client).once('value', snapshot => {
      const clientSessionsObj = snapshot.val();
      if (clientSessionsObj) {
        let sharedSessionsIdsMappedWithSessions = sharedSessionsList
          .filter(s => clientSessionsObj[s.userId] && clientSessionsObj[s.userId][s.id])
          .map(session => {
            return {
              id: session.id,
              clientId: session.clientId,
              ...clientSessionsObj[session.userId][session.id],
            };
          });
        let tempSharedSessions = mapSessionsData(
          sharedSessionsIdsMappedWithSessions,
          state.scenarios.filter(scenario => scenario.visible === true),
        );
        if (tempSharedSessions && tempSharedSessions.length > 0) {
          setSharedSession(tempSharedSessions);
        } else {
          setSharedSession([]);
        }
      } else {
        setSharedSession([]);
      }
    });
  };

  const getSharedSessions = client => {
    props.firebase.sharedSessions().on('value', snapshot => {
      const sharedSessionsObj = snapshot.val();
      if (sharedSessionsObj) {
        const tempSharedSessionsList = Object.keys(sharedSessionsObj)
          .filter(
            k =>
              sharedSessionsObj[k].sharedWith &&
              sharedSessionsObj[k].sharedWith[props.authUser.userId] === true,
          )
          .map(key => {
            let sessionPath = key.split(':');
            return { id: sessionPath[2], userId: sessionPath[1], clientId: sessionPath[0] };
          });

        if (tempSharedSessionsList && tempSharedSessionsList.length !== 0) {
          getAllClientSessions(tempSharedSessionsList, client);
        } else {
          setSharedSession([]);
        }
      } else {
        setSharedSession([]);
      }
    });
  };

  const getClientUsers = client => {
    props.firebase
      .newUsers()
      .orderByChild('clients/' + client)
      .equalTo(true)
      .once('value', snapshot => {
        const tempClientUsersObj = snapshot.val();
        if (tempClientUsersObj) {
          setClientUsersObj(tempClientUsersObj);
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      props.firebase.sharedSessions().off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!state.loading && state.scenarios && state.sessions && state.selectedClient) {
      setSessionsData(
        mapSessionsData(state.sessions, state.scenarios, props.authUser ? props.authUser : null),
      );
      getClientUsers(state.selectedClient);
      getSharedSessions(state.selectedClient);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loading, state.selectedClient]);

  return (
    <SessionsListContainer>
      {!state.loading && <PageTitle title={'Sessions'} withDate={false} />}
      {sessionsData && (
        <SessionsWidgetContainer>
          <DataTable
            columns={sessionsColumn}
            data={sessionsData}
            hiddenColumns={['userId']}
            initialSort={[{ id: 'date', desc: true }]}
          />
        </SessionsWidgetContainer>
      )}

      {state.loading && <Loading loadingText={SESSIONS} width={'80px'} containerHeight={'400px'} />}

      {!state.loading && !state.sessions && !sessionsData && (
        <h3 style={{ padding: 0 }}>Hmm, it appears that you don't have any played sessions...</h3>
      )}

      <h2 style={{ padding: '30px 0 15px 0' }}>Sessions shared with you</h2>
      {sharedSessions && sharedSessions.length > 0 && state.scenarios && (
        <SessionsWidgetContainer>
          <DataTable
            columns={sessionsColumn}
            data={sharedSessions}
            initialSort={[{ id: 'date', desc: true }]}
          />
        </SessionsWidgetContainer>
      )}
      {sharedSessions && sharedSessions.length === 0 && (
        <h3 style={{ padding: 0 }}>Hmm, it appears that you don't have any played sessions...</h3>
      )}
      {!sharedSessions && <Loading width={'80px'} containerHeight={'200px'} />}
    </SessionsListContainer>
  );
};

const SessionListWidget = ({ sessions, scenarios, authUser, history }) => {
  const [sessionsData, setSessionsData] = useState(null);

  const sessionsColumn = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ row }) => formatSessionDate(row.original.date),
      },
      {
        Header: 'Scenario',
        accessor: 'scenarioName',
        Cell: ({ row }) => <h5>{row.original.scenarioName}</h5>,
      },
      {
        Header: 'Length',
        accessor: 'sessionLength',
        Cell: ({ row }) => formatSessionLength(row.original.sessionLength),
      },
      {
        Header: 'Grading',
        Cell: ({ row }) => (
          <ScoreMeter scores={row.original.scores} exchangesCount={row.original.exchangesCount} />
        ),
      },
      {
        Header: () => null,
        accessor: 'view',
        Cell: ({ row }) => (
          <FrDiv alignItems={'center'} justifyContent={'flex-end'} width={'100%'}>
            <FrButton
              onClick={() =>
                history.push(
                  `${ROUTES.SESSIONS}/${row.original.clientId}:${row.original.userId}:${row.original.sessionId}`,
                )
              }
              padding={'6px 26px'}
              background={'transparent'}
              border={'2px solid var(--grey-2)'}
              color={'var(--grey-2)'}
              boxShadow={'transparent'}
              disabled={!row.original.clientId || !row.original.userId || !row.original.sessionId}
            >
              View
            </FrButton>
          </FrDiv>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (scenarios && sessions && authUser && !sessionsData) {
      setSessionsData(
        mapSessionsData(
          sessions,
          scenarios.filter(scenario => scenario.visible === true),
          authUser ? authUser : null,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenarios, sessions, authUser]);

  return (
    <SessionsWidgetContainer>
      {sessionsColumn && sessionsData && sessionsData.length !== 0 && (
        <DataTable
          columns={sessionsColumn}
          data={sessionsData}
          initialSort={[{ id: 'date', desc: true }]}
          paginationOff={true}
        />
      )}
    </SessionsWidgetContainer>
  );
};

export const SessionWidget = withRouter(SessionListWidget);

const condition = authUser => !!authUser;

export default compose(withAuthorization(condition), withFirebase)(SessionsList);
