export const LANDING = '/';
export const SIGN_IN = '/signin';
export const SIGN_UP = '/signup';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const SESSIONS = '/sessions'
export const SESSION = '/session';
export const PASSWORD_FORGET = '/pw-forget';
export const SCENARIOS = '/scenarios';
export const SCENARIO = '/scenario';
export const ADMIN = '/admin';
export const SCENARIO_EDITOR = '/admin/scenario-editor';
export const SUPERUSER = '/superuser';
export const DOWNLOADS = '/downloads';
export const UPLOAD = '/upload';
