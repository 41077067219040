import styled from 'styled-components';
import { smoothIn } from '../../styles/keyframes';

export const ScenarioPageContainer = styled.div`
  width: 80%;
  min-height: 100vh;
  display: flex;
  font-family: 'Montserrat';
  flex-direction: column;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  position: relative;
  background: var(--background-color);
  transition: 0.4s;
  animation: ${smoothIn} 0.4s ease-in-out;
  max-width: 1200px;

  textarea {
    font-family: 'Montserrat';
    color: var(--grey);
  }
  audio {
    outline: none;
  }

  @media (max-width: 768px) {
    padding: 10px;
    width: 100%;
  }
`;

export const ScenarioPageWrapper = styled.div`
  padding: ${props => (props.padding ? props.padding : '0')};
  width: 100%;
`;

export const ScenarioInfoText = styled.div`
  width: ${props => (props.width ? props.width : '100%')};
  margin: 2em 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;

  @media (max-width: 768px) {
    padding: 0 1em;
    text-align: center;
    width: 100%;
  }
`;

export const PageTitle = styled.div`
  width: 100%;
  h1 {
    color: var(--grey);
    font-weight: bold;
    padding: 0;
    margin: 0;
    font-size: ${props => (props.fontSize ? props.fontSize : '2em')};
    line-height: 1;

    span {
      font-size: 0.5em;
      padding: 0 2px;
    }
  }
  @media (max-width: 768px) {
    padding: 1em 0em;
    h1 {
      color: #5b5b5b;
      font-weight: bold;
      padding: 0;
      margin: 0;
      font-size: 2.5em;
      line-height: 1;
      text-align: center;

      span {
        text-align: center;
        font-size: 0.5em;
        padding: 0 2px;
      }
    }
  }
`;

export const ScenarioContentContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0 0;
  height: 100%;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const ScenarioTextContainer = styled.div`
  width: 100%;
  padding: ${props => (props.padding ? props.padding : '1em 0em')};
  transition: 0.4s;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const ScenarioText = styled.div`
  border-radius: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  ${'' /* padding: 2em 2.5em 2em 2.5em; */}

  p {
    font-weight: 500;
    line-height: 1.5;
    padding: 0.5em 0;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    text-align: center;
    padding: 1em 1em;
    border-radius: 0;
  }
`;

export const HeadLine = styled.h2`
  color: var(--grey);
  padding: ${props => props.padding && props.padding};
  text-align: left;
  width: 100%;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 1.5em;
  }
`;

export const DialogueAudioContainer = styled.div`
  width: 100%;
  background: white;
  margin: 1em 0;
  padding: 1em;
  border-radius: 14px;

  ${'' /* h4 {
    padding: 10px;
    color: var(--grey-1);
  } */}

  audio {
    cursor: pointer;
    width: 100%;
    border-radius: 20px;
    background: #f1f3f4;
    z-index: 10;
  }
`;

export const ExchangeContainer = styled.div`
  display: flex;
  align-items: ${props => (props.step === 1 ? 'center' : 'flex-start')};
  align-content: ${props => (props.step === 1 ? 'center' : 'flex-start')};
  justify-content: ${props => (props.step === 1 ? 'center' : 'flex-start')};
  margin: ${props => (props.step >= 3 ? '3em 0' : '0')};
  position: relative;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    background: var(--background-color-white);
    box-shadow: 0px 2px 5px var(--grey-3);
  }
`;

export const Dialogue = styled.div`
  width: 100%;
  min-height: 30vh;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;

  ul {
    width: 100%;
    overflow: auto;
    margin-top: ${props => (props.step === 1 ? '2em' : '0')};
    ${ExchangeContainer}:first-child {
      margin-top: 2em;
    }
    ::-webkit-scrollbar {
      display: none;
    }
    background: ${props => (props.step === 1 ? 'white' : 'transparent')};
    border-radius: ${props => (props.step === 1 ? '10px' : '0')};
    overflow-x: hidden;
    font-size: 14px;
    font-weight: 500;
  }

  li {
    width: 100%;
    margin: 1em 0;
  }
`;

export const ExchangeQuestionTitle = styled.h4`
  color: var(--grey-3);
  padding: 0 0 0.8em;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const ExchangeDialogue = styled.div`
  ${'' /* width: ${props => (props.step === 1 ? '100%' : '70%')}; */}
  animation: ${smoothIn} .6s;
  transition: .4s;
  width: 100%;
  background: white;
  padding: 1.5em;
  border-radius: ${props => (props.step >= 3 ? '10px' : '0')};
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ScoreTab = styled.div`
  background: ${props => props.scoreColor};
  width: 125px;
  position: absolute;
  top: -22px;
  right: 20px;
  border-radius: 20px 20px 0 0;
  padding: 5px 10px;
  z-index: 100;
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  z-index: 0;
`;

export const DialogueMessageBot = styled.li`
  max-width: ${props => (!props.editor ? '55%' : '85%')};
  border-radius: 20px;
  border-left: 20px solid;
  border-color: var(--orange);
  background: var(--grey-5);
  position: relative;

  h3 {
    position: absolute;
    top: -22px;
    right: 0;
    width: 100%;
    text-align: left;
    color: var(--orange);
    padding: 0;
    font-size: 0.9em;
  }

  div {
    width: 100%;
    animation: ${smoothIn} 0.4s;
    padding: 1em;

    p {
      width: 100%;
      line-height: 1.4;
      ${'' /* text-align: ${props => (props.step === 1 ? 'center' : 'left')}; */}
      text-align: center;
    }
  }
  @media (max-width: 768px) {
    max-width: initial;
    min-width: initial;
  }
`;

export const DialogueMessageTeacher = styled.li`
  max-width: ${props => (!props.editor ? '55%' : '85%')};
  border-radius: 20px;
  border-right: 20px solid;
  border-color: var(--teal);
  background: var(--grey-5);
  position: relative;
  overflow: hidden;

  div {
    animation: ${smoothIn} 0.4s;

    p {
      line-height: 1.4;
      ${'' /* text-align: ${props => (props.step === 1 ? 'center' : 'left')}; */}
      text-align: center;

      width: 100%;
    }
  }
  @media (max-width: 768px) {
    max-width: initial;
    min-width: initial;
  }
`;

export const AnswerTitle = styled.h6`
  font-size: 12px;
  width: 100%;
  text-align: center;
  padding: 10px 10px;
  font-weight: bold;
`;

// Example Container
export const ExampleContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0 0 0 20px;
  background: ${props => (props.step === 3 ? 'var(--grey-4)' : 'transparent')};
`;

export const Expand = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;

  span {
    cursor: pointer;
    font-size: 10px;
    font-weight: bold;
    display: inline;
  }
  svg {
    cursor: pointer;
    transition: 0.4s;
    width: 20px;
  }
`;
// ------------- ^ -------------

export const ChosenContainer = styled.div`
  border-radius: ${props => (props.step === 1 ? '20px 0 0 0' : '0')};
  background: ${props => (props.step === 3 ? 'var(--grey-5)' : 'var(--grey-4)')};
  ${'' /* padding: ${props => props.step === 3 ? '0' : '1em'}; */}
  padding: 0;
`;

export const DialogueContentContainer = styled.div`
  position: relative;
  display: ${props => (props.showExample === true ? 'flex' : 'none')};
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 2em;
  background: ${props => (props.step === 3 ? 'var(--grey-4)' : 'var(--grey-5)')};
  border-radius: 0 0 0 20px;
  ${'' /* border-radius: 20px 0 0 20px; */}

  p {
    font-weight: 500;
  }
`;

export const AnswerStep1 = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  ${'' /* background: var(--grey-4); */}
  ${'' /* border-radius: 20px 0 0; */}

  p {
    font-weight: 500;
  }
`;

export const TipContainer = styled.div`
  position: absolute;
  right: ${props => (!props.editor ? '56%' : '90%')};

  top: 30px;
  cursor: pointer;
  text-align: center;

  div {
    max-width: 400px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    display: block;
    border-radius: 5px;
  }

  svg {
    color: var(--grey-1);
    width: 1.6em;
    transition: 0.4s;
  }
  &:hover {
    transition: 0.4s;
    svg {
      color: var(--key-color-2);
    }
  }
`;
// --------Last answer------
export const LastAnswerContainer = styled.div`
  display: flex;
  border-radius: 20px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0 1em 1em;
`;

export const LastAnswerWrapper = styled.div`
  width: 100%;
  textarea {
    transition: 0.4s;
    text-align: center;
    background: white;
    width: 100%;
    resize: none;
    border: none;
    outline: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'montserrat';
    color: var(--grey);
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const ErrorContainer = styled.div`
  transition: 0.4s;
  border-radius: 5px;
  padding-bottom: ${props => (props.textFieldEmpty === true ? '10px' : '0')};
  background: ${props => (props.textFieldEmpty === true ? 'rgba(255, 68, 68, .1)' : 'transparent')};
`;

export const ErrorMessage = styled.p`
  transition: 0.4s;
  padding: 5px;
  color: var(--red);
`;

export const LastAnswerButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  button {
    background: ${props => (props.saved === true ? 'var(--key-color)' : 'var(--key-color-2)')};
    color: white;
    outline: none;
    border: none;
    width: 90%;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 12px;
    font-weight: bold;
  }
`;
//-------------^-----------------

export const DialogueMessagePlayer = styled.div`
  border-radius: 20px;
  ${'' /* border: 2px solid;
  border-right: 15px solid;
  border-color: ${props => props.scoreColor}; */}
  background: var(--grey-6);
  position: relative;

  h3 {
    position: absolute;
    top: -22px;
    width: 100%;
    text-align: right;
    ${'' /* color: ${props => props.scoreColor}; */}
    color: var(--grey);
    padding: 0;
    font-size: 0.9em;
  }
`;

export const AudioContainer = styled.div`
  animation: ${smoothIn} 0.4s;
  padding: 16px;
  ${'' /* background: var(--grey-4); */}
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  audio {
    ${'' /* margin-right: 10px; */}
    width: 100%;
  }
`;

export const AnswerContainer = styled.div`
  width: 100%;
  color: var(--grey-2);
  padding: 0 1em !important;
  font-weight: 500;
  display: flex;
  justify-content: center;

  textarea {
    border: ${props => (props.textFieldEmpty === true ? '2px solid var(--red)' : 'none')};
  }
`;

export const TextAnswerContainer = styled.div`
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-content: center;
  width: 100%;
`;

export const AnswerContent = styled.div`
  display: flex;
  padding: 1em 0;
  justify-content: center;
  align-content: center;
  width: 100%;
  textarea {
    transition: 0.4s;
    margin-right: ${props => (props.saved === true ? '0' : '-30px')};
    text-align: ${props => (props.saved === true ? 'center' : 'left')};
    background: ${props => (props.saved === true ? 'transparent' : 'white')};
    min-width: 80%;
    resize: none;
    border: none;
    outline: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    color: var(--grey);
    ::-webkit-scrollbar {
      display: none;
    }

    ::placeholder {
      color: var(--grey-3);
      opacity: 1;
    }
  }

  button {
    display: ${props => (props.saved === true ? 'none' : 'flex')};
    border-radius: 20px;
    width: 100px;
    font-weight: 600;
    font-size: 14px;
    color: white;
    padding: 10px 10px;
    background: var(--grey);
    border: none;
    align-items: center;
    align-content: center;
    justify-content: center;
    background: var(grey-1);
    transition: 0.4s;
    z-index: 1;

    &:disabled {
      pointer-events: none;
      background: var(--grey-1);
    }

    svg {
      color: white;
      width: 1.5em;
    }

    &:hover {
      transition: 0.4s;
      transform: scale(1);
      color: white;
      opacity: 1;
    }
  }
`;

export const DialogueTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.bot ? 'flex-start' : 'flex-end')};
  align-content: center
  justify-content: center;
  position: relative;
  animation: ${smoothIn} .6s;
`;

export const TeacherNameBadge = styled.h3`
  position: absolute;
  top: -8px;
  right: 20px;
  color: var(--teal);
  font-size: 0.9em;
`;

export const DialogueSidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 0 0 0 1em;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 1.5em 2em;
  }
`;

export const BotChat = styled.div`
  order: 2;
  width: 40%;
  margin-left: 10px;

  .chatroom {
    background-color: white;
    min-width: 350px;
    height: unset;
    max-height: unset;
    padding: 10px;
    ${'' /* border: 1px solid grey; */}
    border-radius: 25px;
    min-height: 60px;
  }

  .chatroom .input {
    background-color: var(--grey-1);
    padding: 15px;
    border-radius: 20px;
  }

  .chatroom .chats {
    max-height: 650px;
  }

  .chatroom .chats .chat.left {
    border-left-width: 15px;
    border-left-style: solid;
    border-color: var(--orange);
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }

  .chatroom .chats .chat.right {
    border-right-width: 15px;
    border-right-style: solid;
    border-color: var(--teal);
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  .chatroom .chats .chat {
    background-color: var(--grey-5);
    padding: 1em;
    border-radius: 20px;
    font-weight: 500;
  }

  .chatroom h3 {
    color: white;
    background: var(--key-color-2);
    cursor: pointer;
    border: none;
    outline: none;
    padding: 5px 20px;
    font-size: 1em;
    border-radius: 20px;
    font-family: 'Montserrat', sans-serif;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    cursor: pointer;
    opacity: 0.9;
  }

  .chatroom .input input[type='submit'] {
    background: var(--key-color-2);
  }

  .chatroom .input #speech-input {
    background: var(--key-color-2);
  }
`;

// export const AddSuggestionBtn = styled.button`
//   position: absolute;
//   right: 1em;
//   top: 1em;
//   background: transparent;
//   color: white;
//   font-weight: 600;
//   margin: 0 0 1em 0;
//   opacity: 0.8;
//   border: none;
//   svg {
//     width: 1.5em;
//     color: var(--grey-3);
//   }
//   &:hover {
//     opacity: 1;
//     transform: scale(1) !important;
//   }
// `;

// export const NotesContainer = styled.div`
//   width: 100%;
//   background: var(--background-color);
//   h4 {
//     padding: 5px;
//     color: var(--grey-2);
//   }
//   @media (max-width: 768px) {
//     background: var(--grey-4);
//     border-radius: 20px;
//     padding: 1.5em 1em 0;

//     h4 {
//       text-align: center;
//       padding: 10px;
//       font-size: 1.2em;
//     }
//   }
// `;

// export const WrittenNotes = styled.ul`
//   width: 100%;
//   max-height: 300px;
//   scroll-y: auto;
//   border-radius: 10px !important;

//   li {
//     background: var(--grey-5);
//     border-radius: 20px;
//     color: var(--grey-2);
//     margin: 0 0 10px;
//     position: relative;
//     display: flex;
//     align-content: flex-start;
//     justify-content: flex-start;
//     width: 100%;
//     animation: ${smoothIn} 0.4s ease-in;

//     p {
//       width: 100%;
//       padding: 1.2em 0 1.2em 1.2em;
//       ${'' /* font-weight: 500; */}
//     }

//     textarea::-webkit-input-placeholder {
//       color: var(--grey);
//     }

//     textarea:-moz-placeholder {
//       /* Firefox 18- */
//       color: var(--grey);
//     }

//     textarea::-moz-placeholder {
//       /* Firefox 19+ */
//       color: var(--grey);
//     }

//     textarea:-ms-input-placeholder {
//       color: var(--grey);
//     }

//     textarea::placeholder {
//       color: var(--grey);
//     }

//     textarea {
//       width: 100%;
//       border: none;
//       font-size: 1em;
//       font-weight: 500;
//       font-family: 'montserrat';
//       padding: 1em;
//       resize: none;
//       color: var(--grey);
//       background: transparent;
//       outline: none;

//       &:focus {
//         background: white;
//       }
//     }
//   }

//   li:last-child {
//     margin: 0 0 0px;
//   }
// `;

// export const DropDownButton = styled.div`
//   width: 6%;
//   min-width: 27px;
//   color: var(--grey-3);
//   padding-top: 5px;
//   height: 50%;

//   svg {
//     cursor: pointer;
//   }

//   div {
//     display: flex;
//     visibility: hidden;
//     position: absolute;
//     top: 5px;
//     right: 20px;
//     align-items: center;
//     justify-content: center;
//     align-content: center;
//     transition: 0.4s;
//     padding: 5px;
//     border-radius: 20px;
//     animation: ${smoothIn} 0.4s ease-in;

//     button {
//       border: none;
//       font-weight: 600;
//       margin: 0 2px;
//       padding: 0 0;
//       background: transparent;
//       visibility: hidden;
//       font-size: 16px;
//     }
//   }

//   &:hover {
//     div {
//       transition: 0.4s;
//       visibility: visible;

//       button {
//         visibility: visible;
//       }
//     }
//   }
// `;

// export const NoteSaved = styled.div`
//   right: 30px;
//   position: absolute;
//   bottom: 0;
//   font-weight: bold;
//   font-size: 10px;
//   color: var(--key-color);
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   align-content: center;
//   transition: 0.4s;

//   div {
//     padding: 5px;
//     svg {
//       width: 10px;
//     }
//   }
// `;

// export const AddNoteContainer = styled.div`
//   display: flex;
//   margin-top: 10px;
//   textarea {
//     min-width: 85%;
//     resize: none;
//     border: none;
//     outline: none;
//     background: white;
//     border-radius: 10px 0 0 10px;
//     padding: 1em;
//     font-size: 1em;
//     font-weight: 500;
//     color: var(--grey-2);

//     ::placeholder {
//       color: var(--grey-3);
//       opacity: 1;
//     }
//   }

//   button {
//     min-width: 15%;
//     font-weight: 500;
//     background: var(--grey-3);
//     color: white;
//     padding: 0;
//     border-radius: 20px;
//     border-radius: 0 10px 10px 0;
//     border: none;
//     display: flex;
//     align-items: center;
//     align-content: center;
//     justify-content: center;
//     opacity: 0.8;
//     font-size: 1em;
//     font-weight: bold;

//     &:hover {
//       transform: scale(1);
//       background: var(--grey-3);
//       color: white;
//       opacity: 1;
//     }
//   }

//   @media (max-width: 768px) {
//     flex-wrap: wrap;
//     align-items: center;
//     justify-content: center;

//     textarea {
//       width: 100%;
//       border-radius: 20px;
//       text-align: center;
//     }

//     button {
//       width: 60%;
//       border-radius: 20px;
//       margin: 1em 0;
//       padding: 10px;
//       font-weight: 500;
//     }
//   }
// `;

// export const CurrentSessionDiv = styled.div`
//   position: fixed;
//   right: 10px;
//   bottom: 10px;
//   z-index: 100;
// `;

export const ModalContainer = styled.div`
  width: 100%;
  background: 'transparent';
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  ${'' /* position: fixed; */}
  ${'' /* top: 40px; */}
  padding: 100px 0;

  @media (max-width: 768px) {
    top: 0;
    height: initial;
    min-height: 80vh;
    position: initial;
    padding: 2em 0;
    overflow-y: auto;
  }
`;

export const Modal = styled.div`
  width: 100%;
  max-width: 1024px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  animation: ${smoothIn} 0.4s;
  opacity: 0;
  animation-fill-mode: forwards;

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }
`;

export const ScenarioCompleteContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  padding: 20px;
  min-height: 60vh;

  img {
    position: fixed;
    bottom: 40px;
    right: 50px;
    width: 65%;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
    align-content: center;
    justify-content: center;
    img {
      position: initial;
      width: 100%;
      padding: 20px 0;
    }
  }
`;

export const ScenarioCompleteContent = styled.div`
  padding: 140px 75px;
  z-index: 100;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 2em;
    color: var(--key-color);
    font-weight: bold;
    padding: 0;
    margin: 0;
  }

  p {
    font-size: 1em;
    font-weight: 500;
    padding-top: 12px;
  }

  @media (max-width: 768px) {
    text-align: center;

    h1 {
      font-size: 1.8em;
    }
  }
`;

export const ButtonContainer = styled.div`
  justify-content: space-between;
  padding: 25px 0;
  z-index: 1000;

  button  {
    font-weight: bold;
    padding: 5px 20px;
    min-width: 35%;
    background: var(--key-color);
    border: 5px solid var(--key-color);
    color: white;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
`;

export const ScenarioInfo = styled.div`
  animation: ${smoothIn} 0.4s;
  opacity: 0;
  animation-fill-mode: forwards;
  font-size: 18px;
  width: 100%;
  box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
  display: flex;
  background: #ffffff;
  padding: 10px 15px;
  margin-top: 5px;
  border-radius: 15px;

  h5 {
    margin-right: 10px;
    color: var(--grey);
    font-size: 16px;
    span {
      margin-left: 5px;
      color: var(--grey-2);
    }
  }
`;
