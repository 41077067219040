import styled from 'styled-components';

export const SliderContainer = styled.div`
  height: 100vh;
  position: fixed;
  top: 0px;
  transition: transform 0.2s ease-out 0s;
  z-index: 205;
  left: 0px;
  transform: translateX(${props => (props.open ? '0' : '-304px')});
  width: 80px;
  display: flex;
  flex-direction: row;
`;

export const OriginalMenuContainer = styled.div`
  width: 80px;
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 10px;
  flex-direction: column;
  box-shadow: 0 1px 2px 0 rgba(32, 33, 36, 0.1);
  button {
    width: 40px;
    height: 40px;
  }
  h1 {
    margin-top: 10px;
    color: var(--key-color) !important;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;
    justify-content: space-between;
    align-items: center;
  flex: 1;
  -moz-box-flex: 1;
  li {
    width: 100%;
    text-align: center;
    padding-bottom: 0.5em;
    transition: 0.4s;
    font-size: 1.5em;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    position: relative;

    p {
      font-size: 0.5em;
      font-weight: bold;
      color: var(--grey-3);
    }

    &:hover {
      transition: 0.4s;

      p {
        color: var(--key-color);
        transition: 0.4s;
      }

      svg {
        transition: 0.4s;
        color: var(--key-color);
      }
    }
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  margin-top: 30px;
`;
// export const WalkthroughWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   flex-basis: 100%;
//   height: 100%;
//   -webkit-box-pack: start;
//   justify-content: flex-start;
//   overflow-y: auto;
//   position: relative;

//   ::before {
//     /* ska nog tillhöra ngn annan */
//     content: '';
//     display: block;
//     flex-shrink: 0;
//     height: 2px;
//     margin-left: 16px;
//     position: relative;
//     z-index: 2;
//     background-color: white;
//     border-radius: 1px;
//   }

//   ::after {
//     border-radius: 1px;
//     content: '';
//     display: block;
//     flex-shrink: 0;
//     height: 2px;
//     margin-left: 16px;
//     margin-top: auto;
//     position: relative;
//     z-index: 2;
//     background-color: rgb(244, 245, 247);
//   }
// `;

// export const WalkthroughContainer = styled.div`
//   box-sizing: border-box;
//   padding-left: 16px;
//   padding-right: 16px;
//   padding-bottom: 12px;
// `;

export const NewMenu = styled.div`
  width: 240px;
  background: var(--background-color-white);
`;

export const User = styled.div`
  box-sizing: border-box;
  padding: 20px 16px;
  text-align: center;
  font-weight: 500;
  line-height: 1.3;
`;
