import { GlobalStyle } from '../styles/GlobalStyle';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import Navigation from '../components/Navigation';
import NavigationHeader from '../components/NavigationHeader';
// import SignUpPage from '../SignUp';
// import UploadPage from '../UploadPage';
import HomePage from '../screens/Home';
import Downloads from '../screens/Downloads';
import SignInPage from '../screens/SignIn';
import PasswordForgetPage from '../screens/PasswordForget';
import ScenarioPage from '../screens/Scenario';
import SessionsList from '../screens/SessionsList';
import SessionPage from '../screens/Session';
import { withAuthentication } from '../components/Session';
import { AppContainer, PageWrapper } from './style';
import AdminFilterContextProvider from '../components/AdminFilter/AdminFilterContext';
import AdminFilter from '../components/AdminFilter';
import ScenariosList from '../screens/ScenariosList';
import Admin from '../screens/Admin';

const App = props => {
  useEffect(() => {
    let timer;
    const handleMouseClick = () => {
      clearTimeout(timer);
      if (window.location.pathname !== '/signin') {
        timer = setTimeout(() => {
          props.firebase.doSignOut();
          alert("You've been logged out due to inactivity");
        }, 3600000);
      }
    };
    window.addEventListener('click', handleMouseClick);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('click', handleMouseClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <GlobalStyle />
      <AppContainer>
        <Navigation />
        <NavigationHeader />
        <PageWrapper>
          <AdminFilterContextProvider>
            <AdminFilter data-cy="adminFilter"> </AdminFilter>
            <Switch>
              <Route exact path={ROUTES.SCENARIOS} component={ScenariosList} />
              <Route path={`${ROUTES.SCENARIOS}/scenario/:id`} component={ScenarioPage} />
            </Switch>
            <Switch>
              <Route exact path={ROUTES.SESSIONS} component={SessionsList} />
              <Route path={`${ROUTES.SESSIONS}/:id`} component={SessionPage} />
            </Switch>
            <Route exact path="/">
              <Redirect strict from="/" to="/home" />
            </Route>
            <Route path={ROUTES.HOME} component={HomePage} />
            <Route path={ROUTES.ADMIN} component={Admin} />
            <Route path={ROUTES.DOWNLOADS} component={Downloads} />
          </AdminFilterContextProvider>
          <Route path={ROUTES.SIGN_IN} component={SignInPage} />
          <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
        </PageWrapper>
      </AppContainer>
    </Router>
  );
};

export default withAuthentication(App);

/* <Route path={ROUTES.SESSION} component={SessionPage} /> */
/* <Route path={ROUTES.SUPERUSER} component={SuperUser} /> */
/* <Route path={ROUTES.ACCOUNT} component={AccountPage} /> */
/* <Route exact path={ROUTES.LANDING} component={ScenarioPage} /> */
/* <Route path={ROUTES.SIGN_IN} component={SignInPage} /> */
