import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { Spinning } from '../../styles/keyframes';

// Error
export const FrError = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 12px;
  color: var(--status-red);
  margin: 0 10px;
`;

// Label
export const FrLabel = styled.label`
  margin: ${props => (props.margin ? props.margin : 'unset')};
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'bold')};
  color: ${props => (props.color ? props.color : 'var(--grey)')};
  width: ${props => (props.width ? props.width : '100%')};
  padding: ${props => (props.padding ? props.padding : '')};
  opacity: ${props => (props.disabled === true ? '.4' : '1')};
  display: ${props => (props.display ? props.display : 'block')};
  white-space: nowrap;
  span {
    font-weight: 500;
  }
`;

// Input
export const FrInput = styled.input`
  padding: ${props => (props.padding ? props.padding : '9px 20px')};
  width: ${props => (props.width ? props.width : '100%')};
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '500px')};
  background: ${props =>
    props.error ? 'rgba(255, 92, 80, 0.15)' : props.background ? props.background : 'white'};
  color: ${props => (props.color ? props.color : 'var(--grey)')};
  margin: ${props => (props.margin ? props.margin : '4px 0 0')};
  box-shadow: 0px 0px 0px 2px transparent;
  border: 2px solid transparent;
  outline: none;
  transition: 0.4s;
  border-radius: 20px;

  ::placeholder {
    color: var(--grey-2);
    font-weight: 500;
  }

  &:disabled {
    transition: 0.4s;
    pointer-events: none;
    opacity: 0.4;
  }
  &:hover {
    transition: 0.4s;
    box-shadow: 0px 0px 0px 2px rgba(127, 101, 255, 0.25);
  }
  &:focus {
    transition: 0.4s;
    border: 2px solid var(--key-color-2);
    box-shadow: 0px 0px 0px 2px rgba(127, 101, 255, 0.2);
  }
`;

//Label wrapping input type FILE
export const FrFileInputWrapper = styled.label`
  display: inline-block;
  cursor: pointer;
  border-radius: 20px;
  border: 2px solid var(--grey-3);
  padding: 0 15px;
  input[type='file'] {
    display: none;
  }

  svg {
    width: 30px;
    color: var(--key-color-2);
    transition: 0.4s;
    opacity: 0.6;
  }

  span {
    font-size: 12px;
    font-weight: 600;
    color: var(--grey-2);
    transition: 0.4s;
  }

  &:hover {
    span {
      transition: 0.4s;
      color: var(--grey-1);
    }
    svg {
      transition: 0.4s;
      opacity: 1;
    }
  }
`;

// Textarea
export const FrTextarea = styled(TextareaAutosize)`
  border: 2px solid transparent;
  width: ${props => (props.width ? props.width : '100%')};
  height: 58px;
  padding: 9px 20px;
  font-size: 14px;
  font-weight: 500px;
  outline: none;
  background: ${props =>
    props.error ? 'rgba(255, 92, 80, 0.15)' : props.background ? props.background : 'white'};
  color: var(--grey);
  transition: 0.4s;
  border-radius: 20px;
  margin: 4px 0 0;
  resize: none;
  font-family: 'Montserrat';

  ::placeholder {
    color: var(--grey-2);
    font-weight: 500;
  }

  &:disabled {
    transition: 0.4s;
    pointer-events: none;
    opacity: 0.4;
  }
  &:hover {
    transition: 0.4s;
    box-shadow: 0px 0px 0px 2px rgba(127, 101, 255, 0.25);
  }
  &:focus {
    transition: 0.4s;
    border: 2px solid var(--key-color-2);
    box-shadow: 0px 0px 0px 2px rgba(127, 101, 255, 0.2);
  }
`;

// Button
export const FrButton = styled.button`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: ${props => (props.margin ? props.margin : '0')};
  color: ${props => (props.color ? props.color : 'white')};
  background: ${props => (props.background ? props.background : 'var(--key-color-2-gradient)')};
  border: ${props => (props.border ? props.border : 'none')};
  padding: ${props => (props.padding ? props.padding : '9px 36px')};
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '20px')};
  cursor: pointer;
  outline: none;
  font-family: 'Montserrat';
  font-weight: bold;
  transition: 0.4s;
  opacity: 1;

  svg {
    width: 20px;
    color: ${props => (props.color ? props.color : 'white')};
    margin-right: 10px;
    animation: ${props =>
      props.loading !== undefined
        ? css`
            ${Spinning} 10s infinite
          `
        : 'none'};
  }

  &:hover {
    transition: 0.4s;
    box-shadow: ${props =>
      props.boxShadow ? '0px 0px 5px' + props.boxShadow : '0px 0px 5px var(--key-color-2)'};
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

export const FrLinkButton = styled.a`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: ${props => (props.margin ? props.margin : '0')};
  color: ${props => (props.color ? props.color : 'white')};
  background: ${props => (props.background ? props.background : 'var(--key-color-2-gradient)')};
  border: ${props => (props.border ? props.border : 'none')};
  padding: ${props => (props.padding ? props.padding : '9px 36px')};
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '20px')};
  cursor: pointer;
  outline: none;
  font-family: 'Montserrat';
  font-weight: bold;
  transition: 0.4s;
  opacity: 1;

  svg {
    width: 20px;
    color: ${props => (props.color ? props.color : 'white')};
    margin-right: 10px;
    animation: ${props =>
      props.loading !== undefined
        ? css`
            ${Spinning} 10s infinite
          `
        : 'none'};
  }

  &:hover {
    transition: 0.4s;
    box-shadow: ${props =>
      props.boxShadow ? '0px 0px 5px' + props.boxShadow : '0px 0px 5px var(--key-color-2)'};
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

// Div
export const FrDiv = styled.div`
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : 'initial')};
  padding: ${props => (props.padding ? props.padding : '0')};
  margin: ${props => (props.margin ? props.margin : '0')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'initial')};
  display: flex;
  flex-direction: ${props => (props.flexDirection ? props.flexDirection : 'row')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
  align-content: ${props => (props.alignContent ? props.alignContent : 'center')};
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'center')};
  flex-wrap: ${props => (props.flexWrap ? props.flexWrap : 'auto')};
  background: ${props => (props.background ? props.background : 'inital')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '20px')};
  border: ${props => (props.border ? props.border : 'none')};
  flex: ${props => (props.flex ? props.flex : 'unset')};
  box-shadow: ${props => (props.boxShadow ? props.boxShadow : 'unset')};
`;

export const FrContainer = styled.div`
  width: 80%;
  max-width: 1600px;
  min-height: 100vh;
  font-family: 'Montserrat';
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  position: relative;
  transition: 0.4s;
  padding: 30px 0 80px 0;
  background: var(--background-color);

  @media (max-width: 1150px) {
    width: 100%;
    padding: 30px 20px 80px;
  }
`;

// Img
export const FrImg = styled.img`
  width: ${props => (props.width ? props.width : '100%')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '0')};
  margin: ${props => (props.margin ? props.margin : '0')};
`;

export const FrH1 = styled.h1`
  width: 100%;
  margin: ${props => (props.margin ? props.margin : '0')};
  color: ${props => (props.color ? props.color : 'var(--grey)')};
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const FrH2 = styled.h2`
  width: 100%;
  margin: ${props => (props.margin ? props.margin : '24px 0 12px 0')};
  color: ${props => (props.color ? props.color : 'var(--grey)')};
  @media (max-width: 768px) {
    text-align: center;
  }
`;

// export const FRcheckbox = styled.input``;
// export const FRradioBtn = styled.input``;

//SELECT
// export const FRselect = styled.select``;
