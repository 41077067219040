import styled, { css } from 'styled-components';
import { smoothIn, FadeIn, pulse } from '../../styles/keyframes';

export const ListItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-radius: 20px;
  padding: 10px 30px;
  background: white;
  margin: 3px 0;
  animation: ${props =>
    props.isLoading
      ? css`
          ${pulse} 1s infinite ease-in-out
        `
      : css`${FadeIn} 0.2s ease-in-out`};
  animation-fill-mode: forwards;
  position: relative;
  cursor: ${props => (props.clickable ? 'pointer' : 'unset')};
  transition: 0.2s;
  pointer-events: ${props => (props.error ? 'none' : 'inital')};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07);

  @media (max-width: 768px) {
    flex-direction: column;
    p {
      width: 100%;
    }
  }

  &:hover {
    transition: 0.4s;
    box-shadow: ${props => (props.clickable ? '0px 0px 0px 2px var(--key-color-2)' : 'none')};
  }
`;

export const ScenarioImageE = styled.img`
  height: 60px;
  width: 60px;
  animation: ${smoothIn} 0.6s ease-in-out;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 250px;
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    width: 100%;
  }
`;

export const Headline = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: #4b4b4b;
`;

export const Nametext = styled.p`
  padding: 0 20px;
  font-size: 16px;
  font-weight: bold;
  color: #4b4b4b;
`;

export const InfoContainer = styled.div`
  padding: 0 20px;
  max-width: 450px;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    p {
      text-align: center;
    }
  }
`;

export const SmallText = styled.p`
  font-size: 14px;
  color: #4b4b4b;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '500')};
`;

export const TimesPlayedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 20%;
`;

export const TimesPlayedWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: ${props => (props.flexAlign ? props.flexAlign : 'center')};
  justify-content: ${props => (props.flexAlign ? props.flexAlign : 'center')};
  align-content: ${props => (props.flexAlign ? props.flexAlign : 'center')};

  div {
    background: ${props => (props.bgColor ? props.bgColor : 'var(--key-color-2)')};
  }
`;

export const TimesPlayedText = styled.p`
  font-size: 12px;
  color: #4b4b4b;
  line-height: 17px;
  text-align: center;
  font-weight: bold;
`;

export const TimesPlayedDots = styled.div`
  width: 10px;
  height: 10px;
  margin: 5px;
  border-radius: 50%;
`;

export const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 20%;
`;

export const ResultText = styled.p`
  font-size: 12px;
  color: #4b4b4b;
  line-height: 17px;
  margin-bottom: 4px;
  font-weight: bold;

  > span {
    font-weight: 500;
  }
`;

export const Button = styled.a`
  inline-size: fit-content;
  background: ${props => (props.isScenarioActive ? `#765CF6` : `#FF6858`)};
  border-radius: 121px;
  display: inline-flex;
  flex-direction: row;
  padding: 4px 10px;
  align-items: center;
  height: 30px;
  min-width: 106px;
  cursor: pointer;
  animation: ${smoothIn} 0.4s ease-in-out;

  > span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    width: 100%;

    color: #ffffff;
    margin: 0 10px;
  }
`;

export const MenuContainer = styled.div`
  width: 20%;
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  visibility: ${props => (props.visibility ? props.visibility : 'initial')};
  a {
    visibility: hidden;
    opacity: 0;
    transition: 0.4s;
  }

  svg {
    cursor: pointer;
    color: #b3b3b3;
    width: 25px;
  }

  &:hover {
    a {
      transition: 0.4s;
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const MenuContainerFixed = styled.div`
  position: absolute;
  right: 5px;
  top: ${props => (props.top ? props.top : '15px')};
  cursor: pointer;
  svg {
    width: 20px;
    color: var(--grey);
  }
`;

export const Options = styled.div`
  position: absolute;
  background: white;
  border-radius: 10px 10px 0 10px;
  box-shadow: 0px 0px 5px var(--grey-3);
  min-width: 100px;
  bottom: 15px;
  right: 12px;
  padding: 5px 0;
  display: flex;
  align-items: baseline;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  z-index: 5;

  button {
    white-space: nowrap;
    width: 100%;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    font-size: 12px;
    font-weight: bold;
    color: #4b4b4b;
    transition: 0.4s;
    opacity: 1;
    background: transparent;
    padding: 5px 10px;
    span {
      font-size: 12px;
    }

    svg {
      margin: 0 2px 0 0;
      color: var(--grey);
    }

    &:hover {
      transition: 0.4s;
      opacity: 0.8;
    }
  }

  @media (max-width: 1300px) {
    right: 14px;
    top: -40px;
    bottom: unset;
    border-radius: 10px;
  }
`;

export const NoImageDiv = styled.div`
  width: 60px;
  height: 60px;
  background: var(--grey-5);
  border-radius: 20px;
`;

export const MeterWrapper = styled.div`
  ${'' /* background: var(--grey-4); */}
  border-radius: 20px;
  width: max-content;
  padding: 0 4px;
`;
