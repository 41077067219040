import styled from 'styled-components';
import { Menu } from 'styled-icons/material/Menu';
import { ChevronDown } from 'styled-icons/boxicons-regular/ChevronDown';
import { ChevronUp } from 'styled-icons/boxicons-regular/ChevronUp';
import { SortAlt } from 'styled-icons/boxicons-solid/SortAlt';
import {ChevronRight} from 'styled-icons/boxicons-regular/ChevronRight';
import { Delete } from 'styled-icons/typicons/Delete';

import { VrCardboard } from 'styled-icons/fa-solid/VrCardboard';
import { AccountCircle } from 'styled-icons/material/AccountCircle';
import { Dashboard } from 'styled-icons/boxicons-solid/Dashboard';
import { Graph } from 'styled-icons/octicons/Graph';
import { FileList } from 'styled-icons/remix-line/FileList';
import { RightArrowAlt } from 'styled-icons/boxicons-regular/RightArrowAlt';

import { Clock } from 'styled-icons/fa-regular/Clock';
import { MailCheck } from 'styled-icons/remix-fill/MailCheck';
import {Rocket} from 'styled-icons/boxicons-solid/Rocket';
import {Star} from 'styled-icons/boxicons-solid/Star';
import {DotsVerticalRounded} from 'styled-icons/boxicons-regular/DotsVerticalRounded';
import {Edit} from 'styled-icons/boxicons-regular/Edit';
import {Admin} from 'styled-icons/remix-line/Admin';

import {DeleteForever} from 'styled-icons/material/DeleteForever';

export const EditI = styled(Edit)`
  width: 1.5em;
`;

export const DeleteI = styled(DeleteForever)`
  width: 1.5em;
`;

export const CloseX = styled(Delete)`
    position: fixed;
    height: 2em;
    width: 2em;
    cursor: pointer;
    color: var(--menu-color);
    font-weight: bold;
    transition: .4s;
    &:hover {
        transition: .4s; 
        transform: scale(1.2);
    }
`;

export const Dots = styled(DotsVerticalRounded)`
  width: 100%;
`;

export const StarI = styled(Star)`
  width: 1.4em;
`;

export const RocketI = styled(Rocket)`
    width: 1.5em;
    color: var(--grey-3);
`;

export const ClockI = styled(Clock)`
    width: 1em;
    margin: 0 5px 0 0;
    color: var(--grey-4); 
`;

export const MailSent = styled(MailCheck)`
    width: 8em !important;
`;

export const Arrow = styled(RightArrowAlt)`
    width: 2em;
    color: white;
`;

export const SuperAdmin = styled(Admin)`
    width: 1.5em;
    color: var(--grey-3);
`;

export const Vr = styled(VrCardboard)`
    width: 1.5em;
    color: var(--grey-3);
`;

export const Acc = styled(AccountCircle)`
    width: 1.5em;
    color: var(--grey-3); 
`;

export const Dash = styled(Dashboard)`
    width: 1.5em;
    color: var(--grey-3); 
`;

export const GraphI = styled(Graph)`
    width: 2em;
    color: var(--grey-3); 
`;

export const List = styled(FileList)`
    width: 2em;
    color: var(--grey-3); 
`;

export const HamMenu = styled(Menu)`
    height: 2.5em;
    width: 2.5em;
    color: var(--key-color-2);
`;

export const NotSorted = styled(SortAlt)`
    height: 1.5em;
    width: 1.5em;
    color: white;
    color: var(--background-color-blue);
`;

export const ChevRight = styled(ChevronRight)`
    width: ${props => props.width ? props.width : '1.5em'};
    color: ${props => props.color ? props.color : 'var(--grey-3)'};
`;

export const ChevDown = styled(ChevronDown)`
    width: ${props => props.width ? props.width : '18px'};
    color: ${props => props.color ? props.color : 'var(--grey-3)'};
`;

export const ChevUp = styled(ChevronUp)`
    width: ${props => props.width ? props.width : '18px'};
    color: ${props => props.color ? props.color : 'var(--grey-3)'};
`;

