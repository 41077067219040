import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        authUser => {
          if (!condition(authUser)) {
            this.props.history.push(ROUTES.HOME);
          }
        },
        (error) => {
          if(error) {
            this.props.history.push({pathname: ROUTES.SIGN_IN, state: {error: error}})
          } else {
            if(this.props.history && this.props.history.location && this.props.history.location.pathname !== '/signin'){
              this.props.history.push({pathname: ROUTES.SIGN_IN, state: {url: this.props.history.location.pathname}})
            }
          }
        }
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            condition(authUser) ? <Component {...this.props} authUser={authUser} /> : null
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return compose(withRouter, withFirebase)(WithAuthorization);
};

export default withAuthorization;
