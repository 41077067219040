import React, { useEffect, useState, useContext } from 'react';
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import DialogueStatement from '../ScenarioEditor/DialogueStatement';
import { findLast } from 'lodash';
import { compose } from 'recompose';
import { formatSessionLength } from '../../helpFunctions/session';
import {
  ScenarioPageContainer,
  ScenarioContentContainer,
  ScenarioPageWrapper,
  DialogueTextWrapper,
  ScenarioInfo,
} from './style';
import { coverImageURL } from '../../helpFunctions/storageImgUrls';
import {
  MainContainer,
  MainWrapper,
  ScoreListItem,
  ShowMoreButton,
  PlayButton,
} from '../Home/style';
import { SessionWidget } from '../SessionsList';
import { AdminFilterContext } from '../../components/AdminFilter/AdminFilterContext';
// import CollapsibleContainer from '../../components/CollapsibleContainer';
// import VideoContent from '../../components/VideoContent';
import { USER_GUIDE } from '../../constants/urls';
import Loading from '../../components/Loading';
import * as ROUTES from '../../constants/routes';
import { SCENARIO } from '../../constants/loadingMessages';
import InfiniteScroll from 'react-infinite-scroll-component';
import { mapVoiceLinesToExchanges, mapSheetVoiceLines } from '../../helpFunctions/scenario';
import ExchangesList from '../ScenarioEditor/ExchangesList';
import { FrButton, FrLinkButton } from '../../components/DesignSystem/style.js';
import { DialogueMenu } from '../ScenarioEditor';
import { DialogueContainer, DialogueTitle } from '../ScenarioEditor/style';
import { PageTitle } from '../Admin/AdminHeader';
import { TheaterMasks } from 'styled-icons/fa-solid/TheaterMasks';
import { Play } from 'styled-icons/fa-solid/Play';
import { Bubbles2 } from 'styled-icons/icomoon/Bubbles2';
import { Star } from 'styled-icons/boxicons-solid/Star';

const ScenarioPage = props => {
  const { state } = useContext(AdminFilterContext);
  const [exchanges, setExchanges] = useState(null);
  const [activeScenario, setActiveScenario] = useState(null);
  const [tempVoiceLines, setTempVoiceLines] = useState(null);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState({ prev: 0, next: 5 });
  const [hasMore, setHasMore] = useState(true);
  const [current, setCurrent] = useState(null);
  const [scenarioError, setScenarioError] = useState(null);
  const [mode, setMode] = useState('script');
  const [aiEnabled, setAiEnabled] = useState(false);
  const [recentSessions, setRecentSessions] = useState(null);
  const [scenarioStats, setScenarioStats] = useState(null);

  const getScenario = sheetId => {
    setLoading(true);
    let url = 'https://spreadsheets.google.com/feeds/list/' + sheetId + '/od6/public/full?alt=json';
    fetch(url)
      .then(response => response.json())
      .then(data => {
        setTempVoiceLines(mapSheetVoiceLines(data.feed.entry));
        setLoading(false);
      })
      .catch(err => {
        setScenarioError('We could not locate the current scenario');
        setLoading(false);
      });
  };

  const reduceNumbers = arr => {
    if (arr && arr.length > 0) {
      return arr.reduce((sum, stat) => sum + stat, 0);
    } else {
      return 0;
    }
  };

  const getAvgSkillLevel = arr => {
    if (arr && arr.length > 0) {
      let tempLast = findLast(arr, val => val !== 0);
      return tempLast ? (tempLast * 100).toFixed(0) : 0;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (exchanges) {
      setCurrent(exchanges.slice(count.prev, count.next));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchanges]);

  useEffect(() => {
    if (tempVoiceLines) {
      setExchanges(mapVoiceLinesToExchanges(tempVoiceLines));
      setLoading(false);
    }
    if (!tempVoiceLines && exchanges) {
      setExchanges(null);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempVoiceLines]);

  useEffect(() => {
    if (state.selectedClient && activeScenario) {
      props.firebase.scenarioStats(activeScenario.id).once('value', snapshot => {
        const tempStats = snapshot.val();
        if (tempStats) {
          setScenarioStats({
            playTime: tempStats.playTimeSeconds ? reduceNumbers(Object.values(tempStats.playTimeSeconds)) : 0,
            avgSkillLevel: tempStats.averageSkillLevel ? getAvgSkillLevel(Object.values(tempStats.averageSkillLevel)): 0,
            sessionsPlayed: tempStats.sessionsPlayed ? reduceNumbers(Object.values(tempStats.sessionsPlayed)): 0,
          });
        } else {
          setScenarioStats({});
        }
      });
      props.firebase
        .newClient(state.selectedClient)
        .child('settings')
        .child('aiEnabled')
        .once('value', snapshot => {
          const tempAiEnabled = snapshot.val();
          if (tempAiEnabled) {
            setAiEnabled(tempAiEnabled);
            if (activeScenario.modes) {
              setMode('explain');
            }
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedClient, activeScenario]);

  useEffect(() => {
    if (!state.loading && state.scenarios && state.scenarios.length > 0 && !exchanges) {
      let scenario = state.scenarios.find(scenario => scenario.id === props.match.params.id);
      if (scenario) {
        setActiveScenario(scenario);
        if (!scenario.voiceLines && !scenario.googleSheet) {
          setLoading(false);
        }
        if (scenario.voiceLines) {
          setTempVoiceLines(scenario.voiceLines);
        } else if (scenario.googleSheet) {
          getScenario(scenario.googleSheet);
        }
      } else {
        setScenarioError('We could not locate the current scenario');
        setLoading(false);
      }
      if (state.sessions && scenario) {
        let filteredSessions = state.sessions.filter(sess => sess.scenario_id === scenario.id);
        if (filteredSessions && filteredSessions.length >= 3) {
          setRecentSessions(
            filteredSessions.slice(filteredSessions.length - 3, filteredSessions.length),
          );
        } else {
          setRecentSessions(filteredSessions);
        }
      }
    }
    if (state.scenarios && state.scenarios.length === 0) {
      props.history.push(ROUTES.SCENARIOS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loading]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const loadMoreStatements = () => {
    if (current.length === exchanges.length) {
      setHasMore(false);
    }
    setTimeout(() => {
      setCurrent(current.concat(exchanges.slice(count.prev + 5, count.next + 5)));
    }, 1000);
    setCount(prevState => ({ prev: prevState.prev + 5, next: prevState.next + 5 }));
  };

  return (
    <ScenarioPageContainer>
      {scenarioError && !loading && (
        <PageTitle>
          <h2 style={{ padding: '30px 0', width: '100%', textAlign: 'center' }}>{scenarioError}</h2>
        </PageTitle>
      )}
      <PageTitle title={'Scenario'} />

      {activeScenario && activeScenario.settings && (
        <MainContainer>
          <MainWrapper
            background={'linear-gradient(91.82deg, #9D89FF 3.83%, #8067FF 98.41%)'}
            margin={'5px 5px 5px 0'}
          >
            {activeScenario.settings.scenarioName && (
              <h2 style={{ marginBottom: '20px', color: 'white' }}>
                {activeScenario.settings.scenarioName}
              </h2>
            )}
            <p>{activeScenario.settings.scenarioText}</p>
            <div style={{ display: 'flex', padding: '20px 0 0' }}>
              <FrButton onClick={() => props.history.push(ROUTES.DOWNLOADS)} margin={'0 8px 0 0'}>
                Play
              </FrButton>
              <FrLinkButton
                href={USER_GUIDE}
                target="_blank"
                rel="noopener noreferrer"
                background={'var(--grey-1)'}
              >
                Need help?
              </FrLinkButton>
            </div>
            {activeScenario.settings.coverImage ? (
              <img
                style={{ width: '30%', right: '20px' }}
                src={coverImageURL(activeScenario.settings.coverImage)}
                alt=""
              />
            ) : (
              <img
                src={require('../../assets/home-page-welcome.png')}
                alt=""
                style={{ width: '40%', right: '20px' }}
              />
            )}
          </MainWrapper>
          <MainWrapper margin={'5px 0 5px 5px'} flex={'1 1 320px'}>
            {scenarioStats && (
              <>
                <h3 style={{ color: 'var(--grey-1)' }}>Scenario stats</h3>
                <ul>
                  <ScoreListItem scoreColor={'rgb(102,219,190)'}>
                    <TheaterMasks /> Playtime:
                    <span>{scenarioStats.playTime ? formatSessionLength(scenarioStats.playTime * 1000): 0}</span>
                  </ScoreListItem>
                  <ScoreListItem scoreColor={'rgb(255 120 106)'}>
                    <Bubbles2 />
                    Sessions played:<span>{scenarioStats.sessionsPlayed ? scenarioStats.sessionsPlayed : 0}</span>
                  </ScoreListItem>
                  <ScoreListItem scoreColor={'rgb(255 210 64)'}>
                    <Star />
                    Avg. skill level:<span>{scenarioStats.avgSkillLevel ? scenarioStats.avgSkillLevel : 0}%</span>
                  </ScoreListItem>
                </ul>
              </>
            )}
            {!scenarioStats && (
              <Loading loadingText={'Loading Stats...'} width={'80px'} containerHeight={'100%'} />
            )}
          </MainWrapper>
        </MainContainer>
      )}

      {activeScenario && activeScenario.settings && (
        <ScenarioInfo>
          {/* <h5>
          Language: <span>{activeScenario.settings.locale},</span>
        </h5> */}
          <h5>
            Environment:
            <span>{activeScenario.settings.environment},</span>
          </h5>
          <h5>
            Status: <span>{activeScenario.visible ? 'Published' : 'Unpublished'}</span>
          </h5>
        </ScenarioInfo>
      )}

      {/* {activeScenario && activeScenario.settings && activeScenario.settings.videoUrl && (
        <ScenarioPageWrapper>
        <CollapsibleContainer isOpen={true} padding={'0'} label={'Scenario Video'}>
          <VideoContent activeScenario={activeScenario} />
        </CollapsibleContainer>
        </ScenarioPageWrapper>
      )} */}

      <h2 style={{ width: '100%', padding: '30px 0 10px' }}>Recent sessions</h2>
      {!loading && recentSessions && recentSessions.length > 0 && activeScenario && (
        <>
          <SessionWidget
            scenarios={[activeScenario]}
            sessions={recentSessions}
            authUser={props.authUser}
            history={props.history}
          />
          <ShowMoreButton onClick={() => props.history.push(ROUTES.SESSIONS)}>
            Go to sessions list
          </ShowMoreButton>
        </>
      )}

      {!loading && recentSessions && recentSessions.length === 0 && activeScenario && (
        <ScenarioInfo>
          <h5 style={{ width: '100%' }}>You don't have any sessions for this scenario</h5>
        </ScenarioInfo>
      )}

      {loading === true && (
        <Loading loadingText={SCENARIO} width={'80px'} containerHeight={'400px'} />
      )}

      {!loading && activeScenario && (
        <ScenarioPageWrapper padding={'0 0 40px 0'}>
          <DialogueTitle>Dialogue</DialogueTitle>
          <DialogueMenu
            aiEnabled={aiEnabled}
            mode={mode}
            setMode={setMode}
            isSuperAdmin={
              props.authUser && props.authUser.claims && props.authUser.claims.superAdmin
            }
          />
          <DialogueContainer style={{ paddingTop: '25px', minHeight: '400px' }}>
            <PlayButton onClick={() => props.history.push(ROUTES.DOWNLOADS)}>
              <Play />
            </PlayButton>
            {mode !== 'script' && aiEnabled && (
              <ExchangesList
                mode={mode}
                scenarioId={
                  mode === 'generic' ? 'noclientcom-generic-bot-responses' : activeScenario.id
                }
                viewMode={true}
                characters={activeScenario.settings && activeScenario.settings.characters}
                locale={activeScenario.settings && activeScenario.settings.locale}
              />
            )}

            {!loading && mode === 'script' && !current && (
              <h3 style={{ color: 'var(--grey-1)', padding: '0' }}>
                Hmm, it appears that this scenario is missing a dialogue script...
              </h3>
            )}

            {current && mode === 'script' && (
              <ScenarioContentContainer>
                <div
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <ul
                    style={{
                      transition: '.4s',
                      borderRadius: '20px',
                      padding: '1.5em',
                      background: 'white',
                      minHeight: '100vh',
                    }}
                  >
                    <InfiniteScroll
                      dataLength={current.length}
                      next={loadMoreStatements}
                      hasMore={hasMore}
                      loader={<Loading width={'60px'} containerHeight={'100px'} />}
                      style={{ paddingTop: '5px' }}
                    >
                      {current.map((exchange, index) => (
                        <React.Fragment key={index}>
                          {exchange.bot && (
                            <DialogueTextWrapper bot={true}>
                              <DialogueStatement
                                disableEditing={true}
                                question={true}
                                charName={
                                  activeScenario &&
                                  activeScenario.settings &&
                                  activeScenario.settings.characters &&
                                  activeScenario.settings.characters.character_1 &&
                                  activeScenario.settings.characters.character_1.displayName
                                }
                                scenarioToEdit={activeScenario.id}
                                statement={exchange.bot}
                              />
                            </DialogueTextWrapper>
                          )}
                          {exchange.teacher && (
                            <DialogueTextWrapper bot={false}>
                              <DialogueStatement
                                disableEditing={true}
                                charName={
                                  activeScenario &&
                                  activeScenario.settings &&
                                  activeScenario.settings.characters &&
                                  activeScenario.settings.characters.character_2 &&
                                  activeScenario.settings.characters.character_2.displayName
                                }
                                statement={exchange.teacher}
                                scenarioToEdit={activeScenario.id}
                              />
                            </DialogueTextWrapper>
                          )}
                        </React.Fragment>
                      ))}
                    </InfiniteScroll>
                  </ul>
                </div>
              </ScenarioContentContainer>
            )}
          </DialogueContainer>
        </ScenarioPageWrapper>
      )}
    </ScenarioPageContainer>
  );
};

const condition = authUser => !!authUser;

export default compose(withAuthorization(condition), withFirebase)(ScenarioPage);

// Old AI chat bot
// {activeScenario.settings && activeScenario.settings.aiEnabled && (
//   <BotChat>
//     <ConnectedChatroom
//       userId={
//         props.authUser && props.authUser.email
//           ? props.authUser.email
//           : BOT_OPTIONS.sessionUserId
//       }
//       host={BOT_OPTIONS.host}
//       title={BOT_OPTIONS.title || 'Chat'}
//       speechRecognition={BOT_OPTIONS.speechRecognition}
//       welcomeMessage={BOT_OPTIONS.welcomeMessage}
//       waitingTimeout={BOT_OPTIONS.waitingTimeout}
//       fetchOptions={BOT_OPTIONS.fetchOptions}
//       voiceLang={BOT_OPTIONS.voiceLang}
//     />
//   </BotChat>
// )}

// BotChat,
// import { BOT_OPTIONS } from '../../constants/botOptions';
// import '@fictive/chatroom/dist/Chatroom.css';
// import ConnectedChatroom from '@fictive/chatroom';
// import { ChevRight, ChevDown } from '../../styles/icons';
// const [events, setEvents] = useState(null);
// const [currentSession, setCurrentSession] = useState(null);
