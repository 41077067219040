import styled from 'styled-components';

export const AppContainer = styled.div`
  width: 100%;
  display: flex;
  background: var(--background-color);
`;

export const PageWrapper = styled.div`
  background: var(--background-color);
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
`;

export const Container = styled.div`
  max-width: 1400px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
`;
