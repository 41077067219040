import React, { useEffect, useState } from 'react';
import { ReactComponent as Hesitation } from '../../assets/hesitation-icon.svg';
import { ReactComponent as Attitude } from '../../assets/attitude-icon.svg';
import { ReactComponent as Pitch } from '../../assets/pitch-icon.svg';
import { ReactComponent as Volume } from '../../assets/volume-icon.svg';
import { ReactComponent as WordRate } from '../../assets/word-rate-icon.svg';
import { ReactComponent as Head } from '../../assets/head-icon.svg';
import { ReactComponent as Gace } from '../../assets/gace-icon.svg';
import { ReactComponent as Fillers } from '../../assets/fillers-icon.svg';
import {SummaryContainer, SummaryList, SummaryColorbar, SummaryItemWrapper} from './style';

const SessionSummary = ({ summaryList }) => {
  const [summaries, setSummaries] = useState(null);

  useEffect(() => {
    if (summaryList) {
      const tempGood = summaryList.filter(sumItem => sumItem.remark === 2);
      const tempModerate = summaryList.filter(sumItem => sumItem.remark === 1);
      const tempPoor = summaryList.filter(sumItem => sumItem.remark === 0);
      let tempSummaries = {
        good: tempGood.length !== 0 ? tempGood : null,
        moderate: tempModerate.length !== 0 ? tempModerate : null,
        poor: tempPoor.length !== 0 ? tempPoor : null,
      };
      setSummaries(tempSummaries);
    }
  }, [summaryList]);

  return (
    <>
      {summaries && (
        <SummaryContainer>
          {summaries.good && (
            <SummaryList color={'var(--status-green)'}>
              <SummaryColorbar color={'var(--status-green)'} />
              {summaries.good.map((sumItem, index) => (
                <SummaryItem key={index} sumItem={sumItem} />
              ))}
            </SummaryList>
          )}
          {summaries.moderate && (
            <SummaryList color={'var(--status-yellow)'}>
              <SummaryColorbar color={'var(--status-yellow)'} />
              {summaries.moderate.map((sumItem, index) => (
                <SummaryItem key={index} sumItem={sumItem} />
              ))}
            </SummaryList>
          )}
          {summaries.poor && (
            <SummaryList color={'var(--status-red)'}>
              <SummaryColorbar color={'var(--status-red)'} />
              {summaries.poor.map((sumItem, index) => (
                <SummaryItem key={index} sumItem={sumItem} />
              ))}
            </SummaryList>
          )}
        </SummaryContainer>
      )}
    </>
  );
};

const SummaryItem = ({ sumItem }) => {
  return (
    <SummaryItemWrapper color={sumItem.color}>
      <h5>{sumItem.title}</h5>
      {sumItem.title && <SummaryIcon name={sumItem.title.toLowerCase()}/>}
      <div>
        <h5>{sumItem.subtitle}</h5>
        <p>{sumItem.text}</p>
      </div>
    </SummaryItemWrapper>
  );
};

const SummaryIcon = ({ name }) => {
  return (
    <>
      {name === 'hesitation' && <Hesitation/>}
      {name === 'attitude' && <Attitude/>}
      {name === 'pitch' && <Pitch/>}
      {name === 'volume' && <Volume/>}
      {name === 'word rate' && <WordRate/>}
      {name === 'head' && <Head/>}
      {name === 'gaze' && <Gace/>}
      {name === 'fillers' && <Fillers/>}
    </>
  );
};

export default SessionSummary;
