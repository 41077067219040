import React from 'react';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as Sentry from '@sentry/browser';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        authUser: null
        // authUser: JSON.parse(localStorage.getItem('authUser')),
      };
    }

    // Localstorage should not be used to store authuser in production.
    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        authUser => {
          // Store firebase authUser in local storage.
          // localStorage.setItem('authUser', JSON.stringify(authUser));
            this.setState({ authUser });

          if (authUser && authUser.email)
            Sentry.setUser({ "email": authUser.email });
        },
        () => {
          // Get authUser from local storage.
          // localStorage.getItem('authUser');
          this.setState({ authUser: null });
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;