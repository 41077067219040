import styled from 'styled-components';

export const Nav = styled.div`
  height: 100%;
  ${'' /* flex: 1 1 0px; */}
  background: white;
  width: 88px;
  position: relative;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const NavContainer = styled.div`
  position: fixed;
  background: var(--background-color-white);
  height: 100vh;
  top: 0;
  left: 0;
  width: 88px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;


  img {
    margin-top: 14px;
    width: 50px;
  }

  ul {
    height: 100%;
    width: 100%;
    padding: 54px 0;

    li:first-child {
      margin:  0 0 15px;
    }
  }
`;

export const ListItem = styled.li`
  width: 100%;
  text-align: center;
  transition: 0.4s;
  padding: 10px 0;
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-left: ${props => props.selected ? '4px solid var(--key-color-2)': '4px solid transparent'};
  border-right: 4px solid transparent;

  a {
    svg {
      transition: 0.4s;
      width: 30px;
      color: ${props => props.selected ? 'var(--key-color-2)': 'var(--grey-3)'};
    }
  }

  &:hover {
    svg {
      transition: 0.4s;
      color: var(--key-color-2);
    }
  }
`;

export const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  font-weight: bold;

  h1 {
    color: white;
  }

  h6 {
    font-size: 12px;
  }

  h5 {
    font-size: 14px;
  }

  h1 {
    pointer-events: none;
    width: 42px;
    height: 42px;
    vertical-align: middle;
    line-height: 1.1;
    border-radius: 50%;
    background: var(--key-color-2-gradient);
    font-size: 20px;
    font-weight: bold;
  }
`;
