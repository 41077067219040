import React, { useState, useEffect, useReducer, useRef } from 'react';
import { withFirebase } from '../../components/Firebase';
import ReactPlayer from 'react-player';
import uuid from 'uuid';
import { ChatFeed, Message } from 'react-chat-ui';
import { FrLabel, FrTextarea, FrInput, FrButton } from '../../components/DesignSystem/style';
import { ChevUp, ChevDown } from '../../styles/icons';
import { Trash } from 'styled-icons/boxicons-regular/Trash';
import { PlusCircle } from 'styled-icons/boxicons-solid/PlusCircle';
import { Play } from 'styled-icons/boxicons-regular/Play';
import { Pause } from 'styled-icons/boxicons-regular/Pause';
import { Cog } from 'styled-icons/boxicons-solid/Cog';
import { Robot } from 'styled-icons/fa-solid/Robot';
import { Edit } from 'styled-icons/boxicons-regular/Edit';
import Loading from '../../components/Loading';
import { ProgressBar } from 'react-step-progress-bar';
import ReactTooltip from 'react-tooltip';
import * as ROUTES from '../../constants/routes';
import 'react-step-progress-bar/styles.css';
import {
  List,
  ExchangeContainer,
  ExchangeWrapper,
  NewExchangeContainer,
  NewExchangeWrapper,
  TopBar,
  DescriptionWrapper,
  LineDiv,
  ButtonContainer,
  ActorContainer,
  ActorWrapper,
  StatementContainer,
  AddStatementBtn,
  StatementWrapper,
  StatementBtn,
  FoldOutMoods,
  MoodContainer,
  EmojiWrapper,
  TrainingContainer,
  ChatContainer,
  ChatTopBar,
  ChatWrapper,
  ChatInputWrapper,
  ProgressWrapper,
} from './ExchangesListStyles';

const exchangesListReducer = (state, action) => {
  switch (action.type) {
    case 'ADD': {
      const newExchange = {
        id: action.id,
        intent: action.intent,
        description: action.description,
        botCharacter: action.character,
        parent: null,
        order:
          state.exchanges && state.exchanges.length >= 1
            ? state.exchanges[state.exchanges.length - 1].order + 1
            : 0,
      };
      return {
        ...state,
        changes: state.changes + 1,
        exchanges: [...state.exchanges, newExchange],
      };
    }
    case 'REMOVE': {
      return {
        ...state,
        exchanges: state.exchanges.filter(exchange => exchange.id !== action.exchangeId),
      };
    }
    case 'MOVE': {
      const tempExchanges = JSON.parse(JSON.stringify(state.exchanges));
      tempExchanges[action.index].order = state.exchanges[action.targetIndex].order;
      tempExchanges[action.targetIndex].order = state.exchanges[action.index].order;
      return {
        ...state,
        changes: state.changes + 1,
        exchanges: tempExchanges.sort((a, b) => (a.order > b.order ? 1 : -1)),
      };
    }
    case 'INITIAL': {
      if (action.exchanges && action.exchanges.length > 1) {
        let sorted = action.exchanges.sort((a, b) => (a.order > b.order ? 1 : -1));
        return {
          ...state,
          changes: 0,
          exchanges: sorted,
        };
      } else {
        return {
          ...state,
          exchanges: action.exchanges,
        };
      }
    }
    default:
      return state;
  }
};

const ExchangesList = (
  {
    scenarioId,
    mode,
    characters,
    firebase,
    viewMode,
    locale,
    hasModes,
    isVisible,
    changeVisibility,
  },
  props,
) => {
  const [state, dispatch] = useReducer(exchangesListReducer, null);
  const [training, setTraining] = useState(null);
  const [showBotChat, setShowBotChat] = useState(false);
  const [loading, setLoading] = useState(true);
  let male = require('../../assets/male.png');
  let female = require('../../assets/female.png');

  useEffect(() => {
    if (scenarioId !== '' && mode) {
      setLoading(true);
      firebase.scenarioModeExchanges(scenarioId, mode).once('value', snapshot => {
        const exchangesObj = snapshot.val();
        if (exchangesObj) {
          const exchangesList = Object.keys(exchangesObj).map(key => ({
            id: key,
            ...exchangesObj[key],
          }));
          dispatch({ type: 'INITIAL', exchanges: exchangesList });
        } else {
          dispatch({ type: 'INITIAL', exchanges: [] });
          setLoading(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenarioId, mode]);

  useEffect(() => {
    if (state && state.exchanges) {
      setLoading(false);
    }
  }, [state]);

  const trainBot = () => {
    setShowBotChat(false);
    setTraining(true);

    let url = `https://${process.env.REACT_APP_PROJECT_ID}.engine.fictivereality.com/ai_server/webhooks/speech/update_model`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ scenario_id: scenarioId }),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        console.log(data);
      })
      .catch(err => {
        console.error(err);
      });
  };

  useEffect(() => {
    firebase
      .newScenario(scenarioId)
      .child('settings')
      .child('training')
      .on('value', snapshot => {
        const trainingObj = snapshot.val();
        if (trainingObj !== null) {
          if (trainingObj === false) {
            setTraining(false);
          } else if (trainingObj === true) {
            setTraining(true);
            setShowBotChat(false);
          }
        } else {
          setTraining(null);
        }
      });

    return () => {
      firebase
        .newScenario()
        .child('settings')
        .child('training')
        .off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeExchange = id => {
    firebase.scenarioModeExchanges(scenarioId, mode).update({ [id]: null }, error => {
      if (error) {
        console.error('Could not remove exchange with' + id);
      } else {
        dispatch({ type: 'REMOVE', exchangeId: id });
      }
    });
  };

  const moveExchange = (index, targetIndex) => {
    console.log('moving ' + index + 'to ' + targetIndex);
    // dispatch({ type: 'MOVE', index: index, targetIndex: targetIndex });
  };

  return (
    <div style={{ position: 'relative', width: '100%', minHeight: '800px', transition: '.6' }}>
      <TrainingContainer>
        <div>
          {!viewMode && (
            <div>
              <FrButton
                disabled={training}
                loading={training ? 1 : undefined}
                onClick={() => trainBot()}
                margin={'0'}
              >
                <Cog />
                {training === null
                  ? 'Train Bot'
                  : training === false
                  ? 'Retrain Bot'
                  : 'Training...'}

                {training && <Progress timeLeft={300} />}
              </FrButton>
            </div>
          )}

          <FrButton
            margin={'0 0 0 5px'}
            disabled={training || training === null}
            onClick={() => setShowBotChat(!showBotChat)}
          >
            <Robot /> Chat with Bot
          </FrButton>
        </div>
        {!viewMode && (
          <div>
            <FrButton
              onClick={() => changeVisibility(isVisible)}
              disabled={!hasModes}
              background={'var(--grey-3)'}
              margin={'0 5px 0 0'}
            >
              {!isVisible ? 'Publish' : 'Unpublish'}
            </FrButton>
            <FrButton
              onClick={() =>
                props.history.push(ROUTES.SCENARIOS + ROUTES.SCENARIO + '/' + props.match.params.id)
              }
              disabled={!isVisible}
            >
              View this scenario
            </FrButton>
          </div>
        )}
      </TrainingContainer>
      {loading && (
        <Loading loadingText={'Loading exchanges...'} width={'80px'} containerHeight={'400px'} />
      )}
      {!loading && (
        <List>
          {state.exchanges &&
            state.exchanges.map(exchange => (
              <Exchange
                key={exchange.id}
                exchangeId={exchange.id}
                newExchange={!exchange.playerLines ? exchange : null}
                characters={{
                  bot: {
                    img: characters.character_1.character === 'EmployeeF' ? female : male,
                    name: characters.character_1.displayName,
                    gender: characters.character_1.character === 'EmployeeF' ? 'Female' : 'Male',
                    voiceName: characters.character_1.voiceName,
                  },
                  player: {
                    img: characters.character_2.character === 'EmployeeF' ? female : male,
                    gender: characters.character_2.character === 'EmployeeF' ? 'Female' : 'Male',
                    voiceName: characters.character_2.voiceName,
                  },
                }}
                locale={locale}
                mode={mode}
                scenarioId={scenarioId}
                removeExchange={id => removeExchange(id)}
                moveExchange={() => moveExchange()}
                firebase={firebase}
                viewMode={viewMode}
              />
            ))}

          {viewMode && state.exchanges && state.exchanges.length === 0 && (
            <h3 style={{ color: 'var(--grey-1)', padding: '0' }}>
              Hmm, it appears that this scenario is missing the {mode} dialogue...
            </h3>
          )}

          {!viewMode && !loading && (
            <NewExchange
              add={(intent, description, custom) =>
                dispatch({
                  type: 'ADD',
                  intent: custom ? intent : `${scenarioId}_${mode}_${intent}`,
                  description: description,
                  id: custom ? intent.split(' ').join('_') : intent,
                  character: 'character_1',
                })
              }
              scenarioId={scenarioId}
              mode={mode}
            />
          )}
        </List>
      )}

      {characters &&
        characters.character_1 &&
        characters.character_1.displayName &&
        training === false && (
          <ScenarioBot
            show={showBotChat}
            setShow={setShowBotChat}
            botName={characters.character_1.displayName}
            scenarioId={scenarioId}
            locale={locale}
          />
        )}
    </div>
  );
};

const NewExchange = ({ add, mode, scenarioId }) => {
  const [intent, setIntent] = useState('');
  const [custom, setCustom] = useState(false);
  const [description, setDescription] = useState('');

  const generateIntentId = intent => {
    let intentId = intent.replace(/\s+/g, '-').replace(/[|<>"?*:\\.$[\]#/@]/g, '');
    return intentId;
  };

  const onClick = () => {
    if (custom) {
      add(intent.toLocaleLowerCase().trim(), description, custom);
      setIntent('');
    } else {
      add(generateIntentId(intent.toLocaleLowerCase().trim()), description);
      setIntent('');
    }
  };

  useEffect(() => {
    if (mode === 'generic') {
      setCustom(true);
    }
  }, [mode]);

  return (
    <NewExchangeContainer>
      <h3 style={{ padding: '0 0 10px' }}>New Topic</h3>
      {!custom ? (
        <FrLabel color={'var(--grey-2)'}>
          Topic
          {intent.length !== 0 && ' ' + scenarioId + '_' + mode + '_' + generateIntentId(intent)}
        </FrLabel>
      ) : (
        <FrLabel color={'var(--grey-2)'}>Topic {intent}</FrLabel>
      )}
      <NewExchangeWrapper margin={'5px 0 0 0'}>
        <FrInput
          maxlength={50}
          background={'var(--grey-5)'}
          margin={'0'}
          value={intent}
          onChange={e => setIntent(e.target.value)}
          placeholder="Add a topic title"
        />
      </NewExchangeWrapper>

      <NewExchangeWrapper margin={'2px 15px'}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input type="checkbox" checked={custom} onChange={() => setCustom(!custom)} />
          <span>Enable custom intent ID</span>
        </div>
      </NewExchangeWrapper>

      <NewExchangeWrapper margin={'10px 0'}>
        <FrLabel color={'var(--grey-2)'}>Description</FrLabel>
        <FrTextarea
          placeholder="Describe the topic..."
          background={'var(--grey-5)'}
          value={description}
          minRows={2}
          onChange={e => setDescription(e.target.value)}
        />
      </NewExchangeWrapper>

      <FrButton
        margin={'0 5px'}
        disabled={intent.length === 0 || intent === ' ' || description === ''}
        onClick={() => onClick()}
      >
        Add
      </FrButton>
    </NewExchangeContainer>
  );
};

const Exchange = ({
  exchangeId,
  mode,
  scenarioId,
  characters,
  firebase,
  newExchange,
  removeExchange,
  viewMode,
  locale,
}) => {
  const [botLines, setBotLines] = useState(null);
  const [playerLines, setPlayerLines] = useState(null);
  const [exchange, setExchange] = useState(null);
  const [intent, setIntent] = useState('');
  const [description, setDescription] = useState('');
  const [editIntent, setEditIntent] = useState(false);
  const placeholderPlayer = {
    ask: 'Enter example question...',
    explain: 'Write down your explanation...',
    answer: 'Enter example answer',
  };
  const placeholderBot = {
    ask: 'Enter example answer...',
    explain: 'Enter example answer...',
    answer: 'Enter example question',
  };

  useEffect(() => {
    return () => {
      firebase.scenarioModeExchange().off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (exchangeId && scenarioId) {
      firebase.scenarioModeExchange(scenarioId, mode, exchangeId).on('value', snapshot => {
        const exchangeObj = snapshot.val();
        if (exchangeObj) {
          setExchange(exchangeObj);
          setIntent(exchangeObj.intent);
          setDescription(exchangeObj.description ? exchangeObj.description : '');
          setBotLines(
            exchangeObj.botLines
              ? exchangeObj.botLines
              : [{ audio: '', text: '', mood: 'neutral' }],
          );
          setPlayerLines(
            exchangeObj.playerLines
              ? exchangeObj.playerLines
              : [{ audio: '', text: '', mood: 'neutral' }],
          );
        } else {
          if (newExchange) {
            setExchange(newExchange);
            setIntent(newExchange.intent);
            setDescription(newExchange.description);
            setBotLines([{ audio: '', text: '', mood: 'neutral' }]);
            setPlayerLines([{ audio: '', text: '', mood: 'neutral' }]);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchangeId, scenarioId]);

  const prettifyIntent = intent => {
    return intent
      .slice(intent.lastIndexOf('_') + 1, intent.length)
      .split('-')
      .join(' ');
  };

  const save = data => {
    firebase
      .scenarioModeExchange(scenarioId, mode, exchangeId)
      .update({ ...exchange, ...data, id: null });
  };

  const onBlur = () => {
    if (
      intent !== '' &&
      intent.trim() !== '' &&
      intent.trim() !== exchange.intent &&
      scenarioId &&
      mode
    ) {
      save({ intent: intent });
    }
    setEditIntent(false);
  };

  const onBlurDesc = () => {
    if (description !== '' && description.trim() !== '' && description !== exchange.description) {
      save({ description: description });
    }
  };

  const editLines = (lineType, arr, index, data) => {
    const newLines = [...arr];
    newLines[index] = data;
    save({ [lineType]: newLines });
  };

  const removeLine = (lineType, arr, index) => {
    const newLines = [...arr];
    newLines.splice(index, 1);
    if (arr[index] && arr[index].text === '') {
      if (lineType === 'botLines') {
        setBotLines(newLines);
      } else {
        setPlayerLines(newLines);
      }
    } else {
      save({ [lineType]: newLines });
    }
  };

  const addLine = arr => {
    return [...arr, { text: '', mood: 'neutral', audio: '' }];
  };

  const generateAudio = (params, scenarioPath) => {
    if (params && params.voiceName && params.gender) {
      return firebase
        .doGenerateSpeechByPath({
          scenarioPath: scenarioPath,
          lang: locale,
          gender: mode !== 'generic' ? params.gender : '*',
          voiceName: mode !== 'generic' ? params.voiceName : '*',
          format: 'LINEAR16',
          replace: true,
        })
        .then(res => {
          return res;
        })
        .catch(err => {
          return err;
        });
    }
  };

  return (
    <>
      {exchange && (
        <ExchangeContainer>
          <TopBar>
            {exchange && exchange.intent && (
              <>
                {editIntent && (
                  <FrInput
                    type="text"
                    autoFocus
                    value={intent}
                    onBlur={() => onBlur()}
                    onChange={e => setIntent(e.target.value)}
                  />
                )}
                {!editIntent && !viewMode && intent && (
                  <p onClick={() => setEditIntent(true)}>
                    {mode === 'generic' ? intent : prettifyIntent(intent)} <Edit />
                  </p>
                )}
                {viewMode && <p>{prettifyIntent(intent)}</p>}
              </>
            )}
            <LineDiv></LineDiv>
            {!viewMode && (
              <ButtonContainer>
                <button onClick={() => removeExchange(exchange.id)}>
                  <Trash />
                </button>
                {/* index === 0  () => moveExchange(index, index - 1) */}
                <button disabled={true} onClick={() => console.log('moving exchange')}>
                  <ChevUp />
                </button>
                {/* index === state.exchanges.length - 1 () => moveExchange(index, index + 1) */}
                <button disabled={true} onClick={() => console.log('moving exchange')}>
                  <ChevDown />
                </button>
              </ButtonContainer>
            )}
          </TopBar>

          <DescriptionWrapper>
            {!viewMode && (
              <FrTextarea
                placeholder="Describe the topic title..."
                background={'var(--grey-5)'}
                value={description}
                onChange={e => setDescription(e.target.value)}
                onBlur={() => onBlurDesc()}
              />
            )}
            {viewMode && <p>{description}</p>}
          </DescriptionWrapper>

          <ExchangeWrapper mode={mode}>
            {mode !== 'generic' && (
              <ActorContainer player>
                <ActorWrapper player>
                  <h5>Player</h5>
                  <List>
                    {playerLines &&
                      playerLines.map((line, index) => (
                        <StatementContainer player key={index}>
                          <IntentStatement
                            statement={line}
                            remove={() => removeLine('playerLines', playerLines, index)}
                            edit={data => editLines('playerLines', playerLines, index, data)}
                            disableDelete={index === 0 && playerLines.length === 1}
                            filePath={
                              characters.player &&
                              characters.player.voiceName &&
                              characters.player.gender &&
                              locale
                                ? `${locale}/${characters.player.gender}/${characters.player.voiceName}`
                                : null
                            }
                            generateAudio={() =>
                              generateAudio(
                                characters && characters.player,
                                `/scenarios/${scenarioId}/modes/${mode}/exchanges/${exchangeId}/playerLines/${index}`,
                              )
                            }
                            placeHolder={placeholderPlayer[mode]}
                            viewMode={viewMode}
                          />
                        </StatementContainer>
                      ))}

                    {!viewMode && (
                      <AddStatementBtn
                        player
                        onClick={() => setPlayerLines(playerLines => addLine(playerLines))}
                      >
                        <PlusCircle />
                      </AddStatementBtn>
                    )}
                  </List>
                  {characters && characters.player && (
                    <img src={characters.player.img} alt="Character logo" />
                  )}
                </ActorWrapper>
              </ActorContainer>
            )}
            <ActorContainer>
              <ActorWrapper>
                {characters && characters.bot && mode !== 'generic' && (
                  <>
                    <img src={characters.bot.img} alt="Character logo" />
                    <h5>{characters.bot.name}</h5>
                  </>
                )}
                {mode === 'generic' && (
                  <>
                    <Robot
                      style={{
                        width: '40px',
                        height: '38px',
                        padding: '5px',
                        color: 'var(--key-color)',
                        borderRadius: '50%',
                        border: '2px solid var(--key-color)',
                      }}
                    />
                    <h5>Bot</h5>
                  </>
                )}
                <List>
                  {botLines &&
                    botLines.map((line, index) => (
                      <StatementContainer key={index}>
                        <IntentStatement
                          statement={line}
                          remove={() => removeLine('botLines', botLines, index)}
                          edit={data => editLines('botLines', botLines, index, data)}
                          filePath={
                            characters &&
                            characters.bot &&
                            characters.bot.voiceName &&
                            characters.bot.gender &&
                            locale
                              ? `${locale}/${characters.bot.gender}/${characters.bot.voiceName}`
                              : null
                          }
                          generateAudio={() =>
                            generateAudio(
                              characters && characters.bot,
                              `/scenarios/${scenarioId}/modes/${mode}/exchanges/${exchangeId}/botLines/${index}`,
                            )
                          }
                          disableDelete={index === 0 && botLines.length === 1}
                          placeholder={placeholderBot[mode]}
                          viewMode={viewMode}
                        />
                      </StatementContainer>
                    ))}
                  {!viewMode && (
                    <AddStatementBtn onClick={() => setBotLines(botLines => addLine(botLines))}>
                      <PlusCircle />
                    </AddStatementBtn>
                  )}
                </List>
              </ActorWrapper>
            </ActorContainer>
          </ExchangeWrapper>
        </ExchangeContainer>
      )}
    </>
  );
};

const IntentStatement = ({
  statement,
  remove,
  edit,
  disableDelete,
  placeHolder,
  viewMode,
  generateAudio,
  filePath,
}) => {
  const [text, setText] = useState('');
  const [audio, setAudio] = useState(null);
  const [mood, setMood] = useState('');
  const [loadingAudio, setLoadingAudio] = useState(false);

  const audioUrl = fileName => {
    return (
      'https://firebasestorage.googleapis.com/v0/b/' +
      process.env.REACT_APP_STORAGE_BUCKET +
      '/o/' +
      encodeURIComponent(`assets/generated/${fileName}`) +
      '?alt=media'
    );
  };

  useEffect(() => {
    if (statement) {
      if (statement.text !== text) {
        setText(statement.text ? statement.text : '');
      }
      if (statement.mood !== mood) {
        setMood(statement.mood ? statement.mood : 'neutral');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statement]);

  useEffect(() => {
    if (statement.audio && filePath && audio === null) {
      if (typeof statement.audio === 'object') {
        let audioVal = filePath.split('/').reduce((item, index) => item[index], statement.audio);
        setAudio(audioUrl(`${filePath}/${audioVal}`));
      }
      setLoadingAudio(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statement.audio, filePath]);

  const generateStatementAudio = () => {
    setLoadingAudio(true);
    generateAudio().then(() => {
      setLoadingAudio(false);
    });
  };

  const save = data => {
    edit({ ...statement, ...data });
  };

  useEffect(() => {
    if (mood && mood !== '' && mood !== statement.mood && text !== '') {
      save({ mood: mood });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mood]);

  const onBlur = () => {
    if (
      text &&
      text !== '' &&
      text.trim() !== '' &&
      text !== statement.text &&
      text.trim() !== statement.text.trim()
    ) {
      save({ text: text, audio: null });
    } else if (text === '' && text.trim() === '') {
      remove();
    }
  };

  return (
    <>
      <StatementWrapper viewMode={viewMode}>
        {!viewMode && (
          <FrTextarea
            disabled={loadingAudio}
            placeholder={placeHolder ? placeHolder : 'Enter example...'}
            value={text}
            onChange={e => setText(e.target.value)}
            onBlur={() => onBlur()}
          />
        )}

        {viewMode && (
          <p style={{ width: '100%', fontSize: '14px', fontWeight: '500', padding: '5px 15px' }}>
            {text}
          </p>
        )}

        {!loadingAudio && audio && <AudioPlayer setAudio={setAudio} url={audio} />}

        {!viewMode && (loadingAudio || !audio) && (
          <div data-for="autogenerate" data-tip={!loadingAudio ? 'Generate audio' : 'Loading...'}>
            <StatementBtn
              disabled={text === '' || loadingAudio || !filePath}
              onClick={() => generateStatementAudio()}
              loading={loadingAudio ? 1 : undefined}
            >
              <Cog />
            </StatementBtn>
            <ReactTooltip
              style={{ maxWidth: '100px' }}
              id="autogenerate"
              multiline={true}
              html={true}
            />
          </div>
        )}

        {!viewMode && (
          <StatementBtn
            style={{ position: 'absolute', right: '0', height: '100%', borderRadius: '0' }}
            disabled={disableDelete || loadingAudio}
            onClick={() => remove()}
          >
            <Trash />
          </StatementBtn>
        )}
      </StatementWrapper>
      <MoodPicker disabled={loadingAudio || viewMode} mood={mood} setMood={setMood} />
    </>
  );
};

export default withFirebase(ExchangesList);

// Audio player button component
export const AudioPlayer = ({ url, setAudio }) => {
  const [playing, setPlaying] = useState(false);
  const btn = useRef(null);

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handleEnded = () => {
    setPlaying(!playing);
  };

  const handleError = () => {
    setPlaying(false);
    setAudio('');
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (btn && !btn.current.contains(event.target)) {
        setPlaying(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    window.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <StatementBtn ref={btn} onClick={() => handlePlayPause()}>
        {playing ? <Pause /> : <Play />}
      </StatementBtn>
      <ReactPlayer
        key={url}
        width="0"
        height="0"
        url={url}
        playing={playing}
        onError={() => handleError()}
        onEnded={() => handleEnded()}
      />
    </>
  );
};

//EMOJI COMPONENT <Emoji symbol="🐑" label="sheep"/>
const Emoji = props => (
  <EmojiWrapper
    fontSize={props.fontSize}
    role="img"
    aria-label={props.label ? props.label : ''}
    aria-hidden={props.label ? false : true}
  >
    {props.symbol}
  </EmojiWrapper>
);

const MoodPicker = props => {
  const moodsEmoji = [
    { id: 'neutral', label: 'Neutral', value: '🙂' },
    { id: 'unaffected', label: 'Unaffected', value: '😐' },
    { id: 'happy', label: 'Happy', value: '😄' },
    { id: 'interested', label: 'Interested', value: '🤓' },
    { id: 'inDoubt', label: 'In doubt', value: '🤔' },
    { id: 'bored', label: 'Bored', value: '😒' },
    { id: 'irritated', label: 'Irritated', value: '😤' },
  ];

  return (
    <MoodContainer disabled={props.disabled}>
      {props.mood && (
        <Emoji
          disabled={props.disabled}
          fontSize={'24px'}
          label={props.mood}
          symbol={moodsEmoji.find(md => md.id === props.mood).value}
        />
      )}
      <FoldOutMoods style={{ position: 'absolute' }}>
        {moodsEmoji
          .filter(m => m.id !== props.mood)
          .map(mood => (
            <button key={mood.id} onClick={() => props.setMood(mood.id)}>
              <Emoji fontSize={'24px'} label={mood.label} symbol={mood.value} />
              <h6>{mood.label}</h6>
            </button>
          ))}
        <div></div>
      </FoldOutMoods>
    </MoodContainer>
  );
};

const ScenarioBot = ({ botName, show, setShow, scenarioId, locale }) => {
  const [messages, setMessages] = useState([]);
  const [debugMessages, setDebugMessages] = useState([]);
  const [debugMode, setDebugMode] = useState(false);
  const [url, setUrl] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (locale && locale !== '' && show && !url) {
      let tempSenderId = uuid();
      setUrl(
        `https://${process.env.REACT_APP_PROJECT_ID}.engine.fictivereality.com/ai_server/webhooks/speech/webhook?sender_id=${tempSenderId}&lang=${locale}`,
      );
    }
  }, [locale, show, url]);

  useEffect(() => {
    if (url && scenarioId && scenarioId !== '') {
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: `/play_scenario ${JSON.stringify({ scenario: scenarioId })}`,
        }),
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data && data.messages) {
            let tempMessages = data.messages
              .filter(me => me.text)
              .map(m => new Message({ id: 1, message: m.text, senderName: botName }));
            setDebugMessages(oldArray => [
              ...oldArray,
              new Message({ id: 1, message: JSON.stringify(data), senderName: botName }),
            ]);
            setMessages(oldArray => [...oldArray, ...tempMessages]);
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, scenarioId]);

  const sendMessage = () => {
    setMessages(oldArray => [
      ...oldArray,
      new Message({ id: 0, message: message, senderName: 'You' }),
    ]);
    setDebugMessages(oldArray => [
      ...oldArray,
      new Message({ id: 0, message: message, senderName: 'You' }),
    ]);
    setMessage('');
    setIsTyping(true);

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message: message }),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data && data.messages) {
          let tempMessages = data.messages
            .filter(me => me.text)
            .map(m => new Message({ id: 1, message: m.text, senderName: botName }));
          setIsTyping(false);
          setDebugMessages(oldArray => [
            ...oldArray,
            new Message({ id: 1, message: JSON.stringify(data), senderName: botName }),
          ]);
          setMessages(oldArray => [...oldArray, ...tempMessages]);
        }
      })
      .catch(error => {
        setIsTyping(false);
        console.error('Error:', error);
      });
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter' && message.trim() !== '') {
      event.preventDefault();
      sendMessage();
    }
  };

  return (
    <ChatContainer show={show ? 1 : undefined}>
      <ChatTopBar>
        <h4>Bot Chat</h4>
        <div>
          <input type="checkbox" checked={debugMode} onChange={() => setDebugMode(!debugMode)} />
          <span>Debug</span>
          <button onClick={() => setShow(false)}>
            <ChevDown />
          </button>
        </div>
      </ChatTopBar>
      <ChatWrapper>
        {url && (
          <ChatFeed
            messages={debugMode === true ? debugMessages : messages}
            isTyping={isTyping}
            hasInputField={false}
            showSenderName
            bubblesCentered={false}
            bubbleStyles={{
              text: {
                fontSize: 14,
                fontWeight: 600,
                color: debugMode ? 'black' : 'white',
                wordBreak: 'break-word',
              },
              chatbubble: {
                borderRadius: 20,
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 20,
                paddingRight: 20,
                maxWidth: debugMode ? '100%' : '70%',
              },
            }}
          />
        )}
      </ChatWrapper>

      <ChatInputWrapper>
        <FrTextarea
          background={'white'}
          padding={'5px 10px'}
          margin={'0'}
          value={message}
          onChange={e => setMessage(e.target.value)}
          onKeyDown={e => handleKeyDown(e)}
          placeholder="Type here..."
        ></FrTextarea>
        <FrButton
          margin={'0 0 0 5px'}
          padding={'5px 20px'}
          disabled={message === '' || message.trim() === ''}
          onClick={() => sendMessage()}
        >
          Send
        </FrButton>
      </ChatInputWrapper>
    </ChatContainer>
  );
};

const Progress = ({ timeLeft }) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds < timeLeft) {
        setSeconds(seconds + 2);
      }
      if (seconds === timeLeft) {
        clearInterval(myInterval);
      }
    }, 2000);

    return () => {
      clearInterval(myInterval);
    };
  });

  const percentage = (partialValue, totalValue) => {
    return (100 * partialValue) / totalValue;
  };

  return (
    <ProgressWrapper>
      <ProgressBar
        style={{ borderRadius: '0 !important' }}
        percent={percentage(seconds, timeLeft)}
        filledBackground={'white'}
      />
    </ProgressWrapper>
  );
};
