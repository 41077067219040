import React, { useEffect, useContext, useState } from 'react';
import { withAuthorization } from '../../components/Session';
import { HomePageContainer } from './style';
import Loading from '../../components/Loading';
import * as ROUTES from '../../constants/routes';
import { AdminFilterContext } from '../../components/AdminFilter/AdminFilterContext';
import { compose } from 'recompose';
import { withFirebase } from '../../components/Firebase';
import { PageTitle } from '../Admin/AdminHeader';
import { FrButton } from '../../components/DesignSystem/style.js';
import { DialogueMenuUL, ListItem } from '../ScenarioEditor/style';
import { Play } from 'styled-icons/fa-solid/Play';
import { Trophy } from 'styled-icons/boxicons-solid/Trophy';
import { CopperCoin } from 'styled-icons/remix-fill/CopperCoin';
import { SessionWidget } from '../SessionsList';
import { formatSessionLength } from '../../helpFunctions/session';
import { TheaterMasks } from 'styled-icons/fa-solid/TheaterMasks';
import { Bubbles2 } from 'styled-icons/icomoon/Bubbles2';
import { Star } from 'styled-icons/boxicons-solid/Star';
import { USER_GUIDE } from '../../constants/urls';
import {
  MainContainer,
  MainWrapper,
  LeaderBoardContainer,
  LeaderBoardUL,
  CurrentUserContainer,
  ShowMoreButton,
  CurrentUserWrapper,
  PlayButton,
  TopScoreContainer,
  TopScoreWrapper,
  ScoreListItem,
  HelpContainer,
} from './style';

const HomePage = props => {
  const { state } = useContext(AdminFilterContext);
  const [currentUser, setCurrentUser] = useState(null);
  const [scenarioStats, setScenarioStats] = useState(null);
  const [scoreMode, setScoreMode] = useState('playtime');
  const [sortedUsers, setSortedUsers] = useState(null);

  const getClientUsers = (selectedClient, sessions) => {
    props.firebase
      .newUsers()
      .orderByChild('clients/' + selectedClient)
      .equalTo(true)
      .once('value', snapshot => {
        const tempClientUsersObj = snapshot.val();
        if (tempClientUsersObj) {
          const tempUsersArr = Object.keys(tempClientUsersObj).map(key => {
            let userSession = sessions && sessions.find(sess => sess.id === key);
            return {
              displayName: tempClientUsersObj[key].displayName,
              id: key,
              sessionsPlayed:
                userSession && userSession.totalSessions ? userSession.totalSessions : 0,
              starsCollected: getScenarioStars(tempClientUsersObj[key].scenarios),
              playtime: userSession && userSession.len ? userSession.len : 0,
            };
          });
          
          setCurrentUser(tempUsersArr.find(user => user.id === state.selectedUser));

          let sortedOnPlaytime = [...tempUsersArr]
            .sort((a, b) => (a.playtime > b.playtime ? 1 : -1))
            .reverse();

          let sortedOnStars = [...tempUsersArr]
            .sort((a, b) => (a.starsCollected > b.starsCollected ? 1 : -1))
            .reverse();

          let sortedOnScenarios = [...tempUsersArr]
            .sort((a, b) => (a.sessionsPlayed > b.sessionsPlayed ? 1 : -1))
            .reverse();

          setSortedUsers({
            starsCollected: sortedOnStars,
            playtime: sortedOnPlaytime,
            sessionsPlayed: sortedOnScenarios,
          });
        } else {
          setSortedUsers({
            starsCollected: [],
            playtime: [],
            sessionsPlayed: [],
          });
        }
      });
  };

  const getScenarioStars = scenarios => {
    if (scenarios && scenarios.length !== 0) {
      let tempScenarios = Object.keys(scenarios).map(key => {
        let exchanges = scenarios[key].exchanges;
        if (exchanges) {
          let voiceLines = Object.keys(exchanges)
            .map(k => ({ ...exchanges[k] }))
            .filter(vl => vl.audioAnswer && vl.audioAnswer !== '');
          return {
            bestAnswerCount: voiceLines.length,
          };
        } else {
          return {
            bestAnswerCount: 0,
          };
        }
      });
      let tempBestAnswers = tempScenarios.reduce(
        (sum, { bestAnswerCount }) => sum + bestAnswerCount,
        0,
      );
      return tempBestAnswers;
    } else {
      return 0;
    }
  };

  const getPlaytime = sessions => {
    if (sessions && sessions.length > 0) {
      return sessions
        .filter(session => session.session_length && session.session_length !== '')
        .reduce((sum, { session_length }) => sum + parseInt(session_length), 0);
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (state.selectedClient && !sortedUsers) {
      props.firebase.clientSessions(state.selectedClient).once('value', snapshot => {
        const tempSessions = snapshot.val();
        if (tempSessions) {
          let tempUserSessions = Object.keys(tempSessions).map(key => ({
            id: key,
            len: getPlaytime(Object.values(tempSessions[key])),
            totalSessions: Object.values(tempSessions[key]).length,
          }));
          getClientUsers(state.selectedClient, tempUserSessions);
        } else {
          getClientUsers(state.selectedClient, null);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedClient, sortedUsers]);

  useEffect(() => {
    if (!scenarioStats && props.authUser.scenarios && !state.loading && state.scenarios) {
      let scenariosInClient = state.scenarios.filter(scenario => scenario.visible === true);
      let userScenarios = scenariosInClient.filter(
        scenario => props.authUser.scenarios[scenario.id],
      );
      let unPlayed = scenariosInClient.length - userScenarios.length;
      let played = userScenarios.length;
      setScenarioStats({ unPlayed: unPlayed > 0 ? unPlayed : 0, played });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.authUser.scenarios, state.loading, scenarioStats]);

  return (
    <HomePageContainer>
      <PageTitle title={'Home'} withDate={true} padding={'40px 0 10px 0'} />
      <MainContainer>
        <MainWrapper
          background={'linear-gradient(91.82deg, #9D89FF 3.83%, #8067FF 98.41%)'}
          margin={'5px 5px 5px 0'}
        >
          {props.authUser && props.authUser.scenarios && (
            <h2 style={{ marginBottom: '20px', color: 'white' }}>
              Welcome Back, {props.authUser.displayName.split(' ')[0]}!
            </h2>
          )}
          {props.authUser && !props.authUser.scenarios && (
            <h2 style={{ marginBottom: '20px', color: 'white' }}>
              Welcome {props.authUser.displayName.split(' ')[0]}!
            </h2>
          )}
          <p>
            You have <span>{scenarioStats ? scenarioStats.unPlayed : '?'}</span> unplayed scenarios
            to explore
          </p>
          {scenarioStats && scenarioStats.played !== 0 && (
            <p>
              and <span>{scenarioStats ? scenarioStats.played : '?'}</span> scenarios to practice
              more on.
            </p>
          )}
          <FrButton margin={'20px 5px 0 0'} onClick={() => props.history.push(ROUTES.SCENARIOS)}>
            Go to scenario list
          </FrButton>
          <img src={require('../../assets/home-page-welcome.png')} alt="" />
        </MainWrapper>
        <MainWrapper margin={'5px 0 5px 5px'} flex={'1 1 320px'}>
          {currentUser && (
            <>
              <h3>Your score</h3>
              <ul>
                <ScoreListItem scoreColor={'rgb(102,219,190)'}>
                  <TheaterMasks /> Playtime:
                  <span>{formatSessionLength(currentUser.playtime, true)}</span>
                </ScoreListItem>
                <ScoreListItem scoreColor={'rgb(255 120 106)'}>
                  <Bubbles2 />
                  Sessions played:<span>{currentUser.sessionsPlayed}</span>
                </ScoreListItem>
                <ScoreListItem scoreColor={'rgb(255 210 64)'}>
                  <Star />
                  Stars collected:<span>{currentUser.starsCollected}</span>
                </ScoreListItem>
              </ul>
            </>
          )}
          {!currentUser && (
            <Loading loadingText={'Loading score...'} width={'80px'} containerHeight={'100%'} />
          )}
        </MainWrapper>
      </MainContainer>
      {sortedUsers && (
        <>
          <h2 style={{ width: '100%', padding: '20px 0' }}>Top players</h2>
          <TopScoreContainer>
            <TopScoreWrapper
              border={'rgb(102, 219, 190)'}
              color={'linear-gradient(93.41deg, #59D8B9 1.21%, #89E1CC 95.08%)'}
              onClick={() => setScoreMode('playtime')}
            >
              <Trophy />
              {sortedUsers.playtime &&
              sortedUsers.playtime[0] &&
              sortedUsers.playtime[0].playtime !== 0 ? (
                <h2>
                  <span>1# Playtime</span>
                  {sortedUsers.playtime[0].displayName}
                  <span>
                    {'Total: ' + formatSessionLength(sortedUsers.playtime[0].playtime, true)}
                  </span>
                </h2>
              ) : (
                <h2>
                  <span>1# Playtime</span>
                  No data <span>Be the one to claim #1</span>
                </h2>
              )}
            </TopScoreWrapper>
            <TopScoreWrapper
              border={'rgb(255 120 106)'}
              color={'linear-gradient(94.78deg, #FF6F60 0%, #FF9B90 100%)'}
              onClick={() => setScoreMode('sessionsPlayed')}
            >
              <Trophy />
              {sortedUsers.sessionsPlayed &&
              sortedUsers.sessionsPlayed[0] &&
              sortedUsers.sessionsPlayed[0].sessionsPlayed !== 0 ? (
                <h2>
                  <span>1# Sessions played</span>
                  {sortedUsers.sessionsPlayed[0].displayName}
                  <span>{'Total: ' + sortedUsers.sessionsPlayed[0].sessionsPlayed}</span>
                </h2>
              ) : (
                <h2>
                  <span>1# Sessions played</span>
                  No data <span>Be the one to claim #1</span>
                </h2>
              )}
            </TopScoreWrapper>
            <TopScoreWrapper
              border={'rgb(255 210 64)'}
              color={'linear-gradient(94.78deg, #FFD038 0%, #FFD653 100%)'}
              onClick={() => setScoreMode('starsCollected')}
            >
              <Trophy />
              {sortedUsers.starsCollected &&
              sortedUsers.starsCollected[0] &&
              sortedUsers.starsCollected[0].starsCollected !== 0 ? (
                <h2>
                  <span>1# Stars collected</span>
                  {sortedUsers.starsCollected[0].displayName}
                  <span>{'Total: ' + sortedUsers.starsCollected[0].starsCollected}</span>
                </h2>
              ) : (
                <h2>
                  <span>1# Stars collected</span>
                  No data <span>Be the one to claim #1</span>
                </h2>
              )}
            </TopScoreWrapper>
          </TopScoreContainer>
        </>
      )}
      <h2 style={{ width: '100%', padding: '20px 0' }}>Leaderboard</h2>
      <DialogueMenuUL>
        <ListItem selected={scoreMode === 'playtime'} onClick={() => setScoreMode('playtime')}>
          Playtime
        </ListItem>
        <ListItem
          selected={scoreMode === 'sessionsPlayed'}
          onClick={() => setScoreMode('sessionsPlayed')}
        >
          Sessions played
        </ListItem>
        <ListItem
          selected={scoreMode === 'starsCollected'}
          onClick={() => setScoreMode('starsCollected')}
        >
          Stars collected
        </ListItem>
      </DialogueMenuUL>

      <LeaderBoard
        history={props.history}
        users={sortedUsers}
        currentUser={state.selectedUser}
        scoreMode={scoreMode}
      />

      {!state.loading && state.sessions && state.sessions.length > 0 && (
        <>
          <h2 style={{ width: '100%', padding: '60px 0 20px' }}>Most recent sessions</h2>
          <SessionWidget
            scenarios={state.scenarios}
            sessions={state.sessions.slice(state.sessions.length - 3, state.sessions.length)}
            authUser={props.authUser}
            history={props.history}
          />
          <ShowMoreButton onClick={() => props.history.push(ROUTES.SESSIONS)}>
            Go to sessions list
          </ShowMoreButton>
        </>
      )}

      <HelpContainer>
        <h2>Need help?</h2>
        <h4>
          Having trouble getting started?{' '}
          <a href={USER_GUIDE} target="_blank" rel="noopener noreferrer">
            View our guide!
          </a>
        </h4>
        <img src={require('../../assets/mask-group.png')} alt="" />
      </HelpContainer>
    </HomePageContainer>
  );
};

const condition = authUser => !!authUser;

export default compose(withAuthorization(condition), withFirebase)(HomePage);

const LeaderBoard = props => {
  const [usersToShow, setUsersToShow] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (props.users && props.users[props.scoreMode] && props.currentUser && props.scoreMode) {
      if (props.users[props.scoreMode].length > 5) {
        let currentUserIndex = props.users[props.scoreMode].findIndex(
          user => user.id === props.currentUser,
        );
        if (!showMore) {
          let topFive = props.users[props.scoreMode].slice(0, 5);
          if (currentUserIndex > 5) {
            setCurrentUser({
              ...props.users[props.scoreMode][currentUserIndex],
              index: currentUserIndex + 1,
            });
          } else {
            setCurrentUser(null);
          }
          setUsersToShow(topFive);
        } else {
          let topTen = props.users[props.scoreMode].slice(0, 10);
          if (currentUserIndex > 10) {
            setCurrentUser({
              ...props.users[props.scoreMode][currentUserIndex],
              index: currentUserIndex + 1,
            });
          } else {
            setCurrentUser(null);
          }
          setUsersToShow(topTen);
        }
      } else {
        setUsersToShow(props.users[props.scoreMode]);
      }
    }
  }, [props.users, props.currentUser, showMore, props.scoreMode]);

  return (
    <LeaderBoardContainer>
      {usersToShow && (
        <LeaderBoardUL>
          {usersToShow &&
            usersToShow[0][props.scoreMode] !== 0 &&
            usersToShow.map((user, index) => (
              <li key={user.id}>
                <h3>Rank {index + 1}</h3>
                {index <= 2 ? <Trophy /> : <CopperCoin />}
                <h2>
                  {user.displayName && user.displayName.split(' ')[0]}
                  {user.id === props.currentUser && (
                    <span style={{ color: 'var(--grey-2)' }}> {`(You)`}</span>
                  )}
                  <br />
                  {user.displayName && user.displayName.split(' ')[1] && (
                    <span style={{ color: 'var(--grey-2)', fontSize: '16px' }}>
                      {user.displayName.split(' ')[1]}
                    </span>
                  )}
                </h2>
                {user && props.scoreMode && (
                  <LeaderBoardScore scoreMode={props.scoreMode} user={user} />
                )}
              </li>
            ))}
          {usersToShow &&
            (usersToShow.length === 0 ||
              (usersToShow[0] && usersToShow[0][props.scoreMode] === 0)) && (
              <li>
                <h2>Not enough data to display leaderboard stats</h2>
              </li>
            )}

          {props.users &&
            props.users[props.scoreMode] &&
            props.users[props.scoreMode].length > 5 &&
            usersToShow[0][props.scoreMode] !== 0 && (
              <CurrentUserContainer>
                {currentUser && (
                  <CurrentUserWrapper>
                    <h3>Rank {currentUser.index}</h3>
                    <CopperCoin />
                    <h2>
                      {currentUser.displayName.split(' ')[0]}
                      <span style={{ color: 'var(--grey-2)' }}> {`(You)`}</span>
                    </h2>
                    {props.scoreMode && (
                      <LeaderBoardScore scoreMode={props.scoreMode} user={currentUser} />
                    )}
                  </CurrentUserWrapper>
                )}
                <button onClick={() => setShowMore(!showMore)}>
                  {!showMore ? 'Show top 10 players' : 'Show top 5 players'}
                </button>
              </CurrentUserContainer>
            )}
        </LeaderBoardUL>
      )}
      {!usersToShow && (
        <Loading loadingText={'Loading leaderboard...'} width={'80px'} containerHeight={'400px'} />
      )}
      {usersToShow && (
        <PlayButton onClick={() => props.history.push(ROUTES.DOWNLOADS)}>
          <Play />
        </PlayButton>
      )}
    </LeaderBoardContainer>
  );
};

const LeaderBoardScore = props => {
  return (
    <>
      {props.scoreMode === 'playtime' && (
        <h4>
          Playtime:
          <span>{props.user ? formatSessionLength(props.user.playtime, true) : 0}</span>
        </h4>
      )}
      {props.scoreMode === 'sessionsPlayed' && (
        <h4>
          Sessions played:
          <span>{props.user ? props.user.sessionsPlayed : 0}</span>
        </h4>
      )}
      {props.scoreMode === 'starsCollected' && (
        <h4>
          Stars collected:
          <span>{props.user ? props.user.starsCollected : 0}</span>
        </h4>
      )}
    </>
  );
};
