import React from 'react';
import { withFirebase } from '../Firebase';
import { LogOutStyle } from './style'
import { LogOutCircle } from 'styled-icons/boxicons-regular/LogOutCircle';


const SignOutButton = ({ dataCy, history }) => {

  const signOut = () => {
    if(history && history.location && history.location) {
      history.location.state = null;
    }
    history.push('/signin');
    // firebase.doSignOut();
    // localStorage.clear();
  }

  return (
    <LogOutStyle
      onClick={() => signOut()}
      data-cy={dataCy}
    >
      <LogOutCircle/>
    </LogOutStyle>
  );
};

export default withFirebase(SignOutButton);