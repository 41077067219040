import styled from 'styled-components';
import { smoothIn } from '../../styles/keyframes';

export const ScenariosPageContainer = styled.div`
  width: 80%;
  max-width: 1000px;
  min-height: 100vh;
  display: flex;
  font-family: 'Montserrat';
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  transition: 0.4s;
  animation: ${smoothIn} 0.4s ease-in-out;
  padding: 0 0 80px;
  background: var(--background-color);

  ${'' /* h1 {
    color: #454545;
    width: 100%;
    font-size: 1.6em;
    font-weight: bold;
    display: flex;
    align-items: center;

    button {
      padding: 0 5px 0 0;
      border: none;
      background: transparent;
      outline: none;
      font-weight: 500;
      svg {
        cursor: pointer;
        width: 40px;
        color: var(--key-color-2);
      }
    }
  } */}

  h2 {
    color: #454545;
    width: 100%;
    font-size: 1.2em;
    padding: 1em 10px;
    font-weight: bold;
  }

  ul {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ListHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: transparent;
  width: 100%;
  padding: 5px 30px;

  p {
    font-size: 12px;
    font-weight: 600;
    width: 20%;
    color: #454545;
  }
`;

export const NewScenarioButton = styled.button`
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  background: var(--key-color-2);
  color: white;
  min-width: 200px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  position: fixed;
  bottom: 20px;
  right: 20px;

  svg {
    margin-left: 10px;
    width: 20px;
  }
`;

export const NoScenarios = styled.h3`
  background: white;
  padding: 28px 20px;
  border-radius: 20px;
  width: 100%;
  font-size: 18px;
  color: var(--grey);
  box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
  z-index: 3;
`;
