import React from 'react';
import { FormWrapper } from './style';
import { PasswordForgetLink } from '../../screens/PasswordForget';
import { FrButton, FrInput, FrLabel } from '../DesignSystem/style';

const Form = ({ inputs, buttonText, onSubmit, isInValid, passwordLink }) => {
  return (
    <FormWrapper passwordLink={passwordLink}>
      <form autoComplete="on" onSubmit={e => onSubmit(e)}>
        {inputs.map((input, index) => (
          <div style={{width: '100%', margin: '5px 0', display: 'flex', flexDirection: 'column'}} key={index}>
            <FrLabel>{input.label}</FrLabel>
            <FrInput
              autoComplete={input.autoComplete}
              type={input.type}
              name={input.name}
              value={input.value}
              onChange={e => input.onChange(e.target.value)}
              data-cy={input.dataCy}
            ></FrInput>
          </div>
        ))}
        <div>
          {passwordLink && <PasswordForgetLink />}
          <FrButton padding={"10px 20px"} background={'transparent'} border={'2px solid var(--key-color-2)'} color={'var(--key-color-2)'} disabled={isInValid} type="submit" data-cy="submit">
            {buttonText}
          </FrButton>
        </div>
      </form>
    </FormWrapper>
  );
};

export default Form;
