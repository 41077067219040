import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import Firebase, { FirebaseContext } from './components/Firebase';

if (process.env.NODE_ENV === 'production') { // Set if running from output of yarn build
  Sentry.init({
    dsn: "https://9d409615165a4d4ab6c02d9fdcca5fd8@sentry.io/2289887",
    release: 'fictive-front@' + process.env.REACT_APP_VERSION
  })
}
console.log("Version " + (process.env.REACT_APP_VERSION || process.env.NODE_ENV));
console.log("Database", process.env.REACT_APP_REALTIME_DB);

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
