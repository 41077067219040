import React, { useState, useEffect, useContext } from 'react';
import { withFirebase } from '../../components/Firebase';
import { AdminFilterContext } from '../../components/AdminFilter/AdminFilterContext';
// import { Check } from 'styled-icons/boxicons-regular/Check';
import { FrLabel, FrInput, FrError, FrTextarea } from '../../components/DesignSystem/style';
import { SelectorContainer } from '../../components/Selector';
import { coverImageURL } from '../../helpFunctions/storageImgUrls';
import { AudioPlayer } from './ExchangesList';
import {
  SettingsContainer,
  SettingsWrapper,
  SaveButton,
  CharPosContainer,
  CharWrapper,
  ImagePosContainer,
  RowWrapper,
  // Saved,
  ScenarioInfoContainer,
} from './style';

const initialSettingsState = {
  scenarioName: '',
  scenarioText: '',
  environment: '',
  videoUrl: '',
  videoTranscript: '',
  locale: '',
  charNameOne: '',
  charNameTwo: 'Player',
  charPosOne: 'Z',
  charPosTwo: 'F',
  playerPos: 'B',
  charOne: 'EmployeeF',
  charTwo: 'TeacherM',
  charVoiceNameOne: '',
  charVoiceNameTwo: '',
  playerChar: '',
  scenarioCoverImage: '',
};

const ScenarioSettings = ({
  saveSettings,
  settings,
  importedScenario,
  scenarioToEdit,
  addScenarioToDB,
  generateScenarioId,
  setGoogleSheet,
  googleSheet,
  // settingsSaved,
  noEdit,
  authUser,
  aiEnabled,
}) => {
  const { state } = useContext(AdminFilterContext);
  const [updateSheetUrl, setUpdateSheetUrl] = useState('');
  const [values, setValues] = useState(initialSettingsState);
  const [settErr, setSettErr] = useState(null);
  const [scenarioWithSettings, setScenarioWithSettings] = useState(null);

  const environments = ['Office', 'Apartment'];
  const languages = ['en-US', 'sv-SE'];

  const validateSettings = () => {
    if (aiEnabled) {
      return (
        values.scenarioName === '' ||
        values.scenarioText === '' ||
        values.environment === '' ||
        values.charNameOne === '' ||
        values.charNameTwo === '' ||
        values.charPosOne === '' ||
        values.charPosTwo === '' ||
        values.charOne === '' ||
        values.charTwo === '' ||
        values.locale === '' ||
        values.charVoiceNameOne === '' ||
        values.charVoiceNameTwo === ''
      );
    } else {
      return (
        values.scenarioName === '' ||
        values.scenarioText === '' ||
        values.environment === '' ||
        values.charNameOne === '' ||
        values.charNameTwo === '' ||
        values.charPosOne === '' ||
        values.charPosTwo === '' ||
        values.charOne === '' ||
        values.charTwo === ''
      );
    }
  };

  const isSaved = () => {
    if (aiEnabled) {
      return (
        values &&
        settings &&
        values.scenarioName === settings.scenarioName &&
        values.scenarioText === settings.scenarioText &&
        values.environment === settings.environment &&
        values.scenarioCoverImage === settings.coverImage &&
        settings.characters &&
        settings.characters.character_1 &&
        settings.characters.character_1.displayName === values.charNameOne &&
        settings.characters.character_1.character === values.charOne &&
        settings.characters.character_2 &&
        settings.characters.character_2.character === values.charTwo &&
        settings.characters.character_1.voiceName === values.charVoiceNameOne &&
        settings.characters.character_2.voiceName === values.charVoiceNameTwo &&
        values.locale === settings.locale
      );
    } else {
      return (
        values &&
        settings &&
        values.scenarioName === settings.scenarioName &&
        values.scenarioText === settings.scenarioText &&
        values.environment === settings.environment &&
        values.scenarioCoverImage === settings.coverImage &&
        settings.characters &&
        settings.characters.character_1 &&
        settings.characters.character_1.displayName === values.charNameOne &&
        settings.characters.character_1.character === values.charOne &&
        settings.characters.character_2 &&
        settings.characters.character_2.character === values.charTwo
      );
    }
  };

  // const validateSettings =
  //   values.scenarioName === '' ||
  //   values.scenarioText === '' ||
  //   values.environment === '' ||
  //   values.charNameOne === '' ||
  //   values.charNameTwo === '' ||
  //   values.charPosOne === '' ||
  //   values.charPosTwo === '' ||
  //   values.charOne === '' ||
  //   values.charTwo === '' ||
  //   values.locale === '' ||
  //   values.charVoiceNameOne === '' ||
  //   values.charVoiceNameTwo === '';

  // const isSaved =
  //   values &&
  //   settings &&
  //   values.scenarioName === settings.scenarioName &&
  //   values.scenarioText === settings.scenarioText &&
  //   values.environment === settings.environment &&
  //   values.scenarioCoverImage === settings.coverImage &&
  //   settings.characters &&
  //   settings.characters.character_1 &&
  //   settings.characters.character_1.displayName === values.charNameOne &&
  //   settings.characters.character_1.character === values.charOne &&
  //   settings.characters.character_2 &&
  //   settings.characters.character_2.character === values.charTwo &&
  //   settings.characters.character_1.voiceName === values.charVoiceNameOne &&
  //   settings.characters.character_2.voiceName === values.charVoiceNameTwo &&
  //   values.locale === settings.locale;

  let office = require('../../assets/environment_office.png');
  let apartment = require('../../assets/environment_apartment.png');

  const handleInputs = target => {
    setValues(state => ({ ...state, [target.name]: target.value }));
  };

  useEffect(() => {
    if (state.scenarios && scenarioToEdit && !scenarioWithSettings) {
      setScenarioWithSettings(state.scenarios.find(scen => scen.id === scenarioToEdit));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.scenarios, scenarioToEdit]);

  useEffect(() => {
    if (settings) {
      let char1 =
        settings.characters && settings.characters.character_1
          ? settings.characters.character_1
          : null;
      let char2 =
        settings.characters && settings.characters.character_2
          ? settings.characters.character_2
          : null;
      let char3 =
        settings.characters && settings.characters.character_3
          ? settings.characters.character_3
          : null;

      let tempSettObj;

      if (aiEnabled) {
        tempSettObj = {
          scenarioName: settings.scenarioName,
          scenarioText: settings.scenarioText,
          environment: settings.environment,
          videoUrl: settings.videoUrl,
          videoTranscript: settings.videoTranscript,
          locale: settings.locale ? settings.locale : '',
          charNameOne: char1 && char1.displayName ? char1.displayName : '',
          charNameTwo: char2 && char2.displayName ? char2.displayName : 'Example answer',
          charPosOne: char1 && char1.spawnPosition ? char1.spawnPosition : 'Z',
          charPosTwo: char2 && char2.spawnPosition ? char2.spawnPosition : 'F',
          playerPos: char3 && char3.spawnPosition ? char3.spawnPosition : 'B',
          charOne: char1 && char1.character ? char1.character : 'EmployeeF',
          charTwo: char2 && char2.character ? char2.character : 'TeacherM',
          charVoiceNameOne: char1 && char1.voiceName ? char1.voiceName : '',
          charVoiceNameTwo: char2 && char2.voiceName ? char2.voiceName : '',
          playerChar: '',
          scenarioCoverImage: settings.coverImage ? settings.coverImage : '',
        };
      } else {
        tempSettObj = {
          scenarioName: settings.scenarioName,
          scenarioText: settings.scenarioText,
          environment: settings.environment,
          videoUrl: settings.videoUrl,
          videoTranscript: settings.videoTranscript,
          // locale: settings.locale ? settings.locale : '',
          charNameOne: char1 && char1.displayName ? char1.displayName : '',
          charNameTwo: char2 && char2.displayName ? char2.displayName : 'Example answer',
          charPosOne: char1 && char1.spawnPosition ? char1.spawnPosition : 'Z',
          charPosTwo: char2 && char2.spawnPosition ? char2.spawnPosition : 'F',
          playerPos: char3 && char3.spawnPosition ? char3.spawnPosition : 'B',
          charOne: char1 && char1.character ? char1.character : 'EmployeeF',
          charTwo: char2 && char2.character ? char2.character : 'TeacherM',
          // charVoiceNameOne: char1 && char1.voiceName ? char1.voiceName : '',
          // charVoiceNameTwo: char2 && char2.voiceName ? char2.voiceName : '',
          playerChar: '',
          scenarioCoverImage: settings.coverImage ? settings.coverImage : '',
        };
      }

      setValues(tempSettObj);
      setSettErr(settingsValidated(tempSettObj));
    } else {
      setSettErr(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    if (scenarioToEdit === '' && !settings) {
      setValues(initialSettingsState);
      setScenarioWithSettings(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenarioToEdit]);

  useEffect(() => {
    setUpdateSheetUrl(googleSheet ? googleSheet : '');
  }, [googleSheet]);

  const handleLocaleChange = target => {
    setValues(state => ({
      ...state,
      [target.name]: target.value,
      charOne: '',
      charTwo: '',
      charVoiceNameTwo: '',
      charVoiceNameOne: '',
    }));
  };

  const settingsValidated = values => {
    let tempSettingsError = {};

    if (!settings && values.scenarioName !== '') {
      let scenId = generateScenarioId(values.scenarioName);
      let scenarioExists = state.scenarios.filter(scenario => scenario.id === scenId);
      if (scenarioExists.length !== 0) {
        tempSettingsError.alreadyExists =
          '*Scenario with name ' + values.scenarioName + ' already exists';
      }
    }
    if (values.scenarioName.trim() === '') {
      tempSettingsError.scenarioName = '*Scenario name is required';
    }

    if (values.scenarioText.trim() === '') {
      tempSettingsError.scenarioText = '*Scenario description is required';
    }

    if (values.scenarioCoverImage === '') {
      tempSettingsError.scenarioCoverImage = '*Scenario cover image is required';
    }

    if (values.charNameOne === '') {
      tempSettingsError.charName = '*Bot name is required';
    }

    if (values.charOne === '') {
      tempSettingsError.charOne = '*Gender is required';
    }

    if (values.charTwo === '') {
      tempSettingsError.charTwo = '*Gender is required';
    }

    if (aiEnabled && values.locale === '') {
      tempSettingsError.locale = '*Language is required';
    }

    if (values.environment === '') {
      tempSettingsError.environment = '*Envirionment is required';
    }

    if (aiEnabled && values.charVoiceNameOne === '') {
      tempSettingsError.charVoiceNameOne = '*Voice is required';
    }

    if (aiEnabled && values.charVoiceNameTwo === '') {
      tempSettingsError.charVoiceNameTwo = '*Voice is required';
    }

    return tempSettingsError;
  };

  const save = () => {
    let settingErrors = settingsValidated(values);
    if (Object.keys(settingErrors).length === 0) {
      let tempSettings = {
        scenarioName: values.scenarioName,
        scenarioText: values.scenarioText,
        environment: values.environment,
        videoUrl: values.videoUrl,
        videoTranscript: values.videoTranscript,
        locale: aiEnabled ? values.locale : null,
        coverImage: values.scenarioCoverImage,
        characters: {
          character_1: {
            role: 'bot',
            character: values.charOne,
            displayName: values.charNameOne,
            spawnPosition: values.charPosOne,
            voiceName: aiEnabled ? values.charVoiceNameOne : null,
            gender: aiEnabled ? (values.charOne === 'TeacherM' ? 'Male' : 'Female') : null,
          },
          character_2: {
            role: 'bot',
            character: values.charTwo,
            displayName: values.charNameTwo,
            spawnPosition: values.charPosTwo,
            voiceName: aiEnabled ? values.charVoiceNameTwo : null,
            gender: aiEnabled ? (values.charOne === 'TeacherM' ? 'Male' : 'Female') : null,
          },
          character_3: {
            role: 'player',
            character: values.playerChar,
            spawnPosition: values.playerPos,
          },
        },
      };
      if (updateSheetUrl !== '' && settings && settings.googleSheet !== updateSheetUrl) {
        setGoogleSheet(updateSheetUrl);
      }
      saveSettings(tempSettings);
      setSettErr(null);
    } else {
      setSettErr(settingErrors);
    }
  };

  return (
    <>
      <SettingsContainer disabled={importedScenario || noEdit}>
        <SettingsWrapper padding={'30px'}>
          <FrLabel padding={'0 10px'}>Title</FrLabel>
          <FrInput
            maxLength={45}
            background={'var(--grey-5)'}
            disabled={importedScenario || noEdit}
            placeholder="Add a scenario name"
            name="scenarioName"
            value={values.scenarioName}
            onChange={e => handleInputs(e.target)}
            error={(settErr && settErr.alreadyExists) || (settErr && settErr.scenarioName)}
          />
          {settErr && settErr.alreadyExists && <FrError>{settErr.alreadyExists}</FrError>}
          {settErr && settErr.scenarioName && <FrError>{settErr.scenarioName}</FrError>}

          {scenarioToEdit && authUser && authUser.claims && authUser.claims.superAdmin && (
            <h5 style={{ color: 'var(--grey-1)', margin: '5px 0 0' }}>{'Id: ' + scenarioToEdit}</h5>
          )}

          <FrLabel margin={'10px 0 0'} padding={'0 10px'}>
            Description
          </FrLabel>

          <FrTextarea
            maxLength={250}
            minRows={3}
            name="scenarioText"
            value={values.scenarioText}
            onChange={e => handleInputs(e.target)}
            placeholder="Describe to the players what the scenario is about."
            disabled={importedScenario || noEdit}
            error={settErr && settErr.scenarioText}
            background={'var(--grey-5)'}
          />

          {settErr && settErr.scenarioText && <FrError>{settErr.scenarioText}</FrError>}
          {aiEnabled && (
            <>
              <FrLabel padding={'0 10px'} margin={'10px 0 5px'}>
                Language
                {values.locale === 'sv-SE' && (
                  <span> (Swedish, female voice only) </span>
                )}
              </FrLabel>
              <div style={{ width: '100%' }}>
                <SelectorContainer margin={'0 0 10px 0'} fullWidth={'50%'} selectWidth={'100%'} background={'var(--grey-5)'}>
                  <select
                    disabled={importedScenario || noEdit}
                    value={values.locale}
                    onChange={e => handleLocaleChange(e.target)}
                    name="locale"
                  >
                    <option value={''}>Select language</option>
                    {languages.map((entity, index) => (
                      <option key={index} value={entity}>
                        {entity === 'sv-SE' ? 'Swedish' : 'English'}
                      </option>
                    ))}
                  </select>
                  {settErr && settErr.locale && <FrError>{settErr.locale}</FrError>}
                </SelectorContainer>
              </div>
            </>
          )}

          <CharPosContainer>
            <FrLabel padding={'0 10px'} labelFor={'player'}>
              Player settings
            </FrLabel>

            <Actor
              id="player"
              isDisabled={importedScenario || noEdit}
              role={'player'}
              characterNumber={'Two'}
              name={values.charNameTwo}
              position={values.charPosTwo}
              avatar={values.charTwo}
              voiceName={values.charVoiceNameTwo}
              handleInputs={handleInputs}
              locale={values.locale}
              error={settErr}
            />

            <FrLabel padding={'10px 10px 0'} labelFor={'bot'}>
              Bot settings
            </FrLabel>

            <Actor
              id="bot"
              isDisabled={importedScenario || noEdit}
              role={'bot'}
              characterNumber={'One'}
              error={settErr}
              name={values.charNameOne}
              position={values.charPosOne}
              avatar={values.charOne}
              voiceName={values.charVoiceNameOne}
              handleInputs={handleInputs}
              locale={values.locale}
            />
          </CharPosContainer>

          {scenarioWithSettings && (
            <>
              <FrLabel padding={'10px 0 0 10px'}>Information</FrLabel>
              <ScenarioInfoContainer>
                {authUser && authUser.claims && authUser.claims.superAdmin && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}>Client:</span>{' '}
                    {scenarioToEdit.split('-')[0]},
                  </p>
                )}
                <p>
                  <span style={{ fontWeight: 'bold' }}>Status:</span>{' '}
                  {scenarioWithSettings.visible === true ? 'Published,' : 'Unpublished,'}
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Priority:</span>{' '}
                  {scenarioWithSettings.mandatory ? scenarioWithSettings.mandatory : 'None'}
                </p>
              </ScenarioInfoContainer>
            </>
          )}

          {!importedScenario && !noEdit && scenarioToEdit && (
            <SaveButton
              padding={'9px 20px'}
              disabled={validateSettings() || isSaved()}
              onClick={() => save()}
            >
              {isSaved() ? 'Saved' : 'Update Settings'}
            </SaveButton>
          )}
        </SettingsWrapper>
        <SettingsWrapper padding={'30px'}>
          <ImagePicker
            handleInputs={handleInputs}
            scenarioCoverImage={values.scenarioCoverImage}
            error={settErr && settErr.scenarioCoverImage}
            disabled={importedScenario || noEdit}
          />
          <FrLabel padding={'0 10px'} margin={'0 0 5px'}>
            Environment
          </FrLabel>
          <div style={{ width: '100%' }}>
            <SelectorContainer margin={'0 0 10px 0'} fullWidth={'50%'} selectWidth={'100%'} background={'var(--grey-5)'}>
              <select
                disabled={importedScenario || noEdit}
                value={values.environment}
                onChange={e => handleInputs(e.target)}
                name="environment"
              >
                <option value={''}>Select environment</option>
                {environments.map((entity, index) => (
                  <option key={index} value={entity}>
                    {entity}
                  </option>
                ))}
              </select>
              {settErr && settErr.environment && <FrError>{settErr.environment}</FrError>}
            </SelectorContainer>
          </div>
          <ImagePosContainer
            width={'100%'}
            minWidth={'300px'}
            noImage={values.environment === '' ? true : false}
            minHeight={'300px'}
          >
            {values.environment && values.environment === 'Office' && office && (
              <img src={office} alt="Office environment" />
            )}
            {values.environment && values.environment === 'Apartment' && apartment && (
              <img src={apartment} alt="Apartment environment" />
            )}
            {values.environment === '' && <h5>No environment chosen</h5>}
          </ImagePosContainer>
        </SettingsWrapper>
        {/* {settingsSaved === true && (
          <Saved>
            <Check /> Settings saved!
          </Saved>
        )} */}
      </SettingsContainer>

      {importedScenario && !noEdit && (
        <SaveButton onClick={() => addScenarioToDB()}>Create Scenario</SaveButton>
      )}

      {!scenarioToEdit && !importedScenario && !noEdit && (
        <SaveButton disabled={validateSettings()} onClick={() => save()}>
          Create Scenario
        </SaveButton>
      )}
    </>
  );
};

const Actor = ({
  isDisabled,
  error,
  role,
  characterNumber,
  handleInputs,
  name,
  // position,
  avatar,
  locale,
  voiceName,
  aiEnabled,
}) => {
  const characters = ['EmployeeF', 'TeacherM'];
  // const characterPositions = ['A', 'B', 'C', 'D', 'E', 'Z', 'F'];
  const voices = {
    'sv-SE': {
      EmployeeF: [{ voiceName: 'Wavenet-A', name: 'Anna' }],
    },
    'en-US': {
      TeacherM: [
        { voiceName: 'Wavenet-A', name: 'Alex' },
        { voiceName: 'Wavenet-B', name: 'Benjamin' },
        { voiceName: 'Wavenet-D', name: 'Dave' },
        { voiceName: 'Wavenet-I', name: 'Ian' },
        { voiceName: 'Wavenet-J', name: 'John' },
      ],
      EmployeeF: [
        { voiceName: 'Wavenet-C', name: 'Chloe' },
        { voiceName: 'Wavenet-E', name: 'Eva' },
        { voiceName: 'Wavenet-F', name: 'Fiona' },
        { voiceName: 'Wavenet-G', name: 'Gabriella' },
        { voiceName: 'Wavenet-H', name: 'Hannah' },
      ],
    },
  };

  return (
    <CharWrapper>
      {role !== 'player' && (
        <>
          <RowWrapper>
            <FrInput
              disabled={isDisabled}
              background={'var(--grey-5)'}
              placeholder="Add a bot display name"
              value={name}
              name={'charName' + characterNumber}
              onChange={e => handleInputs(e.target)}
              error={error && error.charName}
            />
          </RowWrapper>
          {error && error.charName && <FrError>{error.charName}</FrError>}
        </>
      )}
      <RowWrapper padding={'5px 0 0'}>
        <SelectorContainer fullWidth={'100%'} selectWidth={'50%'} background={'var(--grey-5)'}>
          <select
            disabled={isDisabled}
            value={avatar}
            onChange={e => handleInputs(e.target)}
            name={'char' + characterNumber}
          >
            <option value={''}>Choose avatar</option>
            {characters.map((entity, index) => (
              <option
                disabled={locale === 'sv-SE' && entity === 'TeacherM'}
                key={index}
                value={entity}
              >
                {entity === 'EmployeeF' ? 'Female' : 'Male'}
              </option>
            ))}
          </select>
          {error && characterNumber === 'One' && error.charOne && (
            <FrError>{error.charOne}</FrError>
          )}
          {error && characterNumber === 'Two' && error.charTwo && (
            <FrError>{error.charTwo}</FrError>
          )}
        </SelectorContainer>
        {/* <SelectorContainer fullWidth={'100%'} selectWidth={'100%'} background={'var(--grey-5)'}>
          <select
            disabled={true}
            value={position}
            onChange={e => handleInputs(e.target)}
            name={'charPos' + characterNumber}
          >
            <option value={''}>Choose position</option>
            {characterPositions.map((entity, index) => (
              <option key={index} value={entity}>
                {entity}
              </option>
            ))}
          </select>
        </SelectorContainer> */}
      </RowWrapper>
      {aiEnabled && locale && locale !== '' && avatar && avatar !== '' && (
        <RowWrapper padding={'5px 0 0'}>
          <SelectorContainer fullWidth={'50%'} selectWidth={'100%'} background={'var(--grey-5)'}>
            <select
              value={voiceName}
              onChange={e => handleInputs(e.target)}
              name={'charVoiceName' + characterNumber}
            >
              <option value={''}>Select voice</option>
              {voices &&
                voices[locale] &&
                voices[locale][avatar] &&
                voices[locale][avatar].map((entity, index) => (
                  <option key={index} value={entity.voiceName}>
                    {entity.name}
                  </option>
                ))}
            </select>
            {error && characterNumber === 'One' && error.charVoiceNameOne && (
              <FrError>{error.charVoiceNameOne}</FrError>
            )}
            {error && characterNumber === 'Two' && error.charVoiceNameTwo && (
              <FrError>{error.charVoiceNameTwo}</FrError>
            )}
          </SelectorContainer>

          {voiceName && voiceName !== '' && locale && (
            <AudioPlayer
              url={`https://cloud.google.com/text-to-speech/docs/audio/${locale}-${voiceName}.wav`}
            />
          )}
        </RowWrapper>
      )}
    </CharWrapper>
  );
};

const ImagePickerComponent = ({ firebase, handleInputs, scenarioCoverImage, error, disabled }) => {
  const [imageFiles, setImageFiles] = useState(null);
  const [imageFilesError, setImageFilesError] = useState(null);

  useEffect(() => {
    if (!imageFiles) {
      firebase
        .scenarioCoverImages()
        .list()
        .then(imageRef => {
          if (imageRef) {
            const imageFiles = imageRef.items
              .filter(r => r.location && r.location.path_)
              .map(ref => {
                let fileName = ref.location.path_.split('/')[2];
                let name = fileName
                  .split('_')
                  .join(' ')
                  .slice(0, fileName.lastIndexOf('.'));
                return {
                  name: name.charAt(0).toUpperCase() + name.slice(1),
                  fileName: fileName,
                };
              });
            setImageFiles(imageFiles);
          }
        })
        .catch(err => {
          setImageFilesError('Scenario image files could not be loaded');
          console.error(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ width: '100%', marginBottom: '10px' }}>
      <FrLabel padding={'0 10px'} margin={'0 0 5px'}>
        Cover image
      </FrLabel>

      <SelectorContainer margin={'0 0 10px 0'} fullWidth={'50%'} selectWidth={'100%'} background={'var(--grey-5)'}>
        <select
          value={scenarioCoverImage}
          onChange={e => handleInputs(e.target)}
          name="scenarioCoverImage"
          disabled={disabled}
        >
          <option value={''}>Selected image</option>
          {imageFiles &&
            imageFiles.map((entity, index) => (
              <option key={index} value={entity.fileName}>
                {entity.name}
              </option>
            ))}
        </select>
        {error && <FrError>{error}</FrError>}
      </SelectorContainer>
      <ImagePosContainer
        width={'100px'}
        minHeight={'100px'}
        background={'white'}
        padding={'10px'}
        noImage={scenarioCoverImage !== '' ? false : true}
      >
        {scenarioCoverImage !== '' && (
          <img
            style={{ borderRadius: '0' }}
            src={coverImageURL(scenarioCoverImage)}
            alt="Scenario cover"
          />
        )}
        {scenarioCoverImage === '' && <h5>No image</h5>}
      </ImagePosContainer>
      {imageFilesError && <FrError>{imageFilesError}</FrError>}
    </div>
  );
};

const ImagePicker = withFirebase(ImagePickerComponent);

export default ScenarioSettings;
