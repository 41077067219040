import styled from 'styled-components';
import { smoothIn } from '../../styles/keyframes';

export const SuperAdminBar = styled.div`
  width: 100%;
  position: sticky;
  background: white;
  z-index: 5;
  top: 0;
  transition: 0.4s;
  padding: 10px 32px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07);
  display: flex;
  align-items: center;
  align-items: space-between;
  justify-content: space-between;

  div {
    animation: ${smoothIn} 0.6s;
    display: flex;
    align-items: center;
    align-contet: flex-start;
    justify-content: flex-start;

    h5 {
      color: var(--grey);
      font-weight: 600;
    }

    select {
      border: none;
      font-weight: bold;
      border-radius: 20px;
      margin-left: 5px;
      font-size: 13px;
      background: var(--grey-5);
      color: var(--grey-1);
      padding: 8px 10px;
      outline: none;
      cursor: pointer;
      min-width: 320px;
    }
    @media (max-width: 768px) {
      width: 100%;
      padding: 10px;
      align-items: center;
      align-contet: center;
      justify-content: center;
      flex-direction: column;

      button {
        margin: 10px 0 0;
      }
    }
  }

  @media (max-width: 768px) {
    align-items: center;
    align-contet: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export const UserFeedback = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-contet: center;
  width: 200px;
  background: white;
  z-index: 100;
  border-radius: 10px;
  bottom: 20px;
  right: 20px;
  min-height: 50px;
  animation: ${smoothIn} 0.6s;
  border: ${props => (props.border ? props.border : 'none')};
`;

// export const FilterWrapper = styled.div`
// 	width: 100%;
// 	height: 40px;
// 	box-shadow: 0 1px 2px 0 rgba(32, 33, 36, .1);
// 	border-bottom: 2px solid;
// 	border-color: var(--grey-4);
// 	background-color: var(--background-color-white);
// 	display: flex;
// 	align-content: flex-start;
// 	align-items: center;
// 	justify-content: flex-start;
// 	padding: .5em 64px;

//   p {
//     font-weight: 500;
//   }
// `;

// export const Filter = styled.div`
// 	position: relative;
// 	height: 100%;

// 	select {
// 		border-radius: 0;
// 		border: none;
// 		-moz-appearance: none;
// 		-webkit-apperance: none;
// 		appearance: none;
// 		cursor: pointer;
// 		padding: .2em 1em;
// 		outline: none;
// 		font-size: 1em;
// 		color: var(--grey);
// 		font-weight: 500;
// 		background-color: var(--background-color-white);

// 		:disabled {
// 			pointer-events: none;
// 		}
// 	}
// 	::after {
// 			position: absolute;
// 			content: "";
// 			top: 12px;
// 			right: 2px;
// 			width: 0;
// 			height: 0;
// 			border: 6px solid transparent;
// 			border-color: ${props => props.disabled === true ? '#787878 transparent transparent transparent' : 'var(--grey) transparent transparent transparent'};
// 			pointer-events: none;
// 		}

// 	${props => props.disabled && css`
// 		::after {
// 				display: none;
// 			}
// 	` }
// `;
