import styled from 'styled-components';

export const LogOutStyle = styled.button`
  cursor: pointer;
  text-align: center;
  outline: none;
  border: none;
  background: transparent;
  font-weight: bold;
  margin: 20px 0 38px 0;

  svg {
    width: 24px;
    color: var(--grey-2);

  &:hover {
    color: var(--key-color-2);
    transition: 0.4s;
  }
  }

`;
