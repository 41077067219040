import React from 'react';
import styled from 'styled-components';
import { Lock } from 'styled-icons/boxicons-regular/Lock';

const Selector = ({
  arr,
  selectedEntity,
  setSelectedEntity,
  defaultValText,
  fullWidth,
  selectWidth,
  padding,
  disabled,
  margin,
  background,
}) => {
  return (
    <SelectorContainer
      selectWidth={selectWidth}
      fullWidth={fullWidth ? fullWidth : null}
      padding={padding ? padding : null}
      margin={margin}
      background={background}
    >
      <select
        disabled={disabled}
        value={selectedEntity}
        onChange={e => setSelectedEntity(e.target.value)}
      >
        <option value={''}>{defaultValText}</option>
        {arr &&
          arr.map((entity, index) => (
            <option key={index} value={entity}>
              {entity}
            </option>
          ))}
      </select>
      {disabled && <Lock />}
    </SelectorContainer>
  );
};

export const SelectorContainer = styled.div`
  width: ${props => (props.fullWidth ? props.fullWidth : '100%')};
  display: flex;
  align-content: center;
  align-items: flex-start;
  margin: ${props => (props.margin ? props.margin : '0 2px')};
  position: relative;
  flex-direction: column;

  svg {
    position: absolute;
    right: 5px;
    width: 20px;
    color: var(--grey-2);
  }

  select {
    width: ${props => (props.selectWidth ? props.selectWidth : 'initial')};
    border: 2px solid transparent;
    cursor: pointer;
    padding: ${props => (props.padding ? props.padding : '5px 1em')};
    background: ${props => (props.background ? props.background : 'white')};
    outline: none;
    border-radius: 20px;
    &:disabled {
      pointer-events: none;
      opacity: 0.4;
      color: var(--grey);
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';
    }

    &:hover {
      transition: 0.4s;
      box-shadow: 0px 0px 0px 2px rgba(127, 101, 255, 0.25);
    }
    &:focus {
      transition: 0.4s;
      border: 2px solid var(--key-color-2);
      box-shadow: 0px 0px 0px 2px rgba(127, 101, 255, 0.2);
    }
  }

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export default Selector;
