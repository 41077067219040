import React, { useState } from 'react';
import { ChevRight } from '../../styles/icons';
import styled from 'styled-components';

const Container = styled.div`
  padding: ${props => (props.padding ? props.padding : '20px')};
`;

const CollapsibleDiv = styled.div`
  overflow: hidden;
  ${'' /* overflow: ${props => (props.show ? 'visible' : 'hidden')}; */}
  width: 100%;
`;

const ExpandContract = styled.div`
  margin-top: ${props => (!props.show ? '-800px' : '0')};
  transition: all ease-in-out 0.4s;
  @media (max-width: 940px) {
    margin-top: ${props => (!props.show ? '-1500px' : '0')};
  }
`;
//${props => (props.margin !== null ? props.margin : '0 0 10px')}
const CollapsibleLabel = styled.button`
  z-index: 10;
  margin: 0;
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
  width: 100%;

  h2 {
    width: 100%;
    margin: none;
    color: var(--grey);
    text-align: left;
  }

  svg {
    transform: ${props => (props.show ? 'rotate(90deg)' : 'rotate(0deg)')};
    transition: 0.4s;
    color: var(--grey);
    width: 40px;
  }

  :disabled {
    pointer-events: none;
    svg {
      display: none;
    }
  }
`;

const CollapsibleContainer = props => {
  const [show, setShow] = useState(props.isOpen ? true : false);

  return (
    <Container padding={props.padding}>
      <CollapsibleLabel show={show} disabled={props.disabled} onClick={() => setShow(!show)}>
        <h2>
          {props.label}{' '}
          {props.label2 && (
            <>
              {' '}
              <br /> <span>{props.label2}</span>{' '}
            </>
          )}{' '}
        </h2>{' '}
        <ChevRight />
      </CollapsibleLabel>

      <CollapsibleDiv show={show} margin={props.margin}>
        <ExpandContract show={show}>{props.children}</ExpandContract>
      </CollapsibleDiv>
    </Container>
  );
};

export default CollapsibleContainer;
