import styled from 'styled-components';
import { smoothIn } from '../../styles/keyframes';

export const AdminContainer = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    opacity: 1;
  }

  h1 {
    text-align: left;
    color: var(--grey);
    font-weight: bold;
  }
`;

export const AdminContentWrapper = styled.div`
  padding: 0 0 20px;
  width: 80%;
  max-width: 1450px;
  min-height: 100vh;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 20px 20px;
  }
`;

export const AdminTableWrapper = styled.div`
  padding: 20px 0;
  width: 100%;
  min-height: 200px;
  table {
    padding: 5px;
    width: 100%;
  }
`;

export const TableScrollWrapper = styled.div`
  width: 100%;
  @media (max-width: 1300px) {
    overflow-x: scroll;
  }
`;

export const TableHeader = styled.h2`
  width: 100%;
  padding: 0 0 10px;
  font-weight: bold;
  color: var(--grey);
  display: flex;
  align-items: center;
  margin: ${props => (props.margin ? props.margin : 0)};

  button {
    border: none;
    background: transparent;
    outline: none;
    svg {
      width: 35px;
      color: var(--key-color-2);
      margin: 0 5px;
    }
  }

  span {
    padding-left: 10px;
    font-weight: 500;
  }
`;

export const AdminNavigation = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-items: center;
  align-contet: flex-start;
  justify-content: flex-start;

  h2 {
    padding: 5px 10px;
    font-weight: bold;
    color: var(--grey);
  }
`;

export const NewClientWrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  input {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07);
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 24%;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    color: var(--grey-1);
    margin: 5px;
    outline: none;

    ::placeholder {
      color: var(--grey-2);
      font-weight: 500;
    }
  }

  button {
    margin: 5px;
    padding: 10px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 24%;
    border-radius: 20px;
    border: none;
    color: white;
    background: var(--key-color-2);
    opacity: 1;
    font-weight: bold;

    &:disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  @media (max-width: 1400px) {
    button {
      flex-basis: 48.5%;
    }
    input {
      flex-basis: 48.5%;
    }
  }

  @media (max-width: 1000px) {
    button {
      flex-basis: 98%;
    }
    input {
      flex-basis: 98%;
    }
  }
`;

export const VisibilityButton = styled.button`
  padding: 5px 15px;
  background: ${props => (!props.isHidden ? 'transparent' : 'var(--grey-2)')};
  border: 2px solid var(--grey-2);
  color: ${props => (!props.isHidden ? 'var(--grey-2)' : 'white')};
  font-weight: bold;
  border-radius: 20px;
  width: 114px;
  font-size: 12px;
`;

export const MandatorySelect = styled.div`
  input {
    outline: none;
    font-size: 14px;
    border: 2px soild var(--key-color-2);
    font-weight: bold;
    padding: 5px;
  }
`;

export const SaveMandatory = styled.button`
  background: transparent;
  border: none;
  outline: none;
  font-weight: bold;
  color: var(--key-color-2);
  display: inline;
  font-size: 12px;
  margin-left: 5px;
`;

export const AddScenarioContainer = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  align-content: flex-end;
  justify-content: flex-end;

  @media (max-width: 1000px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    align-contet: center;
    justify-content: center;
    padding: 10px;
    button {
      width: 100%;
      margin: 5px 0;
    }
  }
`;

export const AddScenarioWrapper = styled.div`
  display: flex;
  align-items: center;
  align-contet: flex-start;
  justify-content: flex-start;
  width: 100%;

  select {
    border: none;
    font-weight: bold;
    border-radius: 20px;
    padding: 10px;
    margin-right: 10px;
    color: var(--grey-1);
    outline: none;
    cursor: pointer;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07);
    max-width: 400px;
  }

  @media (max-width: 768px) {
    select {
      width: 70%;
    }

    button {
      width: 30%;
    }
  }
`;

export const PrimaryBtn = styled.button`
  text-align: center;
  padding: 10px 20px;
  min-width: ${props => (props.minWidth ? props.minWidth : '220px')};
  color: white;
  background: var(--key-color-2);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  border-radius: 20px;
  border: none;
  outline: none;

  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  svg {
    margin-left: 10px;
    width: 14px;
  }
`;

export const EditClientInput = styled.input`
  padding: 5px 10px;
  outline: none;
  border-radius: 20px;
  border: 2px solid var(--key-color-2);
  font-size: 14px;
  font-weight: 600;
`;

export const PrimeButton = styled.button`
  background: transparent;
  color: ${props => (props.cancel ? 'var(--grey-2)' : 'var(--key-color-2)')};
  font-size: 14px;
  border: 2px solid;
  border-color: ${props => (props.cancel ? 'var(--grey-2)' : 'var(--key-color-2)')};
  padding: 5px 15px;
  margin: 0 2px;
  font-weight: bold;
  border-radius: 20px;
`;

export const InnerDataTable = styled.div`
  background: white;
  font-size: 14px;

  th {
    padding: 5px 10px;
    background-color: transparent;
    color: var(--grey-2);
    font-weight: bold;
    cursor: pointer;
    text-align: left;
    font-size: 14px;
  }

  tbody {
    tr {
      box-shadow: none;
      border-radius: 20px;
    }

    thead {
      tr {
        box-shadow: none;
      }
    }
  }

  td {
    padding: 1em;
    background-color: var(--grey-5);
    color: var(--grey);
    font-weight: 500;
    position: relative;
  }

  td:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  td:last-child {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
  }
`;

export const ClickableHeader = styled.h5`
  color: var(--grey);
  transition: 0.4s;
  cursor: pointer;
  text-decoration: underline;

  span {
    font-size: 12px;
    color: var(--grey-2);
    text-decoration: none;
    display: inline-block;
  }

  &:hover {
    transition: 0.4s;
    color: var(--key-color-2);

    span {
      color: var(--grey-2);
    }
  }
`;

export const LogoDiv = styled.div`
  width: 60px;
  height: 40px;
  margin: 0 5px;
  background: ${props => (props.url ? `url(${props.url})` : 'initial')};
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  font-weight: bold;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: var(--grey-2);
`;

export const StatsBox = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 24%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 15px 30px 15px 30px;
  background: white;
  border-radius: 20px;
  margin: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07);

  @media (max-width: 1400px) {
    flex-basis: 48.5%;
  }

  @media (max-width: 1000px) {
    flex-basis: 98%;
  }

  svg {
    background: ${props => (props.color ? props.color : '#FFCA20')};
    width: 45px;
    min-width: 40px;
    border-radius: 50%;
    padding: 10px;
    color: white;
    margin-right: 10px;
  }

  div {
    display: flex;
    align-items: center;
    align-content: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    min-width: 110px;

    h2 {
      font-size: 32px;
      text-align: left;
      line-height: 1;
      margin-left: 5px;
      background: ${props => (props.color ? props.color : 'var(--grey-1)')};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      white-space: nowrap;
      animation: ${smoothIn} 0.6s;
    }

    h4 {
      font-size: 12px;
      color: var(--grey-2);
      margin-left: 5px;
      line-height: 0.5;
      white-space: nowrap;
    }
  }
`;
