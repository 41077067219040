import styled from 'styled-components';

export const ExchangeWrapper = styled.div`
  width: 100%;
  padding: 20px;
  margin: 10px 0;
  border-radius: 20px;
  background: white;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.07);
`;

export const SessionTableContainer = styled.div`
  width: 100%;
  margin-bottom: 8px;
  overflow-x: scroll;

  table {
    width: 100%;
    min-width: 600px;
    td {
      text-align: center;
    }
  }
`;

// ---------- SESSION SUMMARY STYLES -------------

export const SummaryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: flex-start;
  height: max-content;
  padding-bottom: 10px;
  margin-bottom: 20px;
  width: 100%;

  ul:first-child {
    margin: 0 8px 0 0;
  }
  ul:last-child {
    margin: 0 0 0 8px;
  }

  @media (max-width: 970px) {
    overflow-y: scroll;
  }
`;

export const SummaryList = styled.ul`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  align-content: flex-start;
  ${'' /* border-bottom: ${props => (props.color ? `5px solid ${props.color}` : 'none')}; */}
  padding-bottom: 8px;
  height: 100%;

  li:first-child {
    margin: 0;
  }
  li:last-child {
    margin: 0 0 0 4px;
  }
`;

export const SummaryColorbar = styled.div`
  width: calc(100% - 4px);
  height: 5px;
  position: absolute;
  left: 4px;
  bottom: -5px;
  border-radius: 20px;
  background: ${props => (props.color ? props.color : 'var(--grey-4)')};
`;

export const SummaryItemWrapper = styled.li`
  height: 100%;
  width: 100%;
  max-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  padding: 16px;
  background: white;
  border-radius: 20px;
  margin: 0 4px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07);

  @media (max-width: 970px) {
    min-width: 150px;
  }

  h5 {
    font-size: 14px;
    color: 'var(--grey)';
    text-align: left;
    width: 100%;
    margin-bottom: 16px;
  }

  div {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    flex-direction: column;
    min-height: 130px;
    h5 {
      color: ${props => (props.color ? props.color : 'var(--grey)')};
      margin: 16px 0 8px 0;
      font-size: 14px;
      color: 'var(--grey)';
      text-align: left;
      width: 100%;
    }

    p {
      font-size: 10px;
      font-weight: 500;
    }
  }
  svg {
    width: 60px;
  }
`;