import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { smoothIn } from '../../styles/keyframes';

const PageTitleWrapper = styled.div`
  width: 100%;
  padding: ${props => (props.padding ? props.padding : '45px 0 20px 0')};
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  h1 {
    text-align: left;
    color: var(--grey);
    line-height: 39px;
    padding: 0;
    margin: 0;
  }

  h4 {
    text-align: left;
    color: var(--grey-2);
    line-height: 20px;
    span {
      color: var(--key-color-2);
    }
  }
`;

export const PageTitle = ({ withDate, padding, title }) => {
  const currentDate = () => {
    let date = new Date().toDateString().split(' ');
    return (
      <h4>
        <span>{date[0]}</span>
        {` ${date[2]} ${date[1]} ${date[3]}`}
      </h4>
    );
  };

  return (
    <PageTitleWrapper padding={padding}>
      <h1>{title}</h1>
      {withDate && currentDate()}
    </PageTitleWrapper>
  );
};

const AdminNavContainer = styled.ul`
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 10px;
  position: relative;
  animation: ${smoothIn} .4s;

  li:first-child {
    padding: 5px 0;
  }
`;

const AdminNavLi = styled.li`
  margin: 0;
  padding: 5px 20px;
  a {
    color: var(--grey);
    border-bottom: ${props => props.activeLink ? '3px solid var(--key-color-2)' : '3px solid transparent'};
    font-weight: bold;
    font-size: 20px;
    transition: 0.4s;
    white-space: nowrap;
    &:hover {
      color: var(--grey);
      border-bottom: 3px solid var(--key-color-2);
      transition: 0.4s;
    }
  }
`;

const AdminNav = (props) => {
  const [activeLink, setActiveLink] = useState('/admin');

  useEffect(() => {
    if(props.location.pathname) {
      setActiveLink(props.location.pathname);
    }
  },[props.location.pathname])

  return (
    <AdminNavContainer>
      <AdminNavLi activeLink={activeLink === '/admin'}>
        <Link to={'/admin'}>Overview</Link>
      </AdminNavLi>
      {/* <AdminNavLi activeLink={activeLink.includes('/admin/scenarios')}>
        <Link to={'/admin/scenarios'}>Scenarios</Link>
      </AdminNavLi> */}
      {/* <AdminNavLi activeLink={activeLink.includes('/admin/sessions')}> 
        <Link to={'/admin/sessions'}>Sessions</Link>
      </AdminNavLi> */}
      {/* <AdminNavLi activeLink={activeLink.includes('/admin/users')}>
        <Link to={'/admin/users'}>Users</Link>
      </AdminNavLi> */}
      <AdminNavLi activeLink={activeLink.includes('/admin/scenario-editor')}>
        <Link to={'/admin/scenario-editor'}>Scenario Editor</Link>
      </AdminNavLi>
    </AdminNavContainer>
  );
};

export const AdminNavigation = withRouter(AdminNav);
