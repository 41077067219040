import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';

var config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_REALTIME_DB,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

class Firebase {
  constructor() {
    // app.initializeApp(config);
    let fireApp = app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.database();
    this.storage = app.storage();
    this.functions = fireApp.functions(process.env.REACT_APP_REGION);
    this.copyScenarioFiles = this.functions.httpsCallable('assets-copyScenarioFiles');
    this.generateSpeech = this.functions.httpsCallable('assets-generateSpeech');
    this.generateSpeechByPath = this.functions.httpsCallable('assets-generateSpeechByPath');
    this.clearDemoSessions = this.functions.httpsCallable('stats-clearDemoSessions');
    this.registerUser = this.functions.httpsCallable('auth-registerUser');
  }

  componentWillUnmount() {
    this.newUser.off();
  }

  //Cloud functions
  doClearDemoSessions = data => this.clearDemoSessions(data);

  doCopyScenarioFiles = inputObj => this.copyScenarioFiles(inputObj);

  doGenerateSpeech = data => this.generateSpeech(data);

  doGenerateSpeechByPath = data => this.generateSpeechByPath(data);

  doRegisterUser = data => this.registerUser(data);

  doSendEmailVerification = () => this.auth.currentUser.sendEmailVerification();

  // Set user persistence
  doSetPersistence = persistence => this.auth.setPersistence(persistence);

  //Login user
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  // doSendSignInLinkToEmail = (email, actionCodeSettings) =>
  //   this.auth.sendSignInLinkToEmail(email, actionCodeSettings);

  //Sign out user
  doSignOut = () => this.auth.signOut();

  //Reset user password
  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  //Update user password
  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** Auth API ***
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        const newAuthUser = authUser;
        const userId = authUser.email
          .split('.')
          .join('')
          .split('@')
          .join('');
        try {
          this.newUser(userId).on('value', snapshot => {
            const dbUser = snapshot.val();
            if (dbUser) {
              newAuthUser
                .getIdTokenResult()
                .then(idTokenResult => {
                  authUser = {
                    userId: userId,
                    claims: idTokenResult && idTokenResult.claims ? idTokenResult.claims : {},
                    ...dbUser,
                  };
                  next(authUser);
                })
                .catch(err => {
                  authUser = {
                    userId: userId,
                    claims: {},
                    ...dbUser,
                  };
                  next(authUser);
                  console.error(err);
                });
            } else {
              if (authUser) {
                fallback('Couldn’t find a matching account!');
              }
            }
          });
        } catch (error) {
          console.error(error);
        }
      } else {
        fallback();
      }
    });

  // *** NEW STORAGE STRUCTURE ***

  //Scenario

  scenarioCoverImages = () => this.storage.ref('thumbs/scenarioCoverImages');

  scenarioImage = (scenarioId, imageName) =>
    this.storage.ref(`assets/${scenarioId}/images/${imageName}`);

  scenarioVideo = (scenarioId, videoName) =>
    this.storage.ref(`assets/${scenarioId}/video/${videoName}`);

  scenarioVoiceLine = (scenarioId, voiceLine) =>
    this.storage.ref(`assets/${scenarioId}/audio/${voiceLine}`);

  scenarioVoiceLines = scenarioId => this.storage.ref(`assets/${scenarioId}/audio`);

  // Client

  clientLogo = (clientId, name) => this.storage.ref(`clients/${clientId}/${name}`);

  //Session

  combinedAudio = (clientId, userId, sessionId) =>
    this.storage.ref(`audiologs/${clientId}/${userId}/${sessionId}`);

  answerToVoiceLineAudio = (clientId, userId, sessionId, voiceLineId) =>
    this.storage.ref(`audiologs/${clientId}/${userId}/${sessionId}/${voiceLineId}`);

  // *** NEW DATABASE STRUCTURE ***

  //Sessions

  allSessions = () => this.db.ref('/sessions');

  clientSessions = clientId => this.db.ref(`/sessions/${clientId}`);

  userSessions = (clientId, userId) => this.db.ref(`/sessions/${clientId}/${userId}`);

  newSession = (clientId, userId, sessionId) =>
    this.db.ref(`/sessions/${clientId}/${userId}/${sessionId}`);

  sharedSessions = () => this.db.ref('/sharedSessions');

  sessionSummary = (clientId, userId, sessionId) =>
    this.db.ref(`/analytics/${clientId}/${userId}/${sessionId}/summary`);

  //Clients

  newClients = () => this.db.ref('/clients');

  newClient = id => this.db.ref(`/clients/${id}`);

  //Users

  newUsers = () => this.db.ref('/users');

  newUser = id => this.db.ref(`/users/${id}`);

  //Notes

  notes = (userId, scenarioId, voiceLineId) =>
    this.db.ref(`/users/${userId}/scenarios/${scenarioId}/exchanges/${voiceLineId}/notes`);

  note = (userId, scenarioId, voiceLineId, noteId) =>
    this.db.ref(
      `/users/${userId}/scenarios/${scenarioId}/exchanges/${voiceLineId}/notes/${noteId}`,
    );

  //Scenarios

  scenarioStats = id => this.db.ref(`/stats/scenarios/${id}`);

  mandatoryScenarios = id => this.db.ref(`/clients/${id}/settings/mandatory`);

  newScenarios = () => this.db.ref('/scenarios');

  newScenario = id => this.db.ref(`/scenarios/${id}`);

  scenarioModeExchanges = (id, mode) => this.db.ref(`/scenarios/${id}/modes/${mode}/exchanges`);

  scenarioModeExchange = (id, mode, exchangeId) =>
    this.db.ref(`/scenarios/${id}/modes/${mode}/exchanges/${exchangeId}`);

  //Suggestions

  newSuggestions = scenarioId => this.db.ref(`/scenarios/${scenarioId}/suggestions`);

  // Pin Code

  pinCode = userUid => this.db.ref(`/pinCodes/${userUid}`);
}

export default Firebase;
