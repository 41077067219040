import styled from 'styled-components';
import { smoothIn, ScaleIn} from '../../styles/keyframes';

export const PWForgetContainer = styled.div`
  width: 100%;
  padding: 40px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: ${smoothIn} 0.6s linear;

  img {
    width: 100px;
    margin: 20px 0;
  }

  @media (max-width: 768px) {
    animation: ${ScaleIn} 0.6s linear;
    animation-delay: .2s;
    animation-fill-mode: forwards;
    opacity: 0;
    padding: 0 0 90px 0;
    height: 100vh;
  }
`;

export const FormWrapper = styled.div`
  width: 40%;
  background: white;
  padding: 40px;
  border-radius: 10px;
  position: relative;
  box-shadow: 5px 5px 5px var(--grey-3);

  @media (max-width: 768px) {
    width: 100%;
    border-radius: 10px;
    box-shadow: none;
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
  svg {
    color: var(--grey-2);
    width: 25px;
    transition: .4s;

    &:hover {
      transition: .4s;
      transform: scale(1.1);
    }
  }
`;

export const TextWrapper = styled.div`
  margin-bottom: 20px;
  padding: 0 0 30px;
  border-bottom: 1px solid var(--grey-3);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;

  h1 {
    padding: 10px 0;
    color: var(--grey);
    font-size: 1.8em;
    font-weight: 700;
  }

  p {
    font-size: 14px;
    font-weight: 600;

    span {
      font-weight: bold:
    }
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 3px solid var(--key-color-2);
    margin-bottom: 10px;
    svg {
      color: var(--key-color-2);
      width: 50px;
    }
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 1.4em;
    }
  }
`;