import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../../components/Firebase';
import { ArrowLeft2 } from 'styled-icons/icomoon/ArrowLeft2';
import { compose } from 'recompose';
import { SelectorContainer } from '../../components/Selector';
import Loading from '../../components/Loading';
import {
  SignInContainer,
  SignInWrapper,
  SignInContent,
  BottomContainer,
  Line,
  ImageContainer,
  HeaderContainer,
  ErrorMessage,
  ConsentContainer,
  ButtonContainer,
  VerifyEmailContainer,
  BannerContainer,
  LogoIMG,
} from './style';
import Form from '../../components/Form';
import { MailSend } from 'styled-icons/boxicons-regular/MailSend';
import { FrInput, FrLabel, FrButton } from '../../components/DesignSystem/style';

const SignInPage = props => {
  const background = require('../../assets/login.png');
  const [signUp, setSignUp] = useState(false);

  useEffect(() => {
    if (props.firebase.auth.currentUser) {
      let hasDownloaded = localStorage.getItem('hasDownloaded');
      localStorage.clear();
      localStorage.setItem('hasDownloaded', hasDownloaded);
      props.firebase.doSignOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.firebase.auth.currentUser]);

  return (
    <SignInContainer>
      {signUp ? (
        <SignUpForm firebase={props.firebase} history={props.history} setSignUp={setSignUp} />
      ) : (
        <SignInForm firebase={props.firebase} history={props.history} setSignUp={setSignUp} />
      )}
      {background && <ImageContainer background={background}></ImageContainer>}
    </SignInContainer>
  );
};

const SignInForm = props => {
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = e => {
    e.preventDefault();
    props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        if (
          props.history &&
          props.history.location &&
          props.history.location.state &&
          props.history.location.state.url !== undefined
        ) {
          if (props.history.location.state.url.includes('signin')) {
            props.history.push(ROUTES.HOME);
          } else {
            props.history.push(props.history.location.state.url);
          }
        } else {
          props.firebase.auth.currentUser.getIdTokenResult().then(getIdTokenResult => {
            if (
              getIdTokenResult.claims &&
              (getIdTokenResult.claims.superAdmin || getIdTokenResult.claims.clientAdmin)
            ) {
              props.history.push(ROUTES.ADMIN);
            } else {
              props.history.push(ROUTES.HOME);
            }
          });
        }
      })
      .catch(err => {
        setError(err);
      });
  };

  const isInValidLogin = password === '' || email === '';

  return (
    <SignInWrapper>
      <img src={require('../../assets/fictive_logo_dark.svg')} alt="fictive reality logo" />
      <SignInContent>
        <HeaderContainer>
          <h1>Sign in to your account</h1>
        </HeaderContainer>
        <Form
          passwordLink={true}
          buttonText={'Sign in'}
          onSubmit={onSubmit}
          isInValid={isInValidLogin}
          inputs={[
            {
              type: 'email',
              name: 'email',
              label: 'Email',
              value: email,
              autoComplete: 'username',
              onChange: setEmail,
              dataCy: 'email',
            },
            {
              type: 'password',
              name: 'password',
              label: 'Password',
              value: password,
              autoComplete: 'current-password',
              onChange: setPassword,
              dataCy: 'password',
            },
          ]}
        />
            <BottomContainer>
            <Line></Line>
            <h3 style={{ margin: "20px 0 10px 0" }}>Don't have an account?</h3>
            <FrButton style={{ padding: "10px 20px"}} onClick={() => props.setSignUp(true)}>Sign up</FrButton>
          </BottomContainer>
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
        {props.history && props.history.location.state && props.history.location.state.error && (
          <ErrorMessage>{props.history.location.state.error}</ErrorMessage>
        )}
      </SignInContent>
    </SignInWrapper>
  );
};

const SignUpForm = props => {
  const [email, setEmail] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [avatar, setAvatar] = useState('');
  const [consent, setConsent] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordTwo, setPasswordTwo] = useState('');
  const [showVerificationPage, setShowVerificationPage] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  // Test email string
  // var re = /\S+@\S+\.\S+/

  const signUpUser = () => {
    setLoading(true);
    props.firebase
      .doRegisterUser({
        email: email,
        password: password,
        displayName: displayName,
        avatar: avatar,
        consent: consent,
      })
      .then(res => {
        if (res && Object.keys(res).length !== 0) {
          setShowVerificationPage(true);
        }
        setLoading(false);
      })
      .catch(error => {
        if (error.code === 'already-exists') {
          setError({
            code: error.code,
            message:
              'It appears like this user already exists, if you have troubles signing in try resetting your password',
          });
        } else {
          setError('Something went wrong, user registration failed: Please contact your admin to solve this issue');
        }
        setLoading(false);
      });
  };

  const isInValidSignUp =
    password === '' ||
    password.length < 6 ||
    email === '' ||
    displayName === '' ||
    password !== passwordTwo ||
    avatar === '' ||
    consent === false;

  return (
    <SignInWrapper>
      <LogoIMG src={require('../../assets/fictive_logo_dark.svg')} alt="fictive reality logo" />
      {loading && (
        <Loading loadingText={'Sign Up in progress...'} width={'80px'} containerHeight={'400px'} />
      )}

      {!loading && (
        <SignInContent>
          {showVerificationPage && (
            <VerifyEmailContainer>
              <MailSend />
              <h1 style={{ padding: '10px 0 0' }}>Verification email sent!</h1>
              <h3>Check your mailbox for verification email.</h3>
              <FrButton
                fontSize={'16px'}
                margin={'30px 5px 5px'}
                onClick={() => props.setSignUp(false)}
              >
                Complete
              </FrButton>
            </VerifyEmailContainer>
          )}
          {!showVerificationPage && (
            <>
              <HeaderContainer style={{ marginTop: '30px', paddingBottom: '20px' }}>
                <ArrowLeft2 style={{ zIndex: '1' }} onClick={() => props.setSignUp(false)} />
                <h1>Sign up</h1>
              </HeaderContainer>
              <BannerContainer>
                <h5>
                  Your organization will need to have a subscription to play any scenarios. Contact
                  your admin or us at Fictive Reality if you are unsure.
                </h5>
                <ButtonContainer>
                  <a
                    href="https://www.fictivereality.com/contact-us"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact us
                  </a>
                </ButtonContainer>
              </BannerContainer>
              <form>
                <FrLabel margin={'10px 0 0'}>Display name</FrLabel>
                <FrInput
                  placeholder="Example Name..."
                  type="text"
                  dataCy="displayName"
                  value={displayName}
                  onChange={e => setDisplayName(e.target.value)}
                  background={'var(--grey-5)'}
                ></FrInput>
                <FrLabel margin={'10px 0 0'}>Email</FrLabel>
                <FrInput
                  autoComplete="new-email"
                  placeholder="Example@domain.com..."
                  type="email"
                  dataCy="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  background={'var(--grey-5)'}
                ></FrInput>
                <FrLabel margin={'10px 0 0'}>
                  Password{' '}
                  {password !== '' && password.length < 6 && (
                    <span style={{ color: 'var(--status-red)' }}>
                      Needs to be more then 6 characters
                    </span>
                  )}
                </FrLabel>
                <FrInput
                  autoComplete="new-password"
                  type="password"
                  dataCy="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  background={'var(--grey-5)'}
                  error={password !== '' && password.length < 6}
                ></FrInput>
                <FrLabel margin={'10px 0 0'}>
                  Confirm password{' '}
                  {passwordTwo !== '' && passwordTwo !== password && (
                    <span style={{ color: 'var(--status-red)' }}>
                      Does not match first password
                    </span>
                  )}
                </FrLabel>
                <FrInput
                  type="password"
                  autoComplete="new-password"
                  dataCy="passwordTwo"
                  value={passwordTwo}
                  onChange={e => setPasswordTwo(e.target.value)}
                  background={'var(--grey-5)'}
                  error={passwordTwo !== '' && passwordTwo !== password}
                ></FrInput>
                <FrLabel margin={'10px 0 0'}>Preferred avatar</FrLabel>
                <SelectorContainer background={'var(--grey-5)'} margin={'5px 0 0'}>
                  <select onChange={e => setAvatar(e.target.value)}>
                    <option value="">Select Avatar</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </SelectorContainer>
              </form>

              <ConsentContainer>
                <input
                  type="checkbox"
                  checked={consent}
                  onChange={() => setConsent(!consent)}
                ></input>
                <p>
                  I consent to sharing my personal data and receiving email about this service. No
                  worries, we will not spam you. its only for functional purposes
                </p>
              </ConsentContainer>

              <ButtonContainer>
                <FrButton style={{ padding: "10px 20px"}} disabled={isInValidSignUp} onClick={() => signUpUser()}>
                  Sign up
                </FrButton>
              </ButtonContainer>

              {error && (
                <ErrorMessage style={{ marginTop: '10px' }}>
                  {error.message}{' '}
                  {error.code === 'already-exists' && (
                    <span
                      style={{
                        color: 'var(--key-color-2)',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => props.history.push(ROUTES.PASSWORD_FORGET)}
                    >
                      here
                    </span>
                  )}
                </ErrorMessage>
              )}
            </>
          )}
        </SignInContent>
      )}
    </SignInWrapper>
  );
};

export default compose(withRouter, withFirebase)(SignInPage);
