import styled, { css } from 'styled-components';
import { FadeIn, Spinning } from '../../styles/keyframes';

export const List = styled.ul`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  transition: 0.4s;
`;

//EXCHANGE STYLING
export const ExchangeContainer = styled.div`
  width: 100%;
  background: transparent;
  animation: ${FadeIn} 0.4s;
`;

export const ExchangeWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  flex-direction: ${props => (props.dialogueType === 'answer' ? 'column-reverse' : 'column')};
  min-height: 350px;
  transition: 0.6s;
`;

export const NewExchangeContainer = styled.div`
  width: 100%;
  padding: 24px 0;
  border-top: 1px solid var(--grey-2);
`;

export const NewExchangeWrapper = styled.div`
  width: 60%;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin: ${props => (props.margin ? props.margin : '0')};

  span {
    font-size: 12px;
    font-weight: 600;
    margin: 0 5px;
    color: var(--grey-1);
  }

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

//EXCHANGE TOPBAR
export const TopBar = styled.div`
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;

  input {
    margin: 0;
    min-width: 200px;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    background: var(--grey-5);
    display: block;
    text-transform: lowercase;
    ::first-letter: {
      text-transform: capitalize;
    }
  }

  h5 {
    font-size: 14px;
    font-weight: bold;
    color: var(--grey);
    white-space: nowrap;
  }
  p {
    white-space: nowrap;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: bold;
    color: var(--grey);

    svg {
      transition: 0.4s;
      width: 20px;
      color: var(--grey-3);
    }
    :hover {
      svg {
        transition: 0.4s;
        color: var(--key-color-2);
      }
    }
  }
  p:first-letter {
    text-transform: capitalize;
  }
`;

export const DescriptionWrapper = styled.div`
  padding: 10px 0 15px;

  p {
    color: var(--grey);
    display: inline-block;
  }
  p:first-letter {
    text-transform: capitalize;
  }
`;

export const LineDiv = styled.div`
  height: 1px;
  width: 100%;
  margin: 0 10px;
  background: var(--grey-3);
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  button:last-child {
    margin: 0;
  }

  button {
    ${'' /* margin: 0 5px 0 0; */}
    background: transparent;
    border: none;
    transition: 0.4s;
    outline: none;

    :disabled {
      pointer-events: none;
      svg {
        color: var(--grey-4);
      }
    }

    svg {
      width: 20px;
      color: var(--grey-2);
      transition: 0.4s;
    }

    &:hover {
      transition: 0.4s;
      transform: scale(1.1);
      svg {
        transition: 0.4s;
        color: var(--grey);
      }
    }
  }
`;

//ACTOR STYLING
export const ActorContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: ${props => (props.player ? 'flex-end' : 'flex-start')};
  justify-content: ${props => (props.player ? 'flex-end' : 'flex-start')};
`;

export const ActorWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: flex-start;
  align-content: ${props => (props.player ? 'flex-end' : 'flex-start')};
  justify-content: ${props => (props.player ? 'flex-end' : 'flex-start')};
  position: relative;
  padding: 20px 0;

  h5 {
    position: absolute;
    top: 5px;
    left: ${props => (props.player ? 'unset' : '45px')};
    right: ${props => (props.player ? '45px' : 'unset')};
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: ${props =>
      props.player ? '2px solid var(--key-color-2)' : '2px solid var(--key-color)'};
  }

  @media (max-width: 1200px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;

//ACTOR STATEMENT STYLING
export const StatementContainer = styled.li`
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: ${props => (props.player ? 'row-reverse' : 'row')};
  background: transparent;
  width: 100%;
  animation: ${FadeIn} 0.4s;
`;

export const AddStatementBtn = styled.button`
  background: transparent;
  border: none;
  outline: none;
  margin-left: ${props => (props.player ? '32px' : '-32px')};

  svg {
    width: 30px;
    color: var(--grey-3);
  }
`;

export const StatementWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;
  background: var(--grey-5);
  padding: ${props => (props.viewMode ? '8px 16px' : '8px 40px 8px 16px')};
  margin: 5px;
  width: 100%;
  border-radius: 20px;

  textarea {
    min-width: 300px;
    margin: 0;
    background: transparent;
    cursor: pointer;
    overflow: hidden;
    transition: none;

    &:focus {
      background: white;
      transition: 0.4s;
      cursor: initial;
    }
  }
`;

export const StatementBtn = styled.button`
  animation: ${FadeIn} 0.4s;
  padding: 5px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  alignt-content: center;
  justify-content: center;
  outline: none;
  border: none;
  border-radius: 50%;
  background: var(--grey-4);

  :disabled {
    pointer-events: none;
    svg {
      color: var(--grey-3);
    }
  }

  svg {
    width: 18px;
    height: 18px;
    color: var(--grey);
    transition: 0.4s;
    animation: ${props =>
      props.loading !== undefined
        ? css`
            ${Spinning} 10s infinite
          `
        : 'none'};
  }

  &:hover {
    svg {
      transition: 0.4s;
      color: var(--key-color-2);
    }
  }
`;

// MOOD FOLD OUT MENU STYLING
export const FoldOutMoods = styled.div`
  visibility: hidden;
  position: absolute;
  bottom: 35px;
  z-index: 1;
  background: white;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  border: 2px solid var(--key-color-2);
  position: absolute;
  opacity: 1;
  width: 200px;
  height: max-content;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2px;
  padding: 4px;
  border-radius: 10px;
  transform: scale(0);

  div:last-child {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid var(--key-color-2);
    position: absolute;
    bottom: -15px;
    right: 83px;
  }

  button {
    padding-bottom: 2px;
    border-radius: 5px;
    outline: none;
    border: none;

    h6 {
      font-size: 10px;
      font-weight: 600;
      color: var(--grey);
    }

    &:hover {
      transition: 0.4s;
      background: var(--grey-4);
    }
  }
`;

// MOOD CONTAINER STYLING
export const MoodContainer = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  padding-top: 4px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 50%;
  cursor: default;
  pointer-events: ${props => (props.disabled ? 'none' : 'initial')};

  &:hover ${FoldOutMoods} {
    visibility: visible;
    transform: scale(1);
    transition: 0.4s;
    opacity: 1;
  }
`;

// EMOJI STYLING
export const EmojiWrapper = styled.span`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: ${props => (props.fontSize ? props.fontSize : 'initial')};
`;

export const TrainingContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: flex-end;
  justify-content: flex-end;
  width: 100%;
  bottom: 0;
  right: 0;
  position: fixed;
  background: white;
  z-index: 2;
  padding: 10px 20px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  animation: ${FadeIn} 4s;
  }

  @media (max-width: 768px) {
    align-items: center;
    align-content: center;
    justify-content: center;
  }
`;

// CHAT BOT
export const ChatContainer = styled.div`
  width: 400px;
  position: fixed;
  bottom: 68px;
  right: 15px;
  background: white;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  transition: all ease-in-out 0.4s;
  transform: ${props => (props.show ? 'translateY(0)' : 'translateY(400px)')};
  opacity: ${props => (props.show ? '1' : '0')};
  ::-webkit-scrollbar {
    display: none;
  }
`;
export const ChatTopBar = styled.div`
  display: flex;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;
  background: var(--grey-5);
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
  padding: 5px 15px;

  h4 {
    color: var(--grey-1);
    font-size: 14px;
  }

  div {
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    span {
      font-size: 12px;
      color: var(--grey-1):
    }
    input {
      margin: 0 5px;
      cursor: pointer;
    }
    button {
      background: transparent;
      outline: none;
      border: none;
      svg {
        width: 25px;
        color: var(--grey-1);
      }
    }
  }
`;

export const ChatWrapper = styled.div`
  width: 100%;
  height: 400px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
  display: flex;
  justify-content: flex-end;
  flex-direction: column-reverse;
  padding: 0 15px;
  ::-webkit-scrollbar {
    display: none;
  }
  h5 {
    font-weight: bold !important;
  }
`;

export const ChatInputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  place-content: flex-start;
  background: var(--grey-5);
  padding: 10px;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.3);

  textarea::-webkit-scrollbar {
    display: none;
  }
`;

export const ProgressWrapper = styled.div`
  width: 150px;
  transition: 0.6s;
  padding: 2px;
  margin-left: 10px;
`;
