import styled from 'styled-components';

export const NavContainer = styled.div`
  display: none;
  padding: 10px 15px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: transparent;
  width: min-content;

  button {
    background: var(--key-color-2);
    padding: 0 0;
    border: none;
    outline: none;
    color: white;
    border-radius: 50%;
    padding: 10px;

    svg {
      width: 20px;
      height: 20px;
      color: white;
    }
  }

  h1 {
    font-size: 1em;
    font-weight: bold;
    border-radius: 10px;
    color: white;
  }

  img {
    width: 70px;
  }

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
  }
`;

export const SliderBlanket = styled.div`
  position: fixed;
  /*inset: 0px; /* same function as top, left etc dsnt work in chrome*/
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2) none repeat scroll 0% 0%;
  animation: 0.2s ease-out 0s 1 normal none running eMLfYp;
  z-index: 100;
`;
