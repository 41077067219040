import React, { useEffect, useState, useContext } from 'react';
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import { compose } from 'recompose';
import { ScenariosPageContainer, ListHeader, NewScenarioButton, NoScenarios } from './style';
import { AdminFilterContext } from '../../components/AdminFilter/AdminFilterContext';
import Loading from '../../components/Loading';
import ScenarioFollowUp from '../../components/ScenarioFollowUp';
import { FrInput } from '../../components/DesignSystem/style.js';
import { Plus } from 'styled-icons/fa-solid/Plus';
import { DialogueMenuUL, ListItem } from '../ScenarioEditor/style';
import * as LoadingMsg from '../../constants/loadingMessages';
import * as ROUTES from '../../constants/routes';
import { PageTitle } from '../Admin/AdminHeader';

const ScenariosListView = props => {
  const { state } = useContext(AdminFilterContext);
  const [userScenarios, setUserScenarios] = useState(null);
  const [recommended, setRecommended] = useState(null);
  const [userArchivedScenarios, setUserArchivedScenarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchString, setSearchString] = useState('');
  const [filterMode, setFilterMode] = useState('active');
  const [searchResults, setSearchResults] = useState(null);
  const [scenariosToShow, setScenariosToShow] = useState([]);
  const [loadingFilter, setLoadingFilter] = useState(false);

  const mapUserProgressToScenarios = (scenarios, user) => {
    let tempUserScenario = scenarios.map(scenario => ({
      userData: user.scenarios && user.scenarios[scenario.id] ? user.scenarios[scenario.id] : null,
      archived:
        user.scenarios && user.scenarios[scenario.id] && user.scenarios[scenario.id].archived
          ? true
          : false,
      ...scenario,
    }));
    const filteredScenarios = tempUserScenario.filter(
      scenario => !scenario.archived && scenario.visible === true,
    );
    let tempRecommended = filteredScenarios
      .filter(scen => scen.mandatory !== null)
      .sort((a, b) => (parseInt(a.mandatory) > parseInt(b.mandatory) ? 1 : -1))
      .reverse();
    setRecommended(tempRecommended.slice(0, 2));
    setUserScenarios(filteredScenarios);
    setUserArchivedScenarios(tempUserScenario.filter(scenario => scenario.archived));
    setLoading(false);
  };

  useEffect(() => {
    if (!state.loading && state.scenarios) {
      mapUserProgressToScenarios(
        state.scenarios.filter(scen => scen.visible === true),
        props.authUser,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loading, props.authUser]);

  const searchInArray = (arr, searchString) => {
    if (arr && arr.length > 0) {
      return arr.filter(
        scenario =>
          scenario.settings &&
          scenario.settings.scenarioName &&
          scenario.settings.scenarioName.toLowerCase().match(searchString.toLowerCase()),
      );
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (searchString !== '') {
      setSearchResults(searchInArray(userScenarios, searchString));
    } else {
      setSearchResults(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  useEffect(() => {
    if (userScenarios && filterMode) {
      setLoadingFilter(true);
      setTimeout(() => {
        switch (filterMode) {
          case 'active':
            setScenariosToShow(userScenarios);
            setLoadingFilter(false);
            break;
          case 'played':
            setScenariosToShow(userScenarios.filter(scen => scen.userData !== null));
            setLoadingFilter(false);
            break;
          case 'unplayed':
            setScenariosToShow(userScenarios.filter(scen => scen.userData === null));
            setLoadingFilter(false);
            break;
          default:
            return [];
        }
      }, 500);
    }
  }, [userScenarios, filterMode]);

  return (
    <ScenariosPageContainer>
      {!state.loading && (
        <PageTitle title={'Scenarios'} withDate={false} padding={'56px 10px 24px'} />
      )}

      {!state.loading && (
        <FrInput
          style={{ marginBottom: '32px', boxShadow: '0px 0px 7px rgb(0 0 0 / 10%)' }}
          placeholder="🔍  Search for a scenario..."
          value={searchString}
          onChange={e => setSearchString(e.target.value)}
          disabled={!userScenarios || (userScenarios && userScenarios.length === 0)}
        />
      )}

      {recommended && !searchResults && (
        <>
          <h2 style={{ fontSize: '24px', padding: '0 10px 5px' }}>Recommended</h2>
          <ul>
            {recommended.map((scenario, index) => (
              <li key={index}>
                <ScenarioFollowUp
                  scenario={scenario}
                  userId={state.selectedUser}
                  full={true}
                  authUser={props.authUser}
                />
              </li>
            ))}
          </ul>
        </>
      )}

      {!loading && recommended && recommended.length === 0 && (
        <NoScenarios>Hmm, it appears that you don't have any recommeded scenarios...</NoScenarios>
      )}

      {userScenarios && searchResults && (
        <>
          <h2 style={{ fontSize: '24px', padding: '0 10px 5px' }}>Recommended</h2>
          <ul>
            {searchResults
              .filter(scen => scen.mandatory !== null)
              .map((scenario, index) => (
                <li key={index}>
                  <ScenarioFollowUp
                    scenario={scenario}
                    userId={state.selectedUser}
                    full={true}
                    authUser={props.authUser}
                  />
                </li>
              ))}
          </ul>
        </>
      )}

      {userScenarios && searchResults && (
        <>
          <h2 style={{ fontSize: '24px', padding: '10px 10px 5px 10px' }}>Visible</h2>
          <ul>
            {searchResults
              .filter(scen => scen.mandatory === null)
              .map((scenario, index) => (
                <li key={index}>
                  <ScenarioFollowUp
                    scenario={scenario}
                    userId={state.selectedUser}
                    full={true}
                    authUser={props.authUser}
                  />
                </li>
              ))}
          </ul>
        </>
      )}

      {userScenarios && searchResults && userArchivedScenarios && (
        <>
          <h2 style={{ fontSize: '24px', padding: '10px 10px 5px 10px' }}>Archived</h2>
          <ul>
            {searchInArray(userArchivedScenarios, searchString)
              .filter(scen => scen.mandatory === null)
              .map((scenario, index) => (
                <li key={index}>
                  <ScenarioFollowUp
                    key={index}
                    scenario={scenario}
                    archived={true}
                    userId={state.selectedUser}
                  />
                </li>
              ))}
          </ul>
        </>
      )}

      {loading && (
        <Loading loadingText={LoadingMsg.SCENARIOS} width={'80px'} containerHeight={'400px'} />
      )}

      {!loading && scenariosToShow && !searchResults && (
        <>
          <h2 style={{ fontSize: '24px', padding: '32px 10px 5px' }}>Scenarios</h2>

          <DialogueMenuUL>
            <ListItem selected={filterMode === 'active'} onClick={() => setFilterMode('active')}>
              All scenarios
            </ListItem>
            <ListItem selected={filterMode === 'played'} onClick={() => setFilterMode('played')}>
              Played
            </ListItem>
            <ListItem
              selected={filterMode === 'unplayed'}
              onClick={() => setFilterMode('unplayed')}
            >
              Unplayed
            </ListItem>
          </DialogueMenuUL>

          {!loadingFilter && (
            <ul data-cy="scenarios">
              {scenariosToShow.map((scenario, index) => (
                <li key={index}>
                  <ScenarioFollowUp
                    scenario={scenario}
                    userId={state.selectedUser}
                    full={true}
                    authUser={props.authUser}
                  />
                </li>
              ))}
            </ul>
          )}

          {loadingFilter && (
            <div
              style={{
                background: 'white',
                boxShadow: '0px 0px 5px rgb(0 0 0 / 7%)',
                width: '100%',
                borderRadius: '20px',
                marginTop: '3px',
              }}
            >
              <Loading width={'80px'} containerHeight={'250px'} />
            </div>
          )}
        </>
      )}

      {!loading &&
        !loadingFilter &&
        (!scenariosToShow || (scenariosToShow && scenariosToShow.length === 0)) && (
          <NoScenarios>{`Hmm, it appears that you don't have any ${filterMode} scenarios...`}</NoScenarios>
        )}

      {userArchivedScenarios && userArchivedScenarios.length >= 1 && !searchResults && (
        <>
          <h2 style={{ fontSize: '24px', padding: '32px 10px 5px' }}>Archived scenarios</h2>
          <ul>
            <ListHeader>
              <p>Name</p>
              <p>Archived</p>
              <p>Skill level</p>
              <p>Times played</p>
            </ListHeader>
            {userArchivedScenarios.map((scenario, index) => (
              <ScenarioFollowUp
                key={index}
                scenario={scenario}
                archived={true}
                userId={state.selectedUser}
              />
            ))}
          </ul>
        </>
      )}

      {props.authUser &&
        (props.authUser.claims.superAdmin || props.authUser.claims.clientAdmin) &&
        state.scenarios &&
        state.scenarios.length !== 0 && (
          <NewScenarioButton onClick={() => props.history.push(ROUTES.SCENARIO_EDITOR)}>
            Create New Scenario
            <Plus />
          </NewScenarioButton>
        )}
    </ScenariosPageContainer>
  );
};

const condition = authUser => !!authUser;

export default compose(withAuthorization(condition), withFirebase)(ScenariosListView);
