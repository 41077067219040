export const AdminFilterReducer = (state, action) => {
  switch (action.type) {
    case 'SET_USERS':
      return {
        ...state,
        users: action.users,
      };
    case 'SET_CLIENTS':
      return {
        ...state,
        clients: action.clients,
      };
    case 'SET_SESSIONS':
      return {
        ...state,
        sessions: action.sessions,
      };

    case 'SET_SCENARIOS':
      return {
        ...state,
        scenarios: action.scenarios,
        loading: false,
      };

    case 'SET_CLIENT':
      return {
        ...state,
        client: action.client,
      };

    case 'SET_USER':
      return {
        ...state,
        user: action.user,
      };

    case 'SET_SELECTED_USER':
      return {
        ...state,
        selectedUser: action.selectedUser,
      };
    case 'SET_SELECTED_CLIENT':
      return {
        ...state,
        selectedClient: action.selectedClient,
      };
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.loading,
      };
    case 'RESET_STATE':
      return {
        users: null,
        clients: null,
        sessions: null,
        scenarios: null,
        client: null,
        user: null,
        selectedUser: null,
        selectedClient: null,
        loading: true,
      };
    default:
      return state;
  }
};
