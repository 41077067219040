import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --background-color-blue: #23cdcd;
    --background-color-white: #ffffff;
    --background-color-pink: #23cdcd;
    
    --gradient-main-lighter: linear-gradient(to right bottom, #FF6858 50%, #FF867A 90%);
    --background-gradient-main: var(--key-color);

    --main-text-color: #787878;
    --off-text-color: #ffffff;
    --disabled-text-color: rgba(0,0,0,0.1);

    --menu-color: #051e34;

    --main-button-color: #1b72e8;
    --main-button-text-color: #ffffff;
    --disabled-main-button-color: rgba(0,0,0,0.1);

    --key-color: #FF6F60;
    --key-color-2: #7F65FF;
    --key-color-2-gradient: linear-gradient(91.82deg, #765CF6 3.83%, #A694FF 98.41%);

    --accent-color: #f8f59d;
    --background-color: #F9FBFC;

    --blue: #6dcff6;
    --purple: #a793eb;
    --orange: #FFAF7A;
    --teal: #59D8B9;

    --grey: #404040;
    --grey-1: #666666;
    --grey-2: #AAAAAA;
    --grey-3: #C4C4C4;
    --grey-4: #D9D9D9;
    --grey-5: #F2F2F2;

    --green: #59D87F;
    --yellow: #FFD03A;
    --red: #FF4444;

    --status-red: #FF5C50;
    --status-yellow: #FFCA20;
    --status-green: #42CE6C;

    --red-gradient: linear-gradient(94.78deg, #FF6F60 0%, #FF9B90 100%);
    --green-gradient: linear-gradient(93.41deg, #59D8B9 1.21%, #89E1CC 95.08%);
    --yellow-gradient: linear-gradient(94.78deg, #FFCA20 0%, #FFDA63 100%);
    
    --box-shadow-1: 0px 0px 7px rgb(0 0 0 / 10%);
    
  body {
    width: 100%;
    background: var(--background-color);
    color: var(--grey);
    ${'' /* font-family: 'Montserrat', sans-serif; */}
    font-family: montserrat;
    font-size: 16px;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  textarea, input, select {
    ${'' /* font-family: 'Montserrat', sans-serif; */}
    font-weight: 500;
    font-family: montserrat;
  }

  button {
    outline: none;
    cursor: pointer;
    ${'' /* font-family: 'Montserrat', sans-serif; */}
    font-family: montserrat;
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  } 

  h1 {
    font-size: 32px;
    padding: .5em;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 16px;
  }
`;

export { GlobalStyle };
