import styled from 'styled-components';

export const FormWrapper = styled.div`
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-text-fill-color: var(--grey);
      -webkit-box-shadow: 0 0 0px 1000px var(--grey-5) inset;
      transition: background-color 5000s ease-in-out 0s;
      font-size: 14px;
    }
    input {
      background: var(--grey-4);
    }
    div {
      width: 100%;
      display: flex;
      justify-content: ${props => (props.passwordLink ? 'space-between' : 'flex-end')};
      margin: 10px 0;
      p {
        font-weight: 700;
        color: var(--grey-2);
        font-size: 12px;

        a {
          color: var(--grey);
        }
      }
      button {
        padding: 10px 20px;
        font-size: 14px;
        font-weight: bold;
        width: 95px;
        align-text: center;
      }
    }
  }

  @media (max-width: 768px) {
    form {
      input {
        font-size: 16px;
      }

      div {
        flex-direction: column-reverse;
        align-items: center;
        margin: 30px 0 0;

        button {
          margin-bottom: 10px;
          width: 180px;
        }
      }
    }
  }
`;
