import React, { useEffect, useState, useContext, useMemo } from 'react';
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import { AdminFilterContext } from '../../components/AdminFilter/AdminFilterContext';
import {
  mapVoiceLinesToExchanges,
  mapSheetVoiceLines,
  settingsFormat,
} from '../../helpFunctions/scenario';
import {
  formatSessionDate,
  formatSessionLength,
  mapSessionsData,
} from '../../helpFunctions/session';
import SessionSummary from './SessionSummary';
import DialogueStatement, { PlayerStatement } from '../ScenarioEditor/DialogueStatement';
// import { BarChart } from '../../components/BarChart';
import InfiniteScroll from 'react-infinite-scroll-component';
import { compose } from 'recompose';
import { FrDiv, FrContainer } from '../../components/DesignSystem/style.js';
import { PageTitle, ScenarioInfoText, DialogueTextWrapper } from '../Scenario/style';
import { ExchangeWrapper, SessionTableContainer } from './style';
import { ScoreMeter } from '../../components/ScenarioFollowUp';
import DataTable from '../../components/DataTable';
import { SESSION } from '../../constants/loadingMessages';
import Loading from '../../components/Loading';

const SessionPage = props => {
  const { state } = useContext(AdminFilterContext);
  const [exchanges, setExchanges] = useState(null);
  const [activeSession, setActiveSession] = useState(null);
  const [scenarioSettings, setScenarioSettings] = useState(null);
  const [sessionAudio, setSessionAudio] = useState(null);
  const [sessionUser, setSessionUser] = useState({});
  const [sessionData, setSessionData] = useState(null);
  const [summaryList, setSummaryList] = useState(null);
  const [loading, setLoading] = useState(true);
  // ERRORS
  const [sessionError, setSessionError] = useState(null);
  const [scenarioError, setScenarioError] = useState(null);
  const [permissionDenied, setPermissionDenied] = useState(null);
  //Infinite scroll
  const [count, setCount] = useState({ prev: 0, next: 5 });
  const [hasMore, setHasMore] = useState(true);
  const [current, setCurrent] = useState(null);

  const sessionColumn = useMemo(
    () => [
      {
        Header: () => null,
        accessor: 'userId',
        Cell: () => (
          <>
            {sessionUser && sessionUser.id !== props.authUser.userId ? (
              <h4>{sessionUser.displayName}</h4>
            ) : (
              <h4>Session</h4>
            )}
          </>
        ),
      },
      // {
      //   Header: () => null,
      //   accessor: 'clientId',
      //   Cell: ({ row }) => <h4>{row.original.clientId}</h4>,
      // },
      {
        Header: () => null,
        accessor: 'date',
        Cell: ({ row }) => (
          <p>
            <span style={{ fontWeight: 'bold' }}>Date </span> {formatSessionDate(row.original.date)}
          </p>
        ),
      },
      {
        Header: () => null,
        accessor: 'sessionLength',
        Cell: ({ row }) => (
          <p>
            <span style={{ fontWeight: 'bold' }}>Length </span>{' '}
            {formatSessionLength(row.original.sessionLength)}{' '}
          </p>
        ),
      },
      {
        Header: () => null,
        id: 'grading',
        Cell: ({ row }) => (
          <div style={{ display: 'flex' }}>
            <p style={{ fontWeight: 'bold' }}>Score</p>
            <ScoreMeter scores={row.original.scores} exchangesCount={row.original.exchangesCount} />
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sessionUser],
  );

  const getScenario = sheetId => {
    fetch('https://spreadsheets.google.com/feeds/list/' + sheetId + '/od6/public/full?alt=json')
      .then(response => response.json())
      .then(data => {
        let tempVoiceLines = mapSheetVoiceLines(data.feed.entry);
        setExchanges(mapVoiceLinesToExchanges(tempVoiceLines, activeSession.events));
        setLoading(false);
      })
      .catch(() => {
        setScenarioError('We could not fetch scenario voice lines');
        setLoading(false);
      });

    fetch('https://spreadsheets.google.com/feeds/list/' + sheetId + '/3/public/full?alt=json')
      .then(response => response.json())
      .then(data => {
        if (data && data.feed && data.feed.entry) {
          let tempSettings = {};
          data.feed.entry.forEach(ent => {
            if (ent && ent.gsx$setting) {
              tempSettings[ent.gsx$setting.$t] = ent.gsx$value.$t;
            }
          });
          setScenarioSettings(settingsFormat(tempSettings));
          setLoading(false);
        }
      })
      .catch(() => {
        setScenarioError('We could not fetch scenario voice lines');
        setLoading(false);
      });
  };

  const getFullSessionAudio = (clientId, userId, sessionId) => {
    props.firebase
      .combinedAudio(clientId, userId, sessionId)
      .child('session.wav')
      .getDownloadURL()
      .then(url => {
        setSessionAudio(url);
      })
      .catch(() => {
        // setSessionAudioError('Error loading dialogue audio file');
      });
  };

  const getUser = userId => {
    props.firebase.newUser(userId).once('value', snapshot => {
      const userObject = snapshot.val();
      if (userObject) {
        setSessionUser({ id: userId, ...userObject });
      }
    });
  };

  const getSession = sessionPath => {
    getUser(sessionPath[1]);
    getFullSessionAudio(sessionPath[0], sessionPath[1], sessionPath[2]);
    props.firebase
      .newSession(sessionPath[0], sessionPath[1], sessionPath[2])
      .once('value', snapshot => {
        const sessionObj = snapshot.val();
        if (sessionObj) {
          setActiveSession({ ...sessionObj, client_id: sessionPath[0] });
          setLoading(false);
        } else {
          setLoading(false);
          setSessionError('We could not locate player session: ' + sessionPath[2]);
        }
      })
      .catch(() => {
        setLoading(false);
        setSessionError('We could not locate any player session');
      });
  };

  const getSessionSummary = session => {
    props.firebase
      .sessionSummary(session.client_id, session.user_id, session.id)
      .once('value', snapshot => {
        const summaryObj = snapshot.val();
        if (summaryObj) {
          let colors = {
            0: 'var(--status-red)',
            1: 'var(--status-yellow)',
            2: 'var(--status-green)',
          };
          const tempSummaryList = Object.keys(summaryObj).map(key => ({
            ...summaryObj[key],
            color: colors[summaryObj[key].remark],
          }));
          setSummaryList(tempSummaryList);
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (exchanges) {
      setCurrent(exchanges.slice(count.prev, count.next));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchanges]);

  useEffect(() => {
    if (activeSession && sessionUser) {
      getSessionSummary(activeSession);
      props.firebase
        .newScenario(activeSession.scenario_id)
        .once('value', snapshot => {
          const scenarioObj = snapshot.val();
          if (scenarioObj) {
            if (scenarioObj.voiceLines) {
              let tempExchanges = null;
              if (props.authUser && props.authUser.userId === sessionUser.id) {
                let activeUserScenario = props.authUser.scenarios
                  ? props.authUser.scenarios[activeSession.scenario_id]
                  : null;
                tempExchanges = mapVoiceLinesToExchanges(
                  scenarioObj.voiceLines,
                  activeSession.events,
                  activeUserScenario,
                  activeSession.id,
                );
              } else {
                tempExchanges = mapVoiceLinesToExchanges(
                  scenarioObj.voiceLines,
                  activeSession.events,
                );
              }
              setExchanges(tempExchanges);
              setScenarioSettings(scenarioObj.settings);
            } else {
              getScenario(scenarioObj.googleSheet);
            }
          } else {
            setScenarioError('We could not locate the current scenario');
            setLoading(false);
          }
        })
        .catch(() => {
          setScenarioError('We could not locate the current scenario');
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSession, sessionUser]);

  useEffect(() => {
    if (
      !state.loading &&
      props.match &&
      props.match.params &&
      props.match.params.id &&
      !activeSession
    ) {
      let sessionPath = props.match.params.id.split(':');
      if (
        props.authUser.userId === sessionPath[1] ||
        (props.authUser.claims && props.authUser.claims.superAdmin)
      ) {
        getSession(sessionPath);
      } else {
        props.firebase.sharedSessions(props.match.params.id).once('value', snapshot => {
          const sharedSession = snapshot.val();
          if (
            sharedSession &&
            sharedSession[props.match.params.id] &&
            sharedSession[props.match.params.id].sharedWith &&
            sharedSession[props.match.params.id].sharedWith[props.authUser.userId]
          ) {
            getSession(sessionPath);
          } else {
            setPermissionDenied("You don't have access to view the required session");
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loading]);

  useEffect(() => {
    if (activeSession && scenarioSettings && sessionUser) {
      let tempSessionsData = mapSessionsData(
        [activeSession],
        [{ id: activeSession.scenario_id, settings: scenarioSettings }],
        sessionUser,
      );
      setSessionData(tempSessionsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSession && scenarioSettings && sessionUser]);

  const loadMoreStatements = () => {
    if (current.length === exchanges.length) {
      setHasMore(false);
      return;
    }
    setTimeout(() => {
      setCurrent(current.concat(exchanges.slice(count.prev + 5, count.next + 5)));
    }, 1000);
    setCount(prevState => ({ prev: prevState.prev + 5, next: prevState.next + 5 }));
  };

  return (
    <FrContainer>
      {!permissionDenied && !sessionError ? (
        <>
          {scenarioError && !loading && (
            <PageTitle>
              <h2 style={{ padding: '30px 0', width: '100%', textAlign: 'center' }}>
                {scenarioError}
              </h2>
            </PageTitle>
          )}
          {!loading && scenarioSettings && scenarioSettings.scenarioName && (
            <PageTitle>
              <h1>{scenarioSettings.scenarioName}</h1>
            </PageTitle>
          )}
          {!loading && scenarioSettings && scenarioSettings.scenarioText && (
            <ScenarioInfoText width={'70%'}>{scenarioSettings.scenarioText}</ScenarioInfoText>
          )}
          {loading && <Loading loadingText={SESSION} width={'80px'} containerHeight={'400px'} />}

          {sessionData && (
            <SessionTableContainer>
              <DataTable columns={sessionColumn} data={sessionData} paginationOff={true} />
            </SessionTableContainer>
          )}

          {!loading && summaryList && <SessionSummary summaryList={summaryList} />}

          {!loading && activeSession && current && (
            <FrDiv flexDirection={'column'}>
              <h2 style={{ color: 'var(--grey)', textAlign: 'left', width: '100%' }}>Dialogue</h2>
              {sessionAudio && (
                <FrDiv flexDirection={'column'} margin={'10px 0 0'}>
                  <FrDiv background={'#f1f3f4'}>
                    <audio
                      style={{ width: '100%', outline: 'none' }}
                      controls
                      src={sessionAudio}
                    ></audio>
                  </FrDiv>
                </FrDiv>
              )}
              <ul
                style={{
                  transition: '.4s',
                  minHeight: '100vh',
                  width: '100%',
                }}
              >
                <InfiniteScroll
                  dataLength={current.length}
                  next={loadMoreStatements}
                  hasMore={hasMore}
                  loader={<Loading width={'60px'} containerHeight={'100px'} />}
                  style={{ overflow: 'unset' }}
                >
                  {current.map((exchange, index) => (
                    <ExchangeWrapper key={index}>
                      {exchange.bot && (
                        <DialogueTextWrapper bot={true}>
                          <DialogueStatement
                            disableEditing={true}
                            question={true}
                            charName={
                              scenarioSettings &&
                              scenarioSettings.characters.character_1 &&
                              scenarioSettings.characters.character_1.displayName
                            }
                            scenarioToEdit={activeSession.scenario_id}
                            statement={exchange.bot}
                          />
                        </DialogueTextWrapper>
                      )}

                      {exchange && (
                        <DialogueTextWrapper bot={false}>
                          <PlayerStatement
                            transcriptions={
                              activeSession.lines &&
                              activeSession.lines[exchange.player && exchange.player.parameter] &&
                              activeSession.lines[exchange.player && exchange.player.parameter]
                                .transcriptions
                            }
                            activeSession={activeSession}
                            disableEditing={true}
                            bestAnswer={
                              props.authUser &&
                              props.authUser.scenarios &&
                              props.authUser.scenarios[activeSession.scenario_id] &&
                              props.authUser.scenarios[activeSession.scenario_id].exchanges &&
                              props.authUser.scenarios[activeSession.scenario_id].exchanges[
                                exchange.bot && exchange.bot.id
                              ] &&
                              props.authUser.scenarios[activeSession.scenario_id].exchanges[
                                exchange.bot.id
                              ].audioAnswer
                            }
                            charName={
                              activeSession.user_id === sessionUser.id
                                ? 'Your Answer'
                                : sessionUser.displayName
                            }
                            statement={exchange.player && exchange.player.parameter}
                            exchange={exchange}
                            scenarioToEdit={activeSession.scenario_id}
                          />
                        </DialogueTextWrapper>
                      )}
                    </ExchangeWrapper>
                  ))}
                </InfiniteScroll>
              </ul>
            </FrDiv>
          )}
        </>
      ) : (
        <PageTitle>
          {permissionDenied && (
            <h2 style={{ padding: '30px 0', width: '100%', textAlign: 'center' }}>
              {permissionDenied}
            </h2>
          )}
          {sessionError && (
            <h2 style={{ padding: '30px 0', width: '100%', textAlign: 'center' }}>
              {sessionError}
            </h2>
          )}
        </PageTitle>
      )}
    </FrContainer>
  );
};

const condition = authUser => !!authUser;

export default compose(withAuthorization(condition), withFirebase)(SessionPage);
