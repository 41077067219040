import styled from 'styled-components';

export const ButtonContainer = styled.div`
  width: 100%;
  height: ${props => (props.height ? `${props.height + 'px'}` : '80px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  cursor: pointer;
  transition: .4s;

  p {
    font-size: 12px;
    font-weight: bold;
    margin-top: 5px;
    color: ${props => (props.path === props.history ? `var(--key-color-2)` : `var(--grey-3)`)};
  }

  svg {
    color: ${props => (props.path === props.history ? `var(--key-color-2)` : `var(--grey-3)`)};
    width: 2.5em;
  }

  &:hover {
      transition: .4s;
    p {
      color: var(--key-color);
    }
    svg {
      color: var(--key-color);
    }
  }
`;
