import React, { useState, useEffect } from 'react';
import { FrContainer } from '../../components/DesignSystem/style';
import { HelpContainer } from '../Home/style';
import { USER_GUIDE } from '../../constants/urls';
import { PageTitle } from '../Admin/AdminHeader';
import { Apple } from 'styled-icons/boxicons-logos/Apple';
import { Windows } from 'styled-icons/boxicons-logos/Windows';
import { StepContainer, StepWrapper, LineDiv } from './style';
import CollapsibleContainer from '../../components/CollapsibleContainer';
import { ReactComponent as OculusLogo } from '../../assets/OculusLogo.svg';

const Downloads = () => {
  const [recommended, setRecommended] = useState(null);
  const [hasDownloaded, setHasDownloaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const tempHasDownloaded = JSON.parse(window.localStorage.getItem('hasDownloaded'));
    if (tempHasDownloaded) {
      setHasDownloaded(true);
    }
  }, []);

  useEffect(() => {
    var isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    var isWindows = navigator.platform.toUpperCase().indexOf('WIN') >= 0;
    if (isMac) {
      setRecommended('mac');
    } else if (isWindows) {
      setRecommended('win');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigator.platform]);

  const saveHasDownloaded = () => {
    window.localStorage.setItem('hasDownloaded', JSON.stringify('true'));
  };

  /* eslint-disable jsx-a11y/anchor-is-valid */
  return (
    <FrContainer>
      <PageTitle padding={'20px 0'} title={'Get started'} withDate={false} />
      <StepContainer>
        <CollapsibleContainer
          isOpen={!hasDownloaded ? true : false}
          padding={'0'}
          label={'Step 1: Download'}
          label2={hasDownloaded ? 'You already have the app downloaded' : null}
        >
          <StepWrapper recommended={recommended === 'win'}>
            <h5>
              Windows
              {recommended === 'win' && <span> (recommended) </span>}
            </h5>
            <div>
              <ul>
                <li>
                  <span>1.</span> Click the Windows button to start the download.
                </li>
                <li>
                  <span>2.</span> The app should start automatically, if not, find it in your Start
                  Menu.
                </li>
                <li>
                  <span>3.</span> Every time the app starts, It will first check for updates and
                  automatically update itself, before starting the app. This can take some time.
                </li>
              </ul>
              <a
                onClick={() => saveHasDownloaded()}
                href={process.env.REACT_APP_DOWNLOAD_WIN}
              >
                <Windows />
              </a>
            </div>
          </StepWrapper>
          <LineDiv></LineDiv>
          <StepWrapper recommended={recommended === 'mac'}>
            <h5>
              Mac
              {recommended === 'mac' && <span> (recommended) </span>}
            </h5>
            <div>
              <ul>
                <li>
                  <span>1.</span> Click the Apple button to start the download.
                </li>
                <li>
                  <span>2.</span> At <span>Destination select</span>, make sure to press{' '}
                  <span>install for me only</span>.
                </li>
                <li>
                  <span>3.</span> Complete the installation and in a few moments, you should be able
                  to find the app in your <span>/Users/{'<user>'}/Application/</span>{' '}
                  folder, or by searching for Fictive Reality in Spotlight.
                </li>
                <li>
                  <span>4.</span> Every time the app starts, It will first check for updates and
                  automatically update itself, before starting the app. This can take some time.
                </li>
              </ul>
              <a
                onClick={() => saveHasDownloaded()}
                href={process.env.REACT_APP_DOWNLOAD_MAC}
              >
                <Apple />
              </a>
            </div>
          </StepWrapper>
          <LineDiv></LineDiv>
          <StepWrapper>
            <h5>VR</h5>
            <div>
              <p>Click the Oculus logo to view our detailed guide for installation in VR.</p>
              <a href={USER_GUIDE}>
                <OculusLogo />
              </a>
            </div>
          </StepWrapper>
        </CollapsibleContainer>
      </StepContainer>
      <StepContainer>
        <CollapsibleContainer
          isOpen={hasDownloaded ? true: false}
          padding={'0'}
          label={'Step 2: Navigate & Login'}
        >
          <StepWrapper>
            <h5>Navigation</h5>
            <div>
              <ul>
                <li>
                  <span>1.</span> To focus on the dialogue, your avatar is not able to walk around
                  in the environment and will be stationary{' '}
                  <span>or instantly moved between meeting rooms</span>
                </li>
                <li>
                  <span>2.</span> To interact with the virtual environment on your computer, you
                  hold <span>Ctrl</span> and your mouse to look
                  around
                </li>
                <li>
                  <span>3.</span> Use the mouse to click buttons and the keyboard to type
                  email/password
                </li>
              </ul>
            </div>
            <h5>Login</h5>
            <div style={{ background: 'var(--grey-5)', alignItems: 'flex-start', padding: '20px' }}>
              <ul>
                <li>
                  <span>Email/Password:</span> When you start the app the first time, you will be
                  asked to log in with your user account. Do this by typing in email and password.
                  We will save your details for your next login.
                </li>
                <li>
                  <span>PIN code:</span> if you have been provided with a PIN, you can also use that
                  login by switching mode.
                </li>
              </ul>
              <img src={require('../../assets/loginGif.gif')} alt="Login instructions" />
            </div>
          </StepWrapper>
        </CollapsibleContainer>
      </StepContainer>
      <StepContainer>
        <CollapsibleContainer
          isOpen={false}
          padding={'0'}
          label={'Step 3: Select & Play Scenarios'}
        >
          <StepWrapper>
            <h5>Select a scenario</h5>
            <div style={{ alignItems: 'flex-start' }}>
              <p>
                After you logged in, you will come to a menu with options of different dialogue
                scenarios that you can take. At the top you have scenarios your educators have
                recommended, and below that, all other scenarios. To get more information about the
                scenarios you can click on it and a fact box will show on the right. If it seems
                like the right scenario for you, click Enter.
              </p>
              <img src={require('../../assets/scenario-select.png')} alt="Select scenario" />
            </div>
          </StepWrapper>
          <StepWrapper>
            <h5>Play a scenario</h5>
            <div style={{ alignItems: 'flex-start' }}>
              <ul>
                <li>
                  <span>View:</span> In view you can see, listen, and read the dialogue you soon are
                  supposed to perform yourself.
                </li>
                <li>
                  <span>Speak:</span> It´s your turn to now speak with the avatar and perform the
                  dialogue yourself.
                </li>
                <li>
                  <span>Review:</span> You cannot push the review button until you have something to
                  review.
                </li>
              </ul>
              <img src={require('../../assets/play-scenario.png')} alt="Play scenario" />
            </div>
          </StepWrapper>
        </CollapsibleContainer>
      </StepContainer>
      <StepContainer>
        <CollapsibleContainer
          isOpen={false}
          padding={'0'}
          label={'Step 4: Review your performance'}
        >
          <StepWrapper>
            <h5>Review</h5>
            <div style={{ alignItems: 'flex-start' }}>
              <p>
                After you have been playing your scenario, the review button will turn yellow, and
                you can now push it. Here you can listen to yourself by pushing the eye symbol and
                you can read what you have been saying. (The translation from audio to text of your
                recorded voice is done by the computer and is not always perfect, due to audio
                environment and accents, for example).
                <br />
                <br />
                On the left side the virtual coach evaluates you in different categories, for
                example your attitude, use of fillers, and hesitation. On the right side you are
                going to rate yourself using “the traffic light model”. When you think you did your
                absolutely best and want to save your answer, you can put a star on it. After that
                you push “Done” and you will get your total rating.
              </p>
              <img src={require('../../assets/review-scenario.png')} alt="Review scenario" />
            </div>
          </StepWrapper>
          <StepWrapper>
            <h5>Scenario complete</h5>
            <div>
              <ul>
                <li>
                  <span>Share session:</span> you now have the opportunity to share your session
                  with your friends, coworkers and educator.
                </li>
                <li>
                  <span>View all sessions:</span> Go to the sessions page to view all your sessions
                  and sessions that's been shared with you.
                </li>
                <li>
                  <span>Compete:</span> Check the home page to view your score and compete against
                  your coworkers to claim{' '}
                  <span style={{ color: 'var(--key-color-2)' }}>Rank #1</span>.
                </li>
              </ul>
            </div>
          </StepWrapper>
        </CollapsibleContainer>
      </StepContainer>
      <HelpContainer>
        <h2>Need more help?</h2>
        <h4>
          <a href={USER_GUIDE} target="_blank" rel="noopener noreferrer">
            Click here for detailed guide!
          </a>
        </h4>
        <img src={require('../../assets/mask-group.png')} alt="" />
      </HelpContainer>
    </FrContainer>
  );
};
export default Downloads;
