// Maps scenario voiceLines to exchanges. With player events if present
export const mapVoiceLinesToExchanges = (voiceLines, events, userScenario, sessionId) => {
  let botEntries = [];
  let lookupTeacher = {};
  let lookupEvent = {};
  const exchangesArr = [];

  if(events) {
    events.forEach(event => {
      if(event.event_type === 'PLAYER_STATEMENT') {
        lookupEvent[event.parameter.slice(9, event.parameter.length)] = event;
      }
    })
  }

  Object.keys(voiceLines).forEach(voiceLine => {
    if (voiceLine && voiceLines[voiceLine].followsLine) {
      lookupTeacher[voiceLines[voiceLine].followsLine] = {
        ...voiceLines[voiceLine],
        id: voiceLine,
      };
    } else {
      botEntries.push({ ...voiceLines[voiceLine], id: voiceLine });
    }
  });

  botEntries.forEach(bot => {
    let score = null;
    if(userScenario && userScenario.exchanges && userScenario.exchanges[bot.id] && userScenario.exchanges[bot.id].audioAnswer === sessionId) {
      score = 30;
    } else {
      score = lookupEvent[bot.id] ? lookupEvent[bot.id].score : null
    }
    exchangesArr.push({
      bot: bot,
      player: lookupEvent[bot.id] ? lookupEvent[bot.id] : null,
      teacher: lookupTeacher[bot.id] ? lookupTeacher[bot.id] : null,
      score: score,
    });
  })
  return exchangesArr;
};

const getOrder = vlId => {
  switch (vlId.length) {
    case 1:
      return vlId + '000';
    case 2:
      return vlId + '00';
    case 3:
      return vlId + '0';
    case 4:
      return vlId;
    default:
      return;
  }
};

// Maps scenario voiceLines from sheet to firebase scenario voiceLine format
export const mapSheetVoiceLines = scenarioSheet => {
  const voiceLines = {};
  let answerOrder = 0;
  for (let i = 0; i < scenarioSheet.length; i++) {
    if (scenarioSheet[i].gsx$followsline.$t && scenarioSheet[i].gsx$followsline.$t !== '') {
      answerOrder += 1;
      voiceLines[scenarioSheet[i].gsx$id.$t.toLowerCase()] = {
        name: scenarioSheet[i].gsx$name.$t,
        character: scenarioSheet[i].gsx$character,
        toolTip: scenarioSheet[i].gsx$tooltip.$t,
        order: getOrder(answerOrder.toString()),
        mood: scenarioSheet[i].gsx$mood.$t,
        hidden: scenarioSheet[i].gsx$hidden.$t === 'Yes' ? true : false,
        followsLine: scenarioSheet[i].gsx$followsline.$t.toLowerCase(),
        hint: scenarioSheet[i].gsx$tips ? scenarioSheet[i].gsx$tips.$t : null,
      };
    } else {
      if (scenarioSheet[i].gsx$name.$t !== '' && scenarioSheet[i].gsx$tooltip.$t !== '') {
        voiceLines[scenarioSheet[i].gsx$id.$t.toLowerCase()] = {
          name: scenarioSheet[i].gsx$name.$t,
          character: scenarioSheet[i].gsx$character,
          toolTip: scenarioSheet[i].gsx$tooltip.$t,
          order: getOrder(i.toString()),
          mood: scenarioSheet[i].gsx$mood.$t,
          hidden: scenarioSheet[i].gsx$hidden.$t === 'Yes' ? true : false,
        };
      }
    }
  }
  return voiceLines
};

export const settingsFormat = s => {
  if (Object.keys(s).length !== 0) {
    return {
      scenarioName: s.ScenarioName,
      scenarioText: s.ScenarioText,
      environment: s.Environment,
      exchangesCount: s.ExchangesCount,
      videoUrl: s.VideoUrl,
      videoTranscript: s.VideoTranscript,
      characters: {
        character_1: {
          displayName:
            s.CharactersDisplayNames &&
            s.CharactersDisplayNames.slice(0, s.CharactersDisplayNames.lastIndexOf(',')),
          spawnPosition:
            s.CharacterSpawnPositions &&
            s.CharacterSpawnPositions.slice(0, s.CharacterSpawnPositions.lastIndexOf(',')),
          character: s.Characters && s.Characters.slice(0, s.Characters.lastIndexOf(',')),
        },
        character_2: {
          displayName:
            s.CharactersDisplayNames &&
            s.CharactersDisplayNames.slice(
              s.CharactersDisplayNames.lastIndexOf(',') + 1,
              s.CharactersDisplayNames.length,
            ),
          spawnPosition:
            s.CharacterSpawnPositions &&
            s.CharacterSpawnPositions.slice(
              s.CharacterSpawnPositions.lastIndexOf(',') + 1,
              s.CharacterSpawnPositions.length,
            ),
          character:
            s.Characters &&
            s.Characters.slice(s.Characters.lastIndexOf(',') + 1, s.Characters.length),
        },
        character_3: {
          spawnPosition: s.PlayerSpawnPosition,
          character: '',
        },
      },
    };
  } else {
    return null;
  }
};