import React, { useContext, useEffect, useState, useMemo, useCallback, useRef } from 'react';
import ScenarioEditor from '../ScenarioEditor';
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import { AdminFilterContext } from '../../components/AdminFilter/AdminFilterContext';
import { compose } from 'recompose';
// import AddNewScenario from './AddNewScenario';
import DataTable from '../../components/DataTable';
import ReactTooltip from 'react-tooltip';
import Loading from '../../components/Loading';
import {
  mapSessionsData,
  formatSessionDate,
  formatSessionLength,
  averageSessionLength,
} from '../../helpFunctions/session';
import { Route } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {
  AdminContainer,
  AdminContentWrapper,
  AdminTableWrapper,
  TableScrollWrapper,
  TableHeader,
  NewClientWrapper,
  VisibilityButton,
  MandatorySelect,
  AddScenarioWrapper,
  AddScenarioContainer,
  PrimaryBtn,
  EditClientInput,
  PrimeButton,
  SaveMandatory,
  InnerDataTable,
  ClickableHeader,
  LogoDiv,
  StatsBox,
} from './style';
import { HOME } from '../../constants/routes';
import { MenuContainerFixed, Options } from '../../components/ScenarioFollowUp/style';
import { ChevRight, ChevDown } from '../../styles/icons';
import { Users } from 'styled-icons/fa-solid/Users';
import { Bubbles2 } from 'styled-icons/icomoon/Bubbles2';
import { StatsBars } from 'styled-icons/icomoon/StatsBars';
import { Plus } from 'styled-icons/fa-solid/Plus';
import { Edit } from 'styled-icons/boxicons-regular/Edit';
import { Dots } from '../../styles/icons';
import { DeleteForever } from 'styled-icons/material/DeleteForever';
import { Show } from 'styled-icons/boxicons-regular/Show';
import { QuestionCircle } from 'styled-icons/fa-regular/QuestionCircle';
import { CloudUpload } from 'styled-icons/boxicons-solid/CloudUpload';

// import { Upload } from 'styled-icons/boxicons-regular/Upload';
import { ScoreMeter } from '../../components/ScenarioFollowUp';
import * as LoadingMsg from '../../constants/loadingMessages';
import { ErrorCircle } from 'styled-icons/boxicons-regular/ErrorCircle';
import { PageTitle, AdminNavigation } from './AdminHeader';
import { FrDiv, FrFileInputWrapper, FrButton } from '../../components/DesignSystem/style.js';

const Admin = props => {
  const { state } = useContext(AdminFilterContext);
  const [allClientSessions, setAllClientSessions] = useState(null);
  const [scenariosData, setScenariosData] = useState(null);
  const [usersData, setUsersData] = useState(null);
  const [clientsData, setClientsData] = useState(null);
  const [originalClients, setOriginalClients] = useState(null);
  const [selectedScenario, setSelectedScenario] = useState('');
  const [clientDomain, setClientDomain] = useState('');
  // const [clientLogoUrl, setClientLogoUrl] = useState('');
  const [clientName, setClientName] = useState('');
  const [addClientError, setAddClientError] = useState(null);
  const addNewClientValidation = clientDomain === '' || clientName === '';
  const [clientToEdit, setClientToEdit] = useState(null);

  const updateData = (rowIndex, columnId, value) => {
    setClientsData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  };

  // React table columns

  const scenarioColumns = useMemo(
    () => [
      {
        Header: () => (
          <div style={{ textAlign: 'center', fontWeight: '500', display: 'inline' }}>
            <QuestionCircle
              style={{ width: '16px', color: 'var(--grey-3)', margin: '0 5px' }}
              data-tip="The top two prioritized scenarios </br> will be showed as recommended </br> to the users."
              data-for="priority"
            />
            <ReactTooltip
              style={{ maxWidth: '100px' }}
              id="priority"
              multiline={true}
              html={true}
            />
            <span style={{ fontWeight: 'bold' }}>Priority</span>
          </div>
        ),
        accessor: 'mandatory',
        Cell: MandatoryCell,
      },
      {
        Header: 'Name',
        accessor: 'scenarioName',
        Cell: ({ row }) => (
          <>
            {!row.original.error && (
              <ClickableHeader
                onClick={() =>
                  props.history.push(ROUTES.SCENARIO_EDITOR + '/' + row.original.scenarioId)
                }
              >
                {row.original.scenarioName}
                <br />
                {props.authUser && props.authUser.claims && props.authUser.claims.superAdmin && (
                  <span>{row.original.scenarioId}</span>
                )}
              </ClickableHeader>
            )}

            {row.original.error && (
              <h5 style={{ color: 'var(--red)' }}>
                <ErrorCircle style={{ width: '20px', color: 'var(--red)', margin: '0 2px' }} />
                {row.original.scenarioName}
              </h5>
            )}
          </>
        ),
      },
      {
        Header: 'Total sessions',
        accessor: 'sessions',
      },
      {
        Header: 'Users',
        accessor: 'users',
      },
      {
        Header: 'Avg. session length',
        accessor: 'avgSessionLength',
        Cell: ({ row }) => <p>{formatSessionLength(row.original.avgSessionLength)}</p>,
      },
      {
        Header: 'Avg. skill level',
        accessor: 'avgSkillLevel',
      },
      {
        Header: () => (
          <div style={{ textAlign: 'center', fontWeight: '500', display: 'inline' }}>
            <QuestionCircle
              style={{ width: '16px', color: 'var(--grey-3)' }}
              data-for="visibility"
              data-tip="Setting the scenarios as </br> visible or hidden to the users."
            />
            <ReactTooltip id="visibility" multiline={true} html={true} />{' '}
            <span style={{ fontWeight: 'bold' }}>Visibility</span>{' '}
          </div>
        ),
        accessor: d => (d.visible === true ? 1 : 0),
        id: 'visible',
        Cell: VisibilityCell,
      },
      {
        Header: () => null,
        id: 'menu',
        Cell: ({ row }) => (
          <DotMenu>
            {row.original.visible === true && !row.original.error && (
              <button
                onClick={() => props.history.push('/scenarios/scenario/' + row.original.scenarioId)}
              >
                <Show /> <span>View</span>
              </button>
            )}
            {!row.original.error && (
              <button
                onClick={() =>
                  props.history.push(ROUTES.SCENARIO_EDITOR + '/' + row.original.scenarioId)
                }
              >
                <Edit /> <span>Edit</span>
              </button>
            )}
            {row.original.mandatory && row.original.mandatory <= 5 && (
              <button
                onClick={() =>
                  removeMandatoryFromClient(row.original.scenarioId, state.selectedClient)
                }
              >
                <DeleteForever /> <span>Remove priority</span>
              </button>
            )}
            <button
              onClick={() =>
                removeScenarioFromClient(row.original.scenarioId, state.selectedClient)
              }
            >
              <DeleteForever /> <span>Remove</span>
            </button>
          </DotMenu>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.selectedClient],
  );

  const clientsColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: cellProps => {
          if (cellProps.row.original.id === cellProps.clientToEdit) {
            return <EditableCell {...cellProps} />;
          } else {
            return (
              <h5>
                {cellProps.row.original.name} <br />
                <span style={{ fontSize: '12px', color: 'var(--grey-2)' }}>
                  {cellProps.row.original.id}
                </span>
              </h5>
            );
          }
        },
      },
      {
        Header: 'Logo',
        accessor: 'logo',
        disableSortBy: true,
        Cell: cellProps => {
          return (
            <LogoCell
              {...cellProps}
              clientId={cellProps.row.original.id}
              firebase={props.firebase}
              editing={cellProps.row.original.id === cellProps.clientToEdit}
            />
          );
        },
      },
      {
        Header: 'Domain',
        accessor: 'domain',
        Cell: cellProps => {
          if (cellProps.row.original.id === cellProps.clientToEdit) {
            return <EditableCell {...cellProps} />;
          } else {
            return <p>{cellProps.row.original.domain}</p>;
          }
        },
      },
      {
        Header: 'Total users',
        accessor: 'users',
      },
      {
        Header: () => null,
        id: 'menu',
        Cell: cellProps => (
          <>
            {!cellProps.clientToEdit && (
              <DotMenu>
                <button onClick={() => setClientToEdit(cellProps.row.original.id)}>
                  <Edit /> <span>Edit</span>
                </button>
              </DotMenu>
            )}

            {cellProps.clientToEdit === cellProps.row.original.id && (
              <>
                <PrimeButton cancel onClick={() => cancelEditing(cellProps.originalData)}>
                  Cancel
                </PrimeButton>
                <PrimeButton
                  disabled={
                    cellProps.row.original.name === '' || cellProps.row.original.domain === ''
                  }
                  onClick={() => saveEditedClient(cellProps.row, cellProps.clientToEdit)}
                >
                  Save
                </PrimeButton>
              </>
            )}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const sessionsColumn = useMemo(
    () => [
      {
        Header: 'Session date',
        accessor: 'date',
        Cell: ({ row }) => formatSessionDate(row.original.date),
      },
      {
        Header: 'Scenario',
        accessor: 'scenarioName',
      },
      {
        Header: 'Session length',
        accessor: 'sessionLength',
        Cell: ({ row }) => formatSessionLength(row.original.sessionLength),
      },
      // {
      //   Header: 'Skill level',
      //   accessor: 'skillLevel',
      // },
      {
        Header: 'Grading',
        Cell: ({ row }) => (
          <ScoreMeter scores={row.original.scores} exchangesCount={row.original.exchangesCount} />
        ),
      },
      {
        Header: () => null,
        accessor: 'view',
        Cell: ({ row }) => (
          <>
            {props.authUser &&
              (row.original.userId === props.authUser.userId ||
                (props.authUser.claims && props.authUser.claims.superAdmin)) && (
                <FrDiv alignItems={'center'} justifyContent={'flex-end'} width={'100%'}>
                  <FrButton
                    onClick={() =>
                      props.history.push(
                        `${ROUTES.SESSIONS}/${row.original.clientId}:${row.original.userId}:${row.original.sessionId}`,
                      )
                    }
                    padding={'6px 26px'}
                    background={'transparent'}
                    border={'2px solid var(--grey-2)'}
                    color={'var(--grey-2)'}
                    boxShadow={'transparent'}
                    disabled={
                      !row.original.clientId || !row.original.userId || !row.original.sessionId
                    }
                  >
                    View
                  </FrButton>
                </FrDiv>
              )}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const userColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }) => (
          <h5>
            {row.original.name} <br />
            <span style={{ fontSize: '12px', color: 'var(--grey-2)' }}>{row.original.id}</span>
          </h5>
        ),
      },
      {
        Header: 'Total sessions',
        accessor: 'sessions',
      },
      {
        Header: 'Last active',
        accessor: 'lastActive',
        Cell: ({ row }) => <p>{formatSessionDate(row.original.lastActive)}</p>,
      },
      {
        Header: 'Avg. session length',
        accessor: 'avgSessionLength',
        Cell: ({ row }) => <p>{formatSessionLength(row.original.avgSessionLength)}</p>,
      },
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) =>
          row.original.userSessions ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 2}rem`,
                },
              })}
            >
              {row.isExpanded ? (
                <ChevDown width={'20px'} color={'var(--grey)'} />
              ) : (
                <ChevRight width={'20px'} color={'var(--grey)'} />
              )}
            </span>
          ) : null,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Crud

  const getSessions = () => {
    props.firebase.clientSessions(state.selectedClient).once('value', snapshot => {
      const sessionsObject = snapshot.val();
      if (sessionsObject) {
        let clientSessions = [];
        Object.values(sessionsObject).forEach(userSession => {
          Object.keys(userSession).forEach(session => {
            clientSessions.push({ ...userSession[session], clientId: state.selectedClient });
          });
        });
        setAllClientSessions(clientSessions);
      } else {
        setAllClientSessions([]);
      }
    });
  };

  const updateVisibility = (value, scenarioId) => {
    props.firebase
      .newClient(state.selectedClient)
      .child('settings')
      .child('visible')
      .update({ [scenarioId]: value });
  };

  const updateMandatory = (value, scenarioId) => {
    props.firebase
      .newClient(state.selectedClient)
      .child('settings')
      .child('mandatory')
      .update({ [scenarioId]: value });
  };

  const addScenarioToClient = scenarioId => {
    props.firebase
      .newClient(state.selectedClient)
      .child('settings')
      .child('visible')
      .update(
        {
          [scenarioId]: false,
        },
        err => {
          if (err) {
            console.error(err);
          } else {
            setSelectedScenario('');
          }
        },
      );
  };

  const AddNewClientToDB = () => {
    setAddClientError(null);
    if (clientDomain) {
      let generateId = clientDomain
        .replace(/\s+/g, '-')
        .replace(/[|<>"?*:\\.$[\]#/@]/g, '')
        .toLowerCase();
      if (state.clients.filter(client => client.id === generateId).length === 0) {
        props.firebase.newClients().update(
          {
            [generateId.toLowerCase()]: {
              domain: clientDomain.toLowerCase(),
              logo: '',
              name: clientName,
            },
          },
          err => {
            if (err) {
              setAddClientError(err);
            } else {
              setClientDomain('');
              // setClientLogoUrl('');
              setClientName('');
            }
          },
        );
      } else {
        setAddClientError('Client with id: ' + generateId + ' already exists');
      }
    }
  };

  const removeMandatoryFromClient = (id, client) => {
    props.firebase
      .newClient(client)
      .child('settings')
      .child('mandatory')
      .update({ [id]: null });
  };

  const removeScenarioFromClient = (id, client) => {
    removeMandatoryFromClient(id, client);
    props.firebase
      .newClient(client)
      .child('settings')
      .child('visible')
      .update({ [id]: null });
  };

  const saveEditedClient = (row, clientToEdit) => {
    if (row.values.logo) {
      props.firebase
        .clientLogo(clientToEdit, 'logo.png')
        .put(row.values.logo)
        .then(() => {
          props.firebase.newClient(clientToEdit).update(
            {
              domain: row.values.domain,
              name: row.values.name,
            },
            () => {
              setClientToEdit(null);
            },
          );
        });
    } else {
      props.firebase.newClient(clientToEdit).update(
        {
          domain: row.values.domain,
          name: row.values.name,
        },
        err => {
          if (err) {
            console.error(err);
          } else {
            setClientToEdit(null);
          }
        },
      );
    }
  };

  const cancelEditing = originalData => {
    setClientsData(originalData);
    setClientToEdit(null);
  };

  // Formatting / filtering data

  const averageSkillLevel = (sessions, exchanges) => {
    let totScore = 0;
    let sessionsWithTotScore = sessions.filter(s => parseInt(s.exchangesChanged) > 0);
    if (sessionsWithTotScore.length > 0) {
      sessionsWithTotScore.forEach(session => {
        totScore += parseInt(session.total_score);
      });
      return (
        (totScore / sessionsWithTotScore.length / (exchanges * 20)).toFixed(0) +
        ' / ' +
        exchanges * 20
      );
    } else {
      return '0';
    }
  };

  // Map react table data

  const mapScenariosData = (users, scenarios) => {
    if (users && scenarios && scenarios.length >= 1) {
      const mappedScenarios = scenarios.map(scenario => {
        let scenarioSessions = [];
        scenarioSessions = allClientSessions.filter(session => session.scenario_id === scenario.id);
        let scenarioUsers = users.filter(user => user.scenarios && user.scenarios[scenario.id]);
        return {
          mandatory: scenario.mandatory >= 1 ? scenario.mandatory : 6,
          scenarioName:
            scenario && scenario.settings && scenario.settings.scenarioName
              ? scenario.settings.scenarioName
              : scenario.id,
          sessions: scenarioSessions.length,
          users: scenarioUsers ? scenarioUsers.length : 0,
          avgSessionLength:
            scenarioSessions.length >= 1 ? averageSessionLength(scenarioSessions) : 0,
          avgSkillLevel:
            scenario && scenario.settings && scenario.settings.exchangesCount
              ? averageSkillLevel(scenarioSessions, scenario.settings.exchangesCount)
              : '-',
          visible: scenario.visible === true ? true : false,
          scenarioId: scenario.id,
          googleSheet: scenario.googleSheet,
          error: scenario.error,
        };
      });
      setScenariosData(mappedScenarios);
    } else {
      setScenariosData([]);
    }
  };

  const mapUsersData = (users, sessions) => {
    if (users && users.length >= 1) {
      const mappedUsers = users.map(user => {
        const userSessions = sessions.filter(session => session.user_id === user.id);
        return {
          name: user.displayName,
          id: user.id,
          sessions: userSessions && userSessions.length >= 1 ? userSessions.length : 0,
          lastActive:
            userSessions && userSessions.length >= 1
              ? userSessions[userSessions.length - 1].date
              : null,
          avgSessionLength:
            userSessions && userSessions.length >= 1 ? averageSessionLength(userSessions) : 0,
          userSessions:
            userSessions && userSessions.length >= 1
              ? mapSessionsData(userSessions, state.scenarios, user)
              : null,
          subRows: undefined,
        };
      });
      setUsersData(mappedUsers);
    } else {
      setUsersData([]);
    }
  };

  const mapClientsData = () => {
    if (state.clients && state.clients.length >= 1) {
      const mappedClients = state.clients.map(client => {
        let clientUsers = state.users.filter(user => user.clients && user.clients[client.id]);
        return {
          name: client.name,
          id: client.id,
          logo: null,
          domain: client.domain ? client.domain : '',
          users: clientUsers ? clientUsers.length : 0,
        };
      });
      setOriginalClients(mappedClients);
      setClientsData(mappedClients);
    } else {
      setOriginalClients(null);
      setClientsData(null);
    }
  };

  const publishedScenarios = scenarios => {
    return scenarios.filter(scen => scen.visible === true).length;
  };

  // Lifecycle hooks

  useEffect(() => {
    if (props.authUser && !props.authUser.claims) {
      props.history.push(HOME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.selectedClient && !state.loading) {
      getSessions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedClient, state.loading]);

  useEffect(() => {
    if (state.users && state.selectedClient && allClientSessions) {
      mapUsersData(
        state.users.filter(user => user.clients && user.clients[state.selectedClient]),
        allClientSessions,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.users, state.selectedClient, allClientSessions]);

  useEffect(() => {
    if (state.clients && state.users) {
      mapClientsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.clients, state.users]);

  useEffect(() => {
    if (state.users && allClientSessions && state.scenarios) {
      if (props.authUser && props.authUser.claims && props.authUser.claims.superAdmin) {
        mapScenariosData(
          state.users.filter(user => user.clients && user.clients[state.selectedClient]),
          state.scenarios.filter(scenario => scenario.visible !== null),
        );
      } else {
        mapScenariosData(
          state.users.filter(user => user.clients[state.selectedClient]),
          state.scenarios,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allClientSessions, state.users, state.scenarios]);

  const renderRowSubComponent = useCallback(
    ({ row }) => (
      <InnerDataTable>
        <DataTable
          columns={sessionsColumn}
          data={row.original.userSessions}
          initialSort={[{ id: 'date', desc: true }]}
        />
      </InnerDataTable>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <AdminContainer>
      <AdminContentWrapper>
        <PageTitle withDate={true} title={'Admin'} />
        <AdminNavigation />

        <Route path={ROUTES.SCENARIO_EDITOR + '/:id?'} component={ScenarioEditor} />

        {props.location.pathname === '/admin' && (
          <>
            {state.scenarios && (
              <FrDiv justifyContent={'flex-start'} align-content={'flex-start'} flexWrap={'wrap'}>
                <StatsBox color={'var(--key-color-2-gradient)'}>
                  <Users />
                  <div>
                    <h2>
                      {state.selectedClient && state.users
                        ? state.users.filter(
                            user => user.clients && user.clients[state.selectedClient],
                          ).length
                        : '-'}
                    </h2>
                    <h4>active users</h4>
                  </div>
                </StatsBox>
                <StatsBox color={'var(--red-gradient)'}>
                  <Bubbles2 />
                  <div>
                    <h2>
                      {allClientSessions && allClientSessions.length
                        ? allClientSessions.length
                        : '0'}
                    </h2>
                    <h4>total sessions</h4>
                  </div>
                </StatsBox>
                <StatsBox color={'var(--green-gradient)'}>
                  <StatsBars />
                  <div>
                    <h2>{state.scenarios && publishedScenarios(state.scenarios)}</h2>
                    <h4>published scenarios</h4>
                  </div>
                </StatsBox>
                <StatsBox color={'var(--yellow-gradient)'}>
                  <StatsBars />
                  <div>
                    <h2>
                      {allClientSessions &&
                        formatSessionLength(averageSessionLength(allClientSessions))}
                    </h2>
                    <h4>avg. session length</h4>
                  </div>
                </StatsBox>
              </FrDiv>
            )}

            {/* CLIENTS */}
            {props.authUser &&
              props.authUser.claims &&
              props.authUser.claims.superAdmin &&
              clientsData && (
                <AdminTableWrapper>
                  <TableHeader>
                    Clients
                    {clientsData && <span>{clientsData.length + ' total'}</span>}
                  </TableHeader>

                  {clientsData.length >= 1 && (
                    <TableScrollWrapper>
                      <DataTable
                        columns={clientsColumns}
                        data={clientsData}
                        initialSort={[{ id: 'name', desc: false }]}
                        updateData={updateData}
                        clientToEdit={clientToEdit}
                        originalData={originalClients}
                        style={{ height: '300px' }}
                      />
                    </TableScrollWrapper>
                  )}

                  <NewClientWrapper>
                    <input
                      placeholder={'Enter client name'}
                      type="text"
                      onChange={e => setClientName(e.target.value)}
                    ></input>
                    {/* <input
                      placeholder={'Enter logo URL'}
                      type="text"
                      onChange={e => setClientLogoUrl(e.target.value)}
                    ></input> */}
                    <input
                      placeholder={'Enter client domain (example.com)'}
                      type="text"
                      onChange={e => setClientDomain(e.target.value)}
                    ></input>
                    <button disabled={addNewClientValidation} onClick={() => AddNewClientToDB()}>
                      Add new client
                    </button>
                  </NewClientWrapper>
                  {addClientError && (
                    <div
                      style={{ padding: '10px', background: 'rgba(255,68,68,.3)', margin: '5px 0' }}
                    >
                      <p style={{ padding: '5px 0' }}>{addClientError}</p>
                    </div>
                  )}
                </AdminTableWrapper>
              )}

            {/* SCENARIOS */}
            {scenariosData && (
              <AdminTableWrapper>
                <TableHeader>
                  Published Scenarios
                  {scenariosData && (
                    <span>
                      {scenariosData.filter(scen => scen.visible === true).length + ' total'}
                    </span>
                  )}
                </TableHeader>

                {scenariosData.length >= 1 && (
                  <TableScrollWrapper>
                    <DataTable
                      updateMandatory={updateMandatory}
                      updateVisibility={updateVisibility}
                      columns={scenarioColumns}
                      data={scenariosData.filter(scen => scen.visible === true)}
                      initialSort={[{ id: 'mandatory', desc: false }]}
                    />
                  </TableScrollWrapper>
                )}

                <TableHeader margin={'30px 0 0'}>
                  Unpublished Scenarios
                  {scenariosData && (
                    <span>{scenariosData.filter(scen => !scen.visible).length + ' total'}</span>
                  )}
                </TableHeader>

                {scenariosData.length >= 1 && (
                  <TableScrollWrapper>
                    <DataTable
                      updateMandatory={updateMandatory}
                      updateVisibility={updateVisibility}
                      columns={scenarioColumns}
                      data={scenariosData.filter(scen => !scen.visible)}
                      initialSort={[{ id: 'mandatory', desc: false }]}
                    />
                  </TableScrollWrapper>
                )}

                {state.selectedClient && (
                  <AddScenarioContainer>
                    {props.authUser && props.authUser.claims && props.authUser.claims.superAdmin && (
                      <AddScenarioWrapper>
                        <select
                          onChange={e => setSelectedScenario(e.target.value)}
                          value={selectedScenario}
                        >
                          <option value="">Choose scenario</option>
                          {state.scenarios &&
                            state.scenarios
                              .filter(scenario => scenario.visible === null)
                              .map((scenario, index) => (
                                <option value={scenario.id} key={index}>
                                  {scenario.id}
                                </option>
                              ))}
                        </select>
                        <PrimaryBtn
                          disabled={selectedScenario === ''}
                          onClick={() => addScenarioToClient(selectedScenario)}
                        >
                          Add
                        </PrimaryBtn>
                      </AddScenarioWrapper>
                    )}
                    <PrimaryBtn onClick={() => props.history.push(ROUTES.SCENARIO_EDITOR)}>
                      Create New Scenario
                      <Plus />
                    </PrimaryBtn>
                  </AddScenarioContainer>
                )}
              </AdminTableWrapper>
            )}

            {!scenariosData && (
              <Loading
                loadingText={LoadingMsg.SCENARIOS}
                width={'80px'}
                containerHeight={'400px'}
              />
            )}

            {/* USERS */}
            {usersData && (
              <AdminTableWrapper>
                <TableHeader>
                  Users
                  <span>{usersData.length + ' total'}</span>
                </TableHeader>
                {usersData.length >= 1 && (
                  <TableScrollWrapper>
                    <DataTable
                      columns={userColumns}
                      initialSort={[{ id: 'name', desc: false }]}
                      data={usersData}
                      renderRowSubComponent={renderRowSubComponent}
                    />
                  </TableScrollWrapper>
                )}
              </AdminTableWrapper>
            )}
          </>
        )}
      </AdminContentWrapper>
    </AdminContainer>
  );
};

// Custom react table cells
const MandatoryCell = ({ value: initialValue, row, updateMandatory }) => {
  const [value, setValue] = useState(initialValue);
  const [loading, setLoading] = useState(false);

  const onChange = e => {
    if (e.target.value >= 1 && e.target.value <= 5) {
      setValue(e.target.value);
    }
  };

  useEffect(() => {
    setValue(initialValue);
    if (loading) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const save = () => {
    updateMandatory(value, row.original.scenarioId);
    setLoading(true);
  };

  return (
    <>
      {loading ? (
        <Loading width={'30px'} containerHeight={'40px'} />
      ) : (
        <MandatorySelect>
          {initialValue && initialValue <= 5 ? (
            <input
              min="1"
              max="5"
              step="1"
              value={value}
              onChange={e => onChange(e)}
              type="number"
            />
          ) : (
            <VisibilityButton onClick={() => updateMandatory(5, row.original.scenarioId)}>
              Priority
            </VisibilityButton>
          )}
          {initialValue && initialValue !== value && (
            <SaveMandatory onClick={() => save()}>save</SaveMandatory>
          )}
        </MandatorySelect>
      )}
    </>
  );
};

const VisibilityCell = ({ value: initialValue, row, updateVisibility }) => {
  const [value, setValue] = useState(initialValue);

  const toggleHidden = () => {
    setValue(!value);
    updateVisibility(!value, row.original.scenarioId);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
      }}
    >
      <VisibilityButton isHidden={value} onClick={() => toggleHidden()}>
        {value ? 'Published' : 'Unpublished'}
      </VisibilityButton>
    </div>
  );
};

const DotMenu = props => {
  const menu = useRef(null);
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);

  useEffect(() => {
    const handleClickOutside = event => {
      if (menu && !menu.current.contains(event.target)) {
        setShowOptionsMenu(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    window.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, []);

  return (
    <MenuContainerFixed ref={menu} onClick={() => setShowOptionsMenu(!showOptionsMenu)}>
      <Dots />
      {showOptionsMenu && <Options>{props.children}</Options>}
    </MenuContainerFixed>
  );
};

const EditableCell = ({ value: initialValue, row: { index }, column: { id }, updateData }) => {
  const [value, setValue] = useState(initialValue);

  const onChange = e => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    updateData(index, id, value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return <EditClientInput value={value} onChange={onChange} onBlur={onBlur} />;
};

const LogoCell = ({ row: { index }, column: { id }, clientId, firebase, updateData, editing }) => {
  const [clientLogo, setClientLogo] = useState(null);
  const [clLogo, setClLogo] = useState(null);

  useEffect(() => {
    if(clientId) {
      if (editing) {
        setClientLogo(clLogo);
        setClLogo(null);
      } else {
        getClientLogo(clientId).then(res => {
          if (res) {
            setClLogo(res);
          } else {
            setClLogo('');
          }
        });
      }
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing, clientId]);

  const getClientLogo = async clientId => {
    return await firebase
      .clientLogo(clientId, 'logo.png')
      .getDownloadURL()
      .then(url => {
        return url;
      })
      .catch(() => {
        return '';
      });
  };

  // const removeClientLogo = () => {
  //   firebase
  //     .clientLogo(clientId, 'logo.png')
  //     .delete()
  //     .then(() => {
  //       setClientLogo(null);
  //     })
  //     .catch(err => {
  //       console.error(err);
  //     });
  // };

  const onChange = pngFile => {
    if (pngFile) {
      let blob = window.URL || window.webkitURL;
      setClientLogo(blob.createObjectURL(pngFile));
      updateData(index, id, pngFile);
    }
  };

  return (
    <FrDiv justifyContent={'flex-start'}>
      {/* {noLogo && <LogoDiv>No image</LogoDiv>} */}
      {!editing && clLogo && <LogoDiv url={clLogo}></LogoDiv>}
      {clientLogo && editing && <LogoDiv url={clientLogo}></LogoDiv>}
      {editing && (
        <FrFileInputWrapper
          data-for="upload"
          data-tip="Format: .PNG, Preferrably 1024x1024 with no transparency"
        >
          <input type="file" accept=".png" onChange={e => onChange(e.target.files[0])} />
          <CloudUpload />
          <span>Upload new image</span>
          <ReactTooltip style={{ maxWidth: '100px' }} id="upload" multiline={true} html={true} />
        </FrFileInputWrapper>
      )}
      {/* {clientLogo && editing && (
        <PrimeButton onClick={() => removeClientLogo()}>Delete</PrimeButton>
      )} */}
    </FrDiv>
  );
};

const condition = authUser =>
  authUser && (authUser.claims.clientAdmin || authUser.claims.superAdmin);

export default compose(withAuthorization(condition), withFirebase)(Admin);
