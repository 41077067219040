import styled from 'styled-components';
import { smoothIn } from '../../styles/keyframes';

export const DialogueEditorContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  animation: ${smoothIn} 0.6s;
`;

// Settings
export const SettingsContainer = styled.div`
  width: 100%;
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  background: white;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  ${'' /* background: var(--grey-4); */}
  ${'' /* overflow: hidden; */}
  border-radius: 20px;

  h5 {
    width: 100%;
    text-align: left;
    padding: 0 10px 5px;
  }

  select {
    font-weight: 500;
    padding: 9px 20px;
    background: var(--grey-5);
  }
`;

// NEW EXCHANGE
export const SaveButton = styled.button`
  width: 100%;
  padding: ${props => (props.padding ? props.padding : '15px')};
  border: none;
  background: linear-gradient(269.98deg, #a491fd 0.01%, #7f65ff 99.98%);
  color: white;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  transition: 0.4s;
  border-radius: 20px;
  margin: 1em 0;

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
`;

export const ExchangeCont = styled.div`
  margin: 5px;
  transition: 0.6s;
`;

export const AddNewStatementWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: flex-end;
  justify-content: flex-end;
  padding: 0 40px;

  button {
    border: none;
    outline: none;
    border-radius: 20px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 8px 36px;
    background: linear-gradient(93.41deg, #59d8b9 1.21%, #89e1cc 95.08%);

    h5 {
      color: white;
      font-size: 14px;
      font-weight: bold;
      margin-left: 4px;
    }

    svg {
      margin-right: 4px;
      color: white;
      width: 20px;
    }
  }

  @media (max-width: 768px) {
    align-content: center;
    justify-content: center;
  }
`;

export const ExchangeWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 20px;
  margin: 10px 0;
  ${'' /* border-radius: 20px; */}
  ${'' /* background: white; */}
  border-bottom: 1px solid var(--grey-2);
  border-top: ${props => (props.index === 0 ? ' 1px solid var(--grey-2)' : 'none')};
  ${'' /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07); */}
  ${'' /* border: 2px solid var(--grey-5); */}
  opacity: 0;
  animation: ${smoothIn} 0.6s;
  animation-fill-mode: forwards;
`;

export const CancelNewExchange = styled.button`
  position: absolute;
  background: transparent;
  border: none;
  outline: none;
  right: 10px;
  top: 10px;
  z-index: 1;

  svg {
    width: 30px;
    color: var(--grey-1);
    transition: 0.4s;
  }

  &:hover {
    svg {
      color: var(--status-red);
      transition: 0.4s;
    }
  }
`;

export const Statement = styled.div`
  position: relative;
  width: ${props => (props.small ? '40%' : '70%')};
  max-width: 650px;
  min-width: 300px;
  animation: ${smoothIn} 0.6s;
  border-radius: ${props => (props.bot ? '30px 30px 30px 0' : '30px 30px 0 30px')};
  background: ${props => (props.background ? props.background : 'var(--grey-4)')};
  position: relative;
  margin: 1em 0;

  textarea {
    resize: none;
    padding: 1em;
    font-weight: 500;
    border: none;
    width: 100%;
    border-radius: 20px;
    outline: none;
  }
`;

export const SmallWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.question === true ? 'row-reverse' : 'row')};
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding: 16px;
  width: 100%;

  p {
    font-weight: 500;
    padding: 0 10px;
    max-width: 500px;
    font-size: 14px;
  }
`;

export const NewExchangeWrapper = styled.div`
  padding: 2em;
  text-align: center;

  select {
    font-weight: 500;
  }

  input {
    padding: 1em;
    width: 100%;
    font-weight: 500;
    border: none;
    border-radius: 20px;
    outline: none;
  }

  input[type='file'] {
    display: none;
  }
`;

export const RowWrapper = styled.div`
  animation: ${smoothIn} 0.6s;
  display: flex;
  flex-direction: ${props => (!props.question ? 'row' : 'row-reverse')};
  align-items: center;
  justify-contet: space-between;
  width: 100%;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '20px')};
  background: ${props => (props.background ? props.background : 'none')};
  padding: ${props => (props.padding ? props.padding : '0')};
`;

// Checkbox input
export const CheckboxInputContainer = styled.div`
  display: flex;
  align-items: center;
  align-contet: center;
  justify-content: center;
  padding: 0 10px;
  p {
    font-size: 12px;
    font-weight: bold;
  }

  input {
    margin-left: 10px;
    width: initial;
  }
`;

export const NewExchangeCharTitle = styled.h5`
  position: absolute;
  top: -22px;
  left: ${props => (props.bot === true ? '10px' : 'initial')};
  right: ${props => (props.bot === true ? 'initial' : '10px')};
  color: ${props => (props.color ? props.color : 'var(--grey-1)')};
  font-size: 14px;
`;

// AUDIO INPUT
export const FileInputContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  transition: 0.4s;
  div {
    width: 100%;
    padding: 0 0 5px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: flex-start;

    label {
      width: 50%;
      margin: 0 2px 0 0;
      border-radius: 20px;
      background: var(--key-color-2);
      color: white;
      text-align: left;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      font-weight: bold;
      cursor: pointer;
      font-size: 12px;
      padding: 2px 20px;

      svg {
        color: white;
        width: 30px;
        margin-left: 5px;
      }
    }

    button {
      width: 50%;
      height: 34px;
      margin: 0 0 0 2px;
      padding: 5px;
      font-size: 12px;
      font-weight: bold;
      border-radius: 20px;
      border: 2px solid var(--key-color-2);
      background: transparent;
      color: var(--key-color-2);
    }
  }

  audio {
    width: 100%;
    outline: none;
    padding: 5px 0;
  }

  p {
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
  }

  @media (max-width: 768px) {
    div {
      flex-direction: column;
      label {
        width: 100%;
        margin: 2px;
      }
      button {
        width: 100%;
        margin: 2px;
      }
    }
  }
`;

// CHAR SETTINGS
export const SettingsWrapper = styled.div`
  flex-basis: 50%;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${props => (props.padding ? props.padding : '0')};
  flex-wrap: wrap;

  @media (max-width: 1300px) {
    padding: 15px;
  }
`;

export const CharPosContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  ${'' /* align-items: center;
  align-content: center;
  justify-content: center; */}
  margin-top: 1em;

  ${'' /* @media (min-width: 1500px) {
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-contet: center;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-contet: center;
  } */}
`;

export const ImagePosContainer = styled.div`
  display: flex;
  align-items: ${props => (props.noImage ? 'center' : 'flex-start')};
  justify-content: center;
  align-content: center;
  width: ${props => (props.width ? props.width : 'inital')};
  min-width: ${props => (props.minWidth ? props.minWidth : 'inital')};
  min-height: ${props => (props.minHeight ? props.minHeight : 'inital')};
  border: ${props => (props.noImage ? '2px dashed var(--grey-3)' : 'none')};
  background: ${props =>
    props.noImage ? 'var(--grey-5)' : props.background ? props.background : 'transparent'};
  padding: ${props => (props.padding ? props.padding : 'inital')};
  border-radius: 20px;

  h5 {
    color: var(--grey-2);
    text-align: center;
    padding: 0;
  }

  img  {
    transition: 0.4s;
    width: 100%;
    border-radius: 20px;
    transition: 0.4s;
  }
`;

export const CharWrapper = styled.div`
  flex: 1 1 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 5px 0;
  border-radius: 10px;

  h5 {
    border-radius: 20px;
    width: 100%;
    text-align: left;
  }
`;

//Edit dialogue
export const StatementButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  font-weight: bold;
  cursor: pointer;
  border-radius: 20px;
  font-size: 12px;
  &:disabled {
    pointer-events: none;
    svg {
      opacity: 0.3;
    }
  }
`;

export const PlayerButtonWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  min-width: 40px;
  min-height: 40px;
`;

export const PlayerButton = styled.button`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: none;
  outline: none;
  background: ${props => (props.isPlayer ? 'white' : 'var(--grey-2)')};
  font-weight: bold;
  transition: 0.4s;
  position: relative;
  animation: ${smoothIn} 0.4s;

  svg {
    transition: 0.4s;
    width: 80%;
    color: ${props => (props.isPlayer ? 'var(--key-color-2)' : 'white')};
  }

  &:disabled {
    background: var(--grey-4);
    opacity: 0.6;
    pointer-events: none;

    svg {
      transition: 0.4s;
      width: 100%;
      color: var(--grey-3);
    }
  }
`;

export const SaveStatementButton = styled.button`
  width: 100%;
  padding: 10px;
  border: none;
  ${'' /* background: ${props => (props.question ? 'var(--orange)' : 'var(--teal)')}; */}
  background: var(--key-color-2-gradient);
  color: white;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: ${props => (props.question ? '0px 0 30px 0' : '0px 0 0px 30px')};

  :disabled {
    pointer-events: none;
    background: var(--key-color-2-gradient);
    opacity: 0.6;
  }
`;

export const ResetStatementButton = styled.button`
  position: absolute;
  background: transparent;
  right: 10px;
  top: 10px;
  border: none;
  outline: none;
  transition: 0.4s;
  font-weight: bold;
  color: var(--grey-1);

  svg {
    width: 30px;
    color: var(--grey-1);
  }
`;

export const SelectScenarioContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;
  padding: 10px 0;
  ${'' /* background: var(--grey-4); */}
  background: white;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  transition: 0.6s;
  animation: ${smoothIn} 0.4s;
  font-size: 14px;
  flex-wrap: wrap;

  svg {
    margin: 0 10px;
    color: var(--grey);
    width: 40px;
  }

  h5 {
    font-size: 14px;
  }

  select {
    font-weight: 600;
  }
`;

export const SelectScenarioWrapper = styled.div`
  flex-basis: 400px;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  position: relative;

  input {
    min-width: 250px;
  }

  select {
    min-width: 250px;
    margin: 0 10px;
    font-weight: 500;
    padding: 9px 20px;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    align-content: flex-end;
    justify-content: flex-end;
    input {
      margin: 10px 0;
    }
    select {
      margin: 10px 0;
    }

    label {
      width: 100%;
      text-align: left;
    }

    h5 {
      position: absolute;
      bottom: 35px;
      left: 40px;
      color: var(--grey-1);
      font-size: 12px;
    }
  }
`;

export const CopyScenarioId = styled.h5`
    position: absolute;
    bottom: -7px;
    left: 150px;
    color: var(--grey-1);
    font-size: 12px;
  @media (max-width: 768px) {
    position: absolute;
    bottom: 35px;
    left: 40px;
  }
`;

export const CopyScenarioBtn = styled.button`
  padding: 9px 20px;
  border: none;
  border-radius: 20px;
  font-weight: bold;
  background: var(--key-color-2);
  color: white;

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`;

export const ErrorMsg = styled.span`
  padding: 0 5px;
  font-size: 12px;
  font-weight: 600;
  color: var(--red);
  width: ${props => (props.width ? props.width : '')};
`;

export const SheetInput = styled.input`
  border: none;
  padding: 10px;
  min-width: 300px;
  border-radius: 10px;
  margin-right: 10px;
  font-weight: 500;
  outline: none;
`;

export const Saved = styled.h4`
  background: var(--key-color-2);
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  animation: ${smoothIn} 0.6s;
  padding: 5px;
  svg {
    color: var(--green);
    width: 30px;
  }
`;

export const ScenarioInfoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 5px 10px;
  p:first-child {
    margin: 0 5px 0 0;
  }
  p {
    font-size: 14px;
    margin: 0 5px 0 0;
    font-weight: 600;
    span {
      font-weight: bold;
    }
  }
  @media (max-width: 1300px) {
    flex-wrap: wrap;
  }
`;

export const AutogeneratedText = styled.div`
  position: absolute;
  font-size: 10px;
  right: 0;
  color: var(--grey-2);
`;

export const ScoreTab = styled.div`
  position: absolute;
  background: white;
  color: ${props => (props.color ? props.color : 'var(--grey-2)')};
  padding: 5px 10px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
  font-weight: bold;
  font-size: 12px;
  border-radius: 20px;
  z-index: 2;
  left: 34px;
  bottom: -14px;
  display: flex;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;

  svg {
    margin-right: 5px;
    color: ${props => (props.color ? props.color : 'var(--grey-3)')};
    width: 15px;
  }
`;

export const SkippedAnswer = styled.p`
  width: 100%;
  text-align: center;
  padding: 8px 20px;
  font-weight: 500;
  font-size: 14px;
  color: var(--grey-2);
`;

export const ExpandButtonContainer = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  align-content: center;
  justifycontent: center;
  max-width: 650px;
  min-width: 300px;
  margin-bottom: 16px;
`;

export const ExpandButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  width: 50%;
  border: none;
  padding: 8px 10px 8px 20px;
  font-size: 12px;
  font-weight: 600;
  color: var(--grey-1);
  margin: 0 5px;
  background: ${props => (props.show ? 'var(--grey-4)' : 'var(--grey-5)')};

  svg {
    width: 25px;
    transition: 0.4s;
    color: var(--grey-1);
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 5px;
      color: ${props => (props.bestAnswer ? 'var(--status-yellow)' : 'var(--grey-2)')};
    }
  }

  :disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`;

export const CollapsibleContainer = styled.div`
  ${'' /* overflow: hidden; */}
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  borderradius: 20px;
  transition: 0.6s;
  transition: max-height 600ms ease 0s, opacity 600ms ease 0s, height 600ms ease 0s;
  opacity: ${props => (props.show ? '1' : '0')};
  overflow: ${props => (props.show ? 'unset' : 'hidden')};
  max-height: ${props => (props.show ? 'unset' : '0px')};
  min-height: ${props => (props.show ? '0px' : 'unset')};
  transform: 0.6s;
`;

export const Transcript = styled.p`
  color: ${props => (props.color ? props.color : 'white')};
  fontstyle: ${props => (props.noTranscript ? 'italic' : 'inital')};
  ::first-letter {
    text-transform: uppercase;
  }
`;

export const MoveExchangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: white;
  margin: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  overflow: hidden;

  button {
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: 0.4s;
    background: transparent;

    &:hover {
      background: var(--grey-5);
      transition: 0.4s;
    }

    svg {
      width: 30px;
      color: var(--grey);
    }

    :disabled {
      pointer-events: none;
      svg {
        color: var(--grey-4);
      }
    }
  }
`;

export const DeleteExchangeBtn = styled.button`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  left: 5px;
  outline: none;
  border: none;
  position: absolute;
  bottom: -60px;
  cursor: pointer;
  transition: 0.4s;
  padding: 5px;
  border-radius: 50%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07);
  background: white;

  svg {
    width: 20px;
    color: var(--grey);
    transition: 0.4s;
  }

  &:hover {
    svg {
      transition: 0.4s;
      color: var(--status-red);
    }
  }
`;

// DIALOGUE TITLE
export const DialogueTitle = styled.h2`
  width: 100%;
  margin: 24px 0 12px 0;
`;

// DIALOGUE CONTAINER
export const DialogueContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  z-index: 1;
  padding: 0 32px 20px;
  position: relative;
  margin-bottom: 40px;
`;

// DIALOGUE MENU STYLING
export const DialogueMenuUL = styled.ul`
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  padding: 0 0 0 15px;

  li:first-child {
    margin: 0 4px 0 0;
  }

  @media (max-width: 768px) {
    align-content: center;
    justify-content: center;
    padding: 0;
  }
`;

export const ListItem = styled.li`
  margin: 0 4px 0 0;
  font-weight: bold;
  padding-bottom: 10px;
  min-width: 120px;
  text-align: center;
  cursor: pointer;
  box-shadow: ${props =>
    props.selected ? '0px -1px 7px rgba(0, 0, 0, 0.1)' : '0px 0px 7px rgba(0, 0, 0, 0.1)'};
  color: ${props => (props.selected ? 'var(--key-color-2)' : 'var(--grey-3)')};
  pointer-events: ${props => (props.selected ? 'none' : 'initial')};
  z-index: ${props => (props.selected ? '1' : 'initial')};
  background: white;
  transition: 0.4s;
  padding: 8px 24px;
  border-radius: 20px 20px 0 0;
  &:hover {
    color: var(--key-color-2);
  }
`;

// DESCRIPTION STYLING
export const DescriptionContainer = styled.div`
  width: 100%;
  padding: 24px 0;
  p {
    max-width: 900px;
    font-weight: 500;
    color: var(--grey);
    width: 100%;
    font-size: 14px;
  }
`;
