import React, { useEffect, useState, useRef } from 'react';
import { Meter } from 'grommet';
import { withFirebase } from '../../components/Firebase';
import { withRouter } from 'react-router-dom';
import { Archive } from 'styled-icons/boxicons-regular/Archive';
import { Edit } from 'styled-icons/boxicons-regular/Edit';
import { compose } from 'recompose';
import { Dots } from '../../styles/icons';
import { ErrorCircle } from 'styled-icons/boxicons-regular/ErrorCircle';
import * as ROUTES from '../../constants/routes';
import { coverImageURL } from '../../helpFunctions/storageImgUrls';
import {
  ListItemWrapper,
  ItemContainer,
  ScenarioImageE,
  Nametext,
  InfoContainer,
  SmallText,
  TimesPlayedContainer,
  TimesPlayedWrapper,
  TimesPlayedDots,
  MenuContainerFixed,
  ListItemContainer,
  Options,
  NoImageDiv,
  MeterWrapper,
} from './style';

const ScenarioFollowUp = ({
  scenario,
  history,
  firebase,
  userId,
  full,
  medium,
  archived,
  authUser,
}) => {
  // const [sessionsOpen, setSessionsOpen] = useState(false);
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);
  const menuContainer = useRef(null);
  const [loading, setLoading] = useState(true);

  const enterScenario = () => {
    history.push('/scenarios/scenario/' + scenario.id);
  };

  const archiveScenario = () => {
    let dateObj = new Date();
    firebase
      .newUser(userId)
      .child('scenarios')
      .child(scenario.id)
      .update({ archived: dateObj.toISOString() });
  };

  const unArchiveScenario = () => {
    firebase
      .newUser(userId)
      .child('scenarios')
      .child(scenario.id)
      .child('archived')
      .remove();
  };

  useEffect(() => {
    if (full) {
      const handleClickOutside = event => {
        if (
          menuContainer &&
          menuContainer.current &&
          !menuContainer.current.contains(event.target)
        ) {
          setShowOptionsMenu(false);
        }
      };
      window.addEventListener('mousedown', handleClickOutside, true);
      return () => {
        window.removeEventListener('mousedown', handleClickOutside, true);
      };
    }
  }, [full]);

  useEffect(() => {
    if (scenario) {
      setLoading(false);
    }
  }, [scenario]);

  const renderTimesPlayed = timesPlayed => {
    let timesPlayedArr = [];
    if(timesPlayed < 7) {
      for (let i = 0; i < timesPlayed; i++) {
          timesPlayedArr.push(<TimesPlayedDots key={i}></TimesPlayedDots>);
      }
    } else {
      timesPlayedArr.push(<TimesPlayedDots key={0}></TimesPlayedDots>)
    }
    return timesPlayedArr;
  };

  return (
    <>
      {full && (
        <ListItemContainer>
          <FullScenarioFollowUp
            scenarioImage={
              scenario.settings && scenario.settings.coverImage
                ? scenario.settings.coverImage
                : 'relaxed_vr.png'
            }
            enterScenario={enterScenario}
            renderTimesPlayed={renderTimesPlayed}
            unArchiveScenario={unArchiveScenario}
            scenario={scenario}
            authUser={authUser}
            loading={loading}
          />

          {/* {scenario.sessions &&
            scenario.sessions.filter(session => session.exchangesChanged > 0).length >= 1 &&
            sessionsOpen && (
              <SessionListWidget sessions={scenario.sessions} maxSessions={3} inScenario={true} />
            )} */}

          {scenario && !scenario.error && !loading && (
            <MenuContainerFixed
              ref={menuContainer}
              onClick={() => setShowOptionsMenu(!showOptionsMenu)}
            >
              <Dots />
              {showOptionsMenu && (
                <Options>
                  {authUser &&
                    authUser.claims &&
                    (authUser.claims.clientAdmin || authUser.claims.superAdmin) &&
                    scenario.settings && (
                      <button
                        onClick={() => history.push(ROUTES.SCENARIO_EDITOR + '/' + scenario.id)}
                      >
                        <Edit /> <span>Edit</span>
                      </button>
                    )}
                  <button onClick={() => archiveScenario()}>
                    <Archive /> <span>Archive</span>
                  </button>
                  {/* {scenario.sessions &&
                    scenario.sessions.filter(session => session.exchangesChanged > 0).length >=
                      1 && (
                      <button onClick={() => setSessionsOpen(!sessionsOpen)}>
                        <Menu /> <span>{!sessionsOpen ? 'Show sessions' : 'Hide sessions'}</span>
                      </button>
                    )} */}
                </Options>
              )}
            </MenuContainerFixed>
          )}
        </ListItemContainer>
      )}

      {medium && !loading && (
        <FullScenarioFollowUp
          scenarioImage={
            scenario.settings && scenario.settings.coverImage
              ? scenario.settings.coverImage
              : 'relaxed_vr.png'
          }
          enterScenario={enterScenario}
          renderTimesPlayed={renderTimesPlayed}
          unArchiveScenario={unArchiveScenario}
          scenario={scenario}
        />
      )}

      {archived && !loading && (
        <ArchivedScenarioFollowUp
          unArchiveScenario={unArchiveScenario}
          renderTimesPlayed={renderTimesPlayed}
          scenario={scenario}
        />
      )}
    </>
  );
};

const FullScenarioFollowUp = ({
  enterScenario,
  renderTimesPlayed,
  loading,
  scenario,
  scenarioImage,
  authUser,
}) => {
  const [imageError, setImageError] = useState(false);

  return (
    <>
      {loading ? (
        <ListItemWrapper isLoading={true}>
          <NoImageDiv></NoImageDiv>
          <ItemContainer>
            <div
              style={{
                width: '200px',
                height: '24px',
                background: 'var(--grey-5)',
                margin: '0 20px',
              }}
            ></div>
          </ItemContainer>
          <InfoContainer>
            <div style={{ width: '300px', height: '14px', background: 'var(--grey-5)' }}></div>
            <div
              style={{
                width: '160px',
                height: '14px',
                background: 'var(--grey-5)',
                marginTop: '2px',
              }}
            ></div>
          </InfoContainer>
        </ListItemWrapper>
      ) : (
        <ListItemWrapper
          clickable
          data-cy="enterScenario"
          onClick={() => enterScenario()}
          error={scenario.error}
        >
          {scenarioImage && !scenario.error && !imageError && (
            <ScenarioImageE
              onError={() => setImageError(true)}
              src={coverImageURL(scenarioImage)}
            />
          )}

          {(loading || imageError) && !scenario.error && <NoImageDiv></NoImageDiv>}

          {!loading && scenario.error && (
            <ErrorCircle
              style={{
                width: '50px',
                color: 'var(--red)',
              }}
            />
          )}

          <ItemContainer>
            <Nametext>
              {scenario && scenario.settings && scenario.settings.scenarioName
                ? scenario.settings.scenarioName
                : scenario.id}
              <br />
              {authUser && authUser.claims && authUser.claims.superAdmin && (
                <span style={{ fontSize: '12px', color: 'var(--grey-2)' }}>{scenario.id}</span>
              )}
            </Nametext>
          </ItemContainer>

          <TimesPlayedContainer>
            {scenario.userData && scenario.userData.sessionCount && (
              <p
                style={{
                  fontSize: '12px',
                  fontWeight: '600',
                  padding: '5px',
                  color: 'var(--grey-1)',
                }}
              >
                Times played
              </p>
            )}
            {scenario.userData && scenario.userData.sessionCount && (
              <TimesPlayedWrapper bgColor={'#4B4B4B'} flexAlign={'flex-start'}>
                {renderTimesPlayed(parseInt(scenario.userData.sessionCount))}
                {scenario.userData.sessionCount > 7 && <span style={{fontWeight: 'bold'}}>x {scenario.userData.sessionCount}</span>}
              </TimesPlayedWrapper>
            )}
          </TimesPlayedContainer>

          <InfoContainer>
            {scenario.settings && scenario.settings.scenarioText && (
              <SmallText>{scenario.settings.scenarioText}</SmallText>
            )}
            {scenario.error && <h5 style={{ color: 'var(--red)' }}>{scenario.error}</h5>}
          </InfoContainer>

          {/* <div style={{ width: '200px' }}></div> */}
        </ListItemWrapper>
      )}
    </>
  );
};

const ArchivedScenarioFollowUp = ({ renderTimesPlayed, unArchiveScenario, scenario }) => {
  const [showArchivesMenu, setShowArchivesMenu] = useState(false);

  return (
    <ListItemWrapper>
      <ItemContainer>
        {scenario && scenario.settings && <Nametext>{scenario.settings.scenarioName}</Nametext>}
        {scenario &&
          (!scenario.settings || (scenario.settings && !scenario.settings.scenarioName)) && (
            <Nametext>{scenario.id}</Nametext>
          )}
      </ItemContainer>

      {scenario.userData && scenario.userData.archived && (
        <ItemContainer>
          <SmallText>{scenario.userData.archived.slice(0, 10)}</SmallText>
        </ItemContainer>
      )}

      <ItemContainer>
        <SmallText>85%</SmallText>
      </ItemContainer>

      <TimesPlayedContainer>
        {scenario.userData && scenario.userData.sessionCount && (
          <TimesPlayedWrapper bgColor={'#4B4B4B'} flexAlign={'flex-start'}>
            {renderTimesPlayed(parseInt(scenario.userData.sessionCount))}
          </TimesPlayedWrapper>
        )}
      </TimesPlayedContainer>

      <MenuContainerFixed onClick={() => setShowArchivesMenu(!showArchivesMenu)} top={'10px'}>
        <Dots />
        {showArchivesMenu && (
          <Options>
            <button onClick={() => unArchiveScenario()}>
              <Archive /> <span>Unarchive</span>
            </button>
          </Options>
        )}
      </MenuContainerFixed>
    </ListItemWrapper>
  );
};

// const SessionListWidget = ({ sessions, maxSessions, inScenario}) => {
//   const { state } = useContext(AdminFilterContext);

//   const mapScenarioDataToSession = sessionsArr => {
//     let mappedSessions = sessionsArr.map(session => ({
//       scenario: state.scenarios.filter(scenario => scenario.id === session.scenario_id)[0],
//       ...session,
//     }));

//     return mappedSessions;
//   };

//   useEffect(() => {
//     if (state.scenarios && sessions) {
//       mapScenarioDataToSession(sessions);
//     }
//   }, []);

//   const sessionsToShow = sessionsArr => {
//     let speakSessions = sessionsArr.filter(session => session.exchangesChanged > 0);
//     let sortedByDate = speakSessions.sort((a, b) => (a.date > b.date ? 1 : -1)).reverse();
//     if (sessionsArr.length > maxSessions) {
//       return mapScenarioDataToSession(sortedByDate.slice(0, maxSessions));
//     } else {
//       return mapScenarioDataToSession(sortedByDate);
//     }
//   };

//   return (
//     <ul>
//       <ListHeader>
//         {inScenario ? <p>Number</p> : <p>Name</p>}
//         <p>Played</p>
//         <p>Skill level</p>
//         <p>Question results</p>
//         <p></p>
//       </ListHeader>
//       {sessionsToShow(sessions).map(session => (
//         <SessionListItem
//           key={session.id}
//           session={session}
//           inScenario={inScenario ? true : false}
//         />
//       ))}
//     </ul>
//   );
// };

// const SessionListItem = props => {
//   const [scores, setScores] = useState(null);
//   const [skillLevel, setSkillLevel] = useState(null);

//   useEffect(() => {
//     if (
//       props.session.events &&
//       props.session.scenario &&
//       props.session.scenario.settings &&
//       props.session.scenario.settings.exchangesCount
//     ) {
//       let playerStatements = props.session.events.filter(
//         events => events.event_type === 'PLAYER_STATEMENT',
//       );
//       let tempScores = playerStatements.map(ps => ps.score).filter(score => score !== null);
//       let sum = (a, b) => a + b;
//       if (tempScores && tempScores.length >= 1) {
//         let tempSkillLevel = (
//           (tempScores.reduce(sum) * 100) /
//           (parseInt(props.session.scenario.settings.exchangesCount, 10) * 20)
//         ).toFixed(0);
//         setSkillLevel(tempSkillLevel);
//       }
//       setScores(tempScores);
//     }
//   }, []);

//   return (
//     <ListItemWrapper>
//       <ItemContainer>
//         {props.session.scenario && !props.inScenario && (
//           <SmallText fontWeight={'600'}>
//             {props.session.scenario.settings && props.session.scenario.settings.scenarioName}
//           </SmallText>
//         )}
//         {!props.session.scenario ||
//           (props.inScenario && <SmallText fontWeight={'600'}>{props.session.id}</SmallText>)}
//       </ItemContainer>

//       <ItemContainer>
//         <SmallText>{props.session.date.slice(0, 10)}</SmallText>
//       </ItemContainer>

//       <ItemContainer>{skillLevel && <SmallText>{skillLevel + '%'}</SmallText>}</ItemContainer>

//       <ResultContainer>
//         {/* {scores && scores.length >= 1 && ( */}
//         <ScoreMeter
//           scores={scores}
//           exchangesCount={
//             props.session &&
//             props.session.scenario &&
//             props.session.scenario.settings &&
//             props.session.scenario.settings.exchangesCount
//           }
//         />
//         {/* )} */}
//       </ResultContainer>

//       <MenuContainer visibility={'hidden'}>
//         <Dots />
//       </MenuContainer>
//     </ListItemWrapper>
//   );
// };

const ScoreMeter = ({ scores, exchangesCount }) => {
  const [results, setResults] = useState(null);

  useEffect(() => {
    if (scores && scores.length !== 0) {
      const results = {
        good: [],
        moderate: [],
        poor: [],
        best: [],
      };
      scores.forEach(score => {
        switch (score) {
          case 30:
            results.best.push(score);
            break;
          case 20:
            results.good.push(score);
            break;
          case 10:
            results.moderate.push(score);
            break;
          case 0:
            results.poor.push(score);
            break;
          default:
            break;
        }
      });

      setResults(results);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scores]);

  return (
    <MeterWrapper>
      <Meter
        values={[
          {
            value: results && results.best.length,
            color: 'var(--key-color-2)',
            label: 'Best answer',
          },
          {
            value: results && results.good.length,
            color: 'var(--status-green)',
            label: 'Good',
          },
          {
            value: results && results.moderate.length,
            color: 'var(--status-yellow)',
            label: 'Moderate',
          },
          {
            value: results && results.poor.length,
            color: 'var(--status-red)',
            label: 'Poor',
          },
        ]}
        max={exchangesCount ? parseInt(exchangesCount, 10) : 10}
        round={true}
        size={'250px'}
        background={'#D4D4D4'}
        aria-label="meter"
        thickness={'10px'}
      />
    </MeterWrapper>
  );
};

export { ScoreMeter };

// export { SessionListWidget };

export default compose(withFirebase, withRouter)(ScenarioFollowUp);
