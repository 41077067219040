import React, { useState } from 'react';
import SideMenu from '../SideMenu';
import { NavContainer, SliderBlanket } from './style';
import withAuthorization from '../Session/withAuthorization';
import {HamMenu} from '../../styles/icons';

/**
 * This NavHeader displays only under a certain threshold/screenwidth,
 * currently 768px and is and should be synced with the mainNav
 */

const NavigationHeader = props => {
  const [sideMenuShow, setSideMenuShow] = useState(false);

  const menuClick = () => {
    sideMenuShow ? setSideMenuShow(false) : setSideMenuShow(true);
  };

  // useEffect(() => {
  //   if (props.authUser === null || undefined) {
  //     setSideMenuShow(false);;
  //   }
  // }, [props.authUser]);

  const maybeRenderBlanket = () => {
    if (sideMenuShow) {
      return (
        <SliderBlanket
          onClick={menuClick}
          /*
           * currently issues regarding the animationeffect
           * https://stackoverflow.com/questions/48282848/div-to-animate-and-then-disappear
           * Definitely troublesome
           */
        ></SliderBlanket>
      );
    }
  };

  return (
    <>
    {props.authUser && <NavContainer data-cy="navigationHeader">
      <button data-cy="tempBtn" onClick={menuClick}>
        <HamMenu/>
      </button>
      <SideMenu
        menuToggle={menuClick}
        openMenu={sideMenuShow}
        username={props.authUser ? props.authUser.displayName : null}
        history={props.history}
        authUser={props.authUser}
      />
      {maybeRenderBlanket()}
    </NavContainer>}
    </>
  );
};

const condition = authUser => !authUser || !!authUser;

export default withAuthorization(condition)(NavigationHeader);
