import styled from 'styled-components';

export const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 0 5px;
  margin-top: -10px;

  p {
    font-size: 14px;
  }

  h5 {
    font-size: 14px;
  }

  th {
    padding: 5px 16px 0;
    background-color: transparent;
    color: var(--grey-2);
    font-weight: bold;
    cursor: pointer;
    text-align: left;
    font-size: 14px;
    white-space: nowrap;
  }

  tbody {
    tr {
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07);
      border-radius: 20px;
    }

    thead {
      border-spacing: 0 5px;
      tr {
        box-shadow: none;
      }
    }
  }

  td {
    padding: 1em;
    background-color: white;
    color: var(--grey);
    font-weight: 500;
    position: relative;
  }

  td:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  td:last-child {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
  }
`;

export const PaginationContainer = styled.div`
  background: transparent;
  border-radius: 20px;
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 2px;
  padding: 0 15px;

  h5 {
    font-size: 12px;
    font-weight: 600;
    color: var(--grey-1);
  }

  button {
    background: transparent;
    text-decoration: underline;
    outline: none;
    border: none;
    font-size: 12px;
    font-weight: bold;
    color: var(--grey-1);
    transition: 0.4s;
    &:hover {
      transition: 0.4s;
      color: var(--grey);
    }
  }
`;
