import React, { createContext, useReducer } from 'react';
import { AdminFilterReducer } from './AdminFilterReducer';

export const AdminFilterContext = createContext();

const initialState = {
  users: null,
  clients: null,
  sessions: null,
  scenarios: null,
  client: null,
  user: null,
  selectedUser: null,
  selectedClient: null,
  loading: true,
};

const AdminFilterContextProvider = props => {
  const [state, dispatch] = useReducer(AdminFilterReducer, initialState, () => {
    return initialState;
  });

  return (
    <AdminFilterContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AdminFilterContext.Provider>
  );
};

export default AdminFilterContextProvider;
