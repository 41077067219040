import React, { useState, useEffect, useContext } from 'react';
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import { compose } from 'recompose';
import { AdminFilterContext } from '../../components/AdminFilter/AdminFilterContext';
import { Dialogue, DialogueTextWrapper } from '../Scenario/style';
import DialogueStatement from './DialogueStatement';
import ExchangesList from './ExchangesList';
import { hasIn } from 'lodash';
import Selector from '../../components/Selector';
import Loading from '../../components/Loading';
import { DEFAULTMSG } from '../../constants/loadingMessages';
import * as ROUTES from '../../constants/routes';
import { FrLabel, FrInput, FrButton } from '../../components/DesignSystem/style';
import { ReactComponent as Plus } from '../../assets/plus-icon.svg';
import { Trash } from 'styled-icons/boxicons-regular/Trash';
import { ChevUp, ChevDown } from '../../styles/icons';
import { Delete } from 'styled-icons/typicons/Delete';
import ScenarioSettings from './Settings';
import {
  DialogueEditorContainer,
  AddNewStatementWrapper,
  SelectScenarioContainer,
  CopyScenarioBtn,
  ErrorMsg,
  RowWrapper,
  SelectScenarioWrapper,
  ExchangeWrapper,
  MoveExchangeContainer,
  DeleteExchangeBtn,
  CancelNewExchange,
  DialogueContainer,
  DialogueMenuUL,
  ListItem,
  DescriptionContainer,
  DialogueTitle,
  CopyScenarioId,
} from './style';
import { TrainingContainer } from './ExchangesListStyles';

const ScenarioEditor = props => {
  const { state } = useContext(AdminFilterContext);
  const [scenarioToEdit, setScenarioToEdit] = useState('');
  const [editing, setEditing] = useState(null);
  const [addNewStatement, setAddNewStatment] = useState(false);
  const [importedScenario, setImportedScenario] = useState(false);
  const [exchangesCount, setExchangesCount] = useState(null);
  const [settingsObj, setSettingsObj] = useState(null);
  const [tempVoiceLines, setTempVoiceLines] = useState(null);
  const [dialogueExchanges, setDialogueExchanges] = useState(null);
  const [sheetErr, setSheetErr] = useState(null);
  const [audioCopyErr, setAudioCopyErr] = useState(null);
  const [scenarioToCopy, setScenarioToCopy] = useState(null);
  const [newScenarioName, setNewScenarioName] = useState('');
  const [aiEnabled, setAiEnabled] = useState(false);
  const [missingCharacterVoiceNames, setMissingCharacterVoiceNames] = useState(false);
  //Import scenario from sheets
  const [sheetUrl, setSheetUrl] = useState('');
  const [googleSheet, setGoogleSheet] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState('script');
  const [showCopyContainer, setShowCopyContainer] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [hasModes, setHasModes] = useState(false);
  const [noEdit, setNoEdit] = useState(false);

  //Map voiceline sheets to exchanges
  const mapSheetVoiceLines = scenarioSheet => {
    const newObj = {};
    let answerOrder = 0;

    for (let i = 0; i < scenarioSheet.length; i++) {
      if (scenarioSheet[i].gsx$followsline.$t && scenarioSheet[i].gsx$followsline.$t !== '') {
        answerOrder += 1;
        newObj[scenarioSheet[i].gsx$id.$t.toLowerCase()] = {
          name: scenarioSheet[i].gsx$name.$t,
          character: scenarioSheet[i].gsx$character.$t,
          toolTip: scenarioSheet[i].gsx$tooltip.$t,
          order: getOrder(answerOrder.toString()),
          mood: scenarioSheet[i].gsx$mood.$t,
          hidden: scenarioSheet[i].gsx$hidden.$t === 'Yes' ? true : false,
          followsLine: scenarioSheet[i].gsx$followsline.$t.toLowerCase(),
          hint: scenarioSheet[i].gsx$hint ? scenarioSheet[i].gsx$hint.$t : null,
        };
      } else {
        if (scenarioSheet[i].gsx$name.$t !== '' && scenarioSheet[i].gsx$tooltip.$t !== '') {
          newObj[scenarioSheet[i].gsx$id.$t.toLowerCase()] = {
            name: scenarioSheet[i].gsx$name.$t,
            character: scenarioSheet[i].gsx$character.$t,
            toolTip: scenarioSheet[i].gsx$tooltip.$t,
            order: getOrder(i.toString()),
            mood: scenarioSheet[i].gsx$mood.$t,
            hidden: scenarioSheet[i].gsx$hidden.$t === 'Yes' ? true : false,
          };
        }
      }
    }
    setTempVoiceLines(newObj);
  };

  //Get voicelines from sheet
  const getScenarioVoiceLinesFromSheet = sheetId => {
    let url = 'https://spreadsheets.google.com/feeds/list/' + sheetId + '/od6/public/full?alt=json';
    fetch(url)
      .then(response => response.json())
      .then(data => {
        mapSheetVoiceLines(data.feed.entry);
      })
      .catch(err => {
        console.error(err);
      });
  };

  //Get settings from sheet
  const getScenarioSettingsFromSheet = sheetId => {
    let url = 'https://spreadsheets.google.com/feeds/list/' + sheetId + '/3/public/full?alt=json';
    fetch(url)
      .then(response => response.json())
      .then(data => {
        let e = {};

        data.feed.entry.forEach(ent => {
          if (ent && ent.gsx$setting) {
            e[ent.gsx$setting.$t] = ent.gsx$value.$t;
          }
        });

        setGoogleSheet(sheetId);
        let tempSettings = {
          scenarioName: e.ScenarioName,
          scenarioText: e.ScenarioText,
          environment: e.Environment,
          exchangesCount: e.ExchangesCount,
          videoUrl: e.VideoUrl,
          videoTranscript: e.VideoTranscript,
          characters: {
            character_1: {
              displayName: e.CharactersDisplayNames.slice(
                0,
                e.CharactersDisplayNames.lastIndexOf(','),
              ),
              spawnPosition: e.CharacterSpawnPositions.slice(
                0,
                e.CharacterSpawnPositions.lastIndexOf(','),
              ),
              character: e.Characters.slice(0, e.Characters.lastIndexOf(',')),
            },
            character_2: {
              displayName: e.CharactersDisplayNames.slice(
                e.CharactersDisplayNames.lastIndexOf(',') + 1,
                e.CharactersDisplayNames.length,
              ),
              spawnPosition: e.CharacterSpawnPositions.slice(
                e.CharacterSpawnPositions.lastIndexOf(',') + 1,
                e.CharacterSpawnPositions.length,
              ),
              character: e.Characters.slice(e.Characters.lastIndexOf(',') + 1, e.Characters.length),
            },
            character_3: {
              spawnPosition: e.PlayerSpawnPosition,
              character: '',
            },
          },
        };
        if (scenarioToEdit === '' && tempSettings.scenarioName) {
          setScenarioToEdit(generateScenarioId(tempSettings.scenarioName));
          getScenarioVoiceLinesFromSheet(sheetId);
          setImportedScenario(true);
        }
        setSheetErr(null);
        setSettingsObj(tempSettings);
        window.alert(
          'This is a scenario imported from Google sheets, click the Create scenario button to enable editing',
        );
      })
      .catch(() => {
        setSheetErr('*Sheet could not be located');
        setSheetUrl('');
      });
  };

  useEffect(() => {
    if (scenarioToEdit && scenarioToEdit !== '' && sheetUrl === '') {
      props.firebase
        .newScenario(scenarioToEdit)
        .child('settings')
        .on('value', snapshot => {
          const scenSettings = snapshot.val();
          if (scenSettings && Object.keys(scenSettings).length >= 3 && scenSettings.scenarioName) {
            let settings = scenSettings;
            setScenarioToCopy(scenarioToEdit);
            setImportedScenario(false);
            setSettingsObj(settings);
            setGoogleSheet(scenSettings.googleSheet ? scenSettings.googleSheet : null);
          } else {
            props.firebase
              .newScenario(scenarioToEdit)
              .child('googleSheet')
              .once('value', snapshot => {
                const sheetId = snapshot.val();
                if (sheetId) {
                  setImportedScenario(true);
                  getScenarioVoiceLinesFromSheet(sheetId);
                  getScenarioSettingsFromSheet(sheetId);
                  setScenarioToCopy(false);
                } else {
                  changeScenario('');
                }
              });
          }
        });

      props.firebase
        .newScenario(scenarioToEdit)
        .child('voiceLines')
        .on('value', snapshot => {
          const scenVls = snapshot.val();
          if (scenVls) {
            setTempVoiceLines(scenVls);
          } else {
            setTempVoiceLines(null);
          }
        });

      props.firebase
        .newScenario(scenarioToEdit)
        .child('modes')
        .on('value', snapshot => {
          const tempModes = snapshot.val();
          if (tempModes) {
            setHasModes(true);
          } else {
            setHasModes(false);
          }
        });

      props.firebase
        .newClient(state.selectedClient)
        .child('settings')
        .child('visible')
        .on('value', snapshot => {
          const tempVisible = snapshot.val();
          if (tempVisible) {
            if (
              state.selectedClient !== scenarioToEdit.split('-')[0] && (props.authUser && props.authUser.claims && !props.authUser.claims.superAdmin)
            ) {
              setNoEdit(true);
            } else {
              setNoEdit(false);
            }
            if (tempVisible[scenarioToEdit]) {
              setIsVisible(true);
            } else {
              setIsVisible(false);
            }
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenarioToEdit]);

  useEffect(() => {
    return () => {
      props.firebase
        .newScenario()
        .child('settings')
        .off();
      props.firebase
        .newScenario()
        .child('voiceLines')
        .off();
      props.firebase
        .newScenario()
        .child('modes')
        .off();
      props.firebase
        .newClient()
        .child('settings')
        .child('visible')
        .off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.selectedClient) {
      props.firebase
        .newClient(state.selectedClient)
        .child('settings')
        .child('aiEnabled')
        .once('value', snapshot => {
          const tempAiEnabled = snapshot.val();
          if (tempAiEnabled) {
            setAiEnabled(tempAiEnabled);
            setMode('explain');
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedClient]);

  useEffect(() => {
    if (!state.loading) {
      if (props.match && props.match.params && props.match.params.id !== undefined) {
        let tempScenario = state.scenarios.find(scen => scen.id === props.match.params.id);
        if (tempScenario) {
          setScenarioToEdit(props.match.params.id);
        } else {
          props.history.push(ROUTES.SCENARIO_EDITOR);
        }
      } else {
        props.history.push(ROUTES.SCENARIO_EDITOR);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loading]);

  useEffect(() => {
    if (tempVoiceLines) {
      mapVoiceLinesToExchanges(tempVoiceLines);
    } else {
      if (dialogueExchanges) {
        setDialogueExchanges(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempVoiceLines]);

  useEffect(() => {
    if (settingsObj) {
      if (
        hasIn(settingsObj, 'character_1.voiceName') &&
        hasIn(settingsObj, 'character_2.voiceName')
      ) {
        setMissingCharacterVoiceNames(true);
      } else {
        setMissingCharacterVoiceNames(false);
      }
    }
  }, [settingsObj]);

  const addScenarioToDB = () => {
    try {
      if (scenarioToCopy) {
        let newScenarioId = generateScenarioId(newScenarioName);
        let scenarioExist = state.scenarios.filter(scenario => scenario.id === newScenarioId);
        settingsObj.scenarioName = newScenarioName;
        if (scenarioExist.length === 0) {
          setLoading(true);
          props.firebase.newScenario(newScenarioId).update({
            settings: settingsObj,
            voiceLines: tempVoiceLines,
          });
          if (tempVoiceLines) {
            props.firebase
              .doCopyScenarioFiles({
                oldId: scenarioToCopy,
                newId: newScenarioId,
                commit: true,
                force: true,
              })
              .then(() => {
                props.firebase
                  .newClient(state.selectedClient)
                  .child('settings')
                  .child('visible')
                  .update({ [newScenarioId]: false });
                changeScenario(newScenarioId);
                setNewScenarioName('');
                setLoading(false);
              })
              .catch(() => {
                setAudioCopyErr(
                  '*Error! Statement audio files could not be copied to ' + newScenarioName,
                );
                setLoading(false);
              });
          } else {
            changeScenario(newScenarioId);
            setNewScenarioName('');
            setLoading(false);
          }
        } else {
          setSheetErr('*Scenario with name ' + newScenarioName + ' already exists');
          setNewScenarioName('');
        }
      }
      if (importedScenario) {
        props.firebase.newScenario(scenarioToEdit).update(
          {
            settings: settingsObj,
            voiceLines: tempVoiceLines,
          },
          () => {
            changeScenario('');
          },
        );
      }
    } catch (err) {
      setSheetErr("Something went wrong.. Scenario settings couldn't be saved");
      setLoading(false);
    }
  };

  const mapVoiceLinesToExchanges = voiceLines => {
    let botEntries = [];
    let lookupTeacher = {};
    const exchangesArr = [];

    Object.keys(voiceLines).forEach(voiceLine => {
      if (voiceLine && voiceLines[voiceLine].followsLine) {
        lookupTeacher[voiceLines[voiceLine].followsLine] = {
          ...voiceLines[voiceLine],
          id: voiceLine,
        };
      } else {
        botEntries.push({ ...voiceLines[voiceLine], id: voiceLine });
      }
    });

    let sortedBotEntries = botEntries.sort((a, b) =>
      parseInt(a.order) > parseInt(b.order) ? 1 : -1,
    );

    setExchangesCount(sortedBotEntries.length);

    for (let i = 0; i < sortedBotEntries.length; i++) {
      exchangesArr.push({
        bot: sortedBotEntries[i],
        teacher: lookupTeacher[sortedBotEntries[i].id],
      });
    }
    setDialogueExchanges(exchangesArr);
  };

  const generateScenarioId = scenarioName => {
    let scenId =
      state.selectedClient +
      '-' +
      scenarioName.replace(/\s+/g, '-').replace(/[|<>"?*:\\.$[\]#/@]/g, '');
    return scenId.toLowerCase();
  };

  const saveScenarioSettings = sett => {
    if (sett.scenarioName && state.scenarios) {
      let scenarioId = generateScenarioId(sett.scenarioName);
      props.firebase
        .newScenario(scenarioToEdit && scenarioToEdit !== '' ? scenarioToEdit : scenarioId)
        .update({ googleSheet: googleSheet });
      props.firebase
        .newScenario(scenarioToEdit && scenarioToEdit !== '' ? scenarioToEdit : scenarioId)
        .child('settings')
        .update(
          {
            scenarioName: sett.scenarioName,
            scenarioText: sett.scenarioText,
            environment: sett.environment,
            videoUrl: sett.videoUrl ? sett.videoUrl : '',
            videoTranscript: sett.videoTranscript ? sett.videoTranscript : '',
            coverImage: sett.coverImage,
            locale: sett.locale ? sett.locale : null,
            exchangesCount: exchangesCount ? exchangesCount.toString() : '',
            characters: sett.characters ? sett.characters : null,
          },
          () => {
            if (!scenarioToEdit || scenarioToEdit === '') {
              props.firebase
                .newClient(state.selectedClient)
                .child('settings')
                .child('visible')
                .update({ [scenarioId]: false }, () => {
                  changeScenario(scenarioId);
                });
            }
          },
        );
    }
  };

  const getNextVlId = () => {
    if (tempVoiceLines) {
      let exchanges = Object.keys(tempVoiceLines).map(key => ({
        ...tempVoiceLines[key],
        id: key,
      }));

      let sortedExchanges = exchanges.sort((a, b) =>
        parseInt(a.order) > parseInt(b.order) ? 1 : -1,
      );
      if (sortedExchanges.length >= 1) {
        let vlId = sortedExchanges[exchanges.length - 1].id;
        return (parseInt(vlId.slice(vlId.lastIndexOf('_') + 1)) + 1).toString();
      } else {
        return '0';
      }
    } else {
      return '0';
    }
  };

  const getFollowsId = () => {
    if (tempVoiceLines) {
      let exchanges = Object.keys(tempVoiceLines).map(key => ({
        ...tempVoiceLines[key],
        id: key,
      }));
      if (exchanges.length >= 1) {
        let questions = [];
        exchanges.forEach(ex => {
          if (ex && !ex.followsLine) {
            questions.push(ex);
          }
        });

        let sortedQuestions = questions.sort((a, b) =>
          parseInt(a.order) > parseInt(b.order) ? 1 : -1,
        );
        let vlId = sortedQuestions[sortedQuestions.length - 1].id;
        return parseInt(vlId.slice(vlId.lastIndexOf('_') + 1)).toString();
      } else {
        return '0';
      }
    } else {
      return '0';
    }
  };

  const getOrder = vlId => {
    switch (vlId.length) {
      case 1:
        return '000' + vlId;
      case 2:
        return '00' + vlId;
      case 3:
        return '0' + vlId;
      case 4:
        return vlId;
      default:
        return;
    }
  };

  const saveStatement = (question, statementObj, newAudioFile) => {
    let lastVlId = (parseInt(getNextVlId()) - 1).toString();
    let vlId = getNextVlId();
    let followsId = getFollowsId();
    if (question) {
      if (newAudioFile) {
        props.firebase
          .scenarioVoiceLines(scenarioToEdit)
          .child(statementObj.id ? statementObj.id + '.ogg' : 'voice_line_' + vlId + '.ogg')
          .put(newAudioFile);
      }
      props.firebase
        .newScenario(scenarioToEdit)
        .child('voiceLines')
        .update({
          [statementObj.id ? statementObj.id : 'voice_line_' + vlId]: {
            order: statementObj.order ? statementObj.order : getOrder(vlId),
            name: statementObj.toolTip,
            character: settingsObj.characters.character_1.character,
            toolTip: statementObj.toolTip,
            mood: statementObj.mood,
            hidden: statementObj.hidden,
          },
        });
      props.firebase
        .newScenario(scenarioToEdit)
        .child('settings')
        .update({ exchangesCount: (exchangesCount + 1).toString() });
    } else {
      if (newAudioFile) {
        props.firebase
          .scenarioVoiceLines(scenarioToEdit)
          .child(
            statementObj.id
              ? statementObj.id + '.ogg'
              : 'voice_line_' + lastVlId + '_' + lastVlId + '.ogg',
          )
          .put(newAudioFile);
      }
      props.firebase
        .newScenario(scenarioToEdit)
        .child('voiceLines')
        .update({
          [statementObj.id ? statementObj.id : 'voice_line_' + lastVlId + '_' + lastVlId]: {
            order: statementObj.order ? statementObj.order : getOrder(lastVlId),
            character: settingsObj.characters.character_2.character,
            followsLine: statementObj.followsLine
              ? statementObj.followsLine
              : 'voice_line_' + followsId,
            mood: statementObj.mood,
            hidden: statementObj.hidden,
            hint: statementObj.hint,
            toolTip: statementObj.toolTip,
            name: statementObj.toolTip,
          },
        });
    }
  };

  const deleteAudioFile = statementId => {
    if (statementId) {
      props.firebase
        .scenarioVoiceLines(scenarioToEdit)
        .child(statementId + '.ogg')
        .delete()
        .catch(() => {
          console.error('Could not delete ' + statementId + ' audio file');
        });
    }
  };

  const deleteExchange = (teacher, bot) => {
    let teacherId = teacher ? teacher.id : null;
    let botId = bot ? bot.id : null;
    props.firebase
      .newScenario(scenarioToEdit)
      .child('voiceLines')
      .update({ [botId]: null, [teacherId]: null });
    deleteAudioFile(teacherId);
    deleteAudioFile(botId);
  };

  const changeScenario = scenario => {
    setDialogueExchanges(null);
    setTempVoiceLines(null);
    setSettingsObj(null);
    setScenarioToCopy(null);
    setImportedScenario(false);
    setAddNewStatment(false);
    setNewScenarioName('');
    setSheetUrl('');
    setEditing(null);
    setGoogleSheet(null);
    setSheetErr(null);
    setExchangesCount(null);
    if (scenario === '') {
      props.history.push(ROUTES.SCENARIO_EDITOR);
      setScenarioToEdit('');
    } else {
      setScenarioToEdit(scenario);
      props.history.push(ROUTES.SCENARIO_EDITOR + '/' + scenario);
    }
  };

  const newStatementIsQuestion = () => {
    if (dialogueExchanges && dialogueExchanges.length >= 1) {
      return dialogueExchanges[dialogueExchanges.length - 1].bot &&
        !dialogueExchanges[dialogueExchanges.length - 1].teacher
        ? false
        : true;
    } else {
      return true;
    }
  };

  const fetchSheet = sheetUrl => {
    let id;
    if (sheetUrl.length === 44) {
      id = sheetUrl;
    } else {
      id = sheetUrl
        .split('/d/')
        .pop()
        .split('/edit')[0];
    }
    getScenarioSettingsFromSheet(id);
  };

  const selectExistingScenario = () => {
    return (
      <SelectScenarioContainer>
        <SelectScenarioWrapper>
          <FrLabel width={'auto'} margin={'0'}>
            Edit existing scenario
          </FrLabel>
          <Selector
            arr={state.scenarios.map(scenario => scenario.id)}
            selectedEntity={scenarioToEdit}
            setSelectedEntity={changeScenario}
            defaultValText={'Select'}
            selectWidth={'100%'}
            background={'var(--grey-5)'}
          />
        </SelectScenarioWrapper>

        {scenarioToCopy && !showCopyContainer && (
          <SelectScenarioWrapper>
            <CopyScenarioBtn onClick={() => setShowCopyContainer(true)}>
              Copy scenario
            </CopyScenarioBtn>
          </SelectScenarioWrapper>
        )}

        {scenarioToCopy && showCopyContainer && (
          <SelectScenarioWrapper>
            {newScenarioName !== '' && state.selectedClient && (
              <CopyScenarioId>{'ID: ' + generateScenarioId(newScenarioName)}</CopyScenarioId>
            )}
            <FrLabel width={'auto'} margin={'0'}>
              Copy scenario
            </FrLabel>

            <FrInput
              value={newScenarioName}
              onChange={e => setNewScenarioName(e.target.value)}
              placeholder="New scenario name..."
              margin={'0 10px'}
              background={'var(--grey-5)'}
            />
            <CopyScenarioBtn
              disabled={
                newScenarioName === '' ||
                newScenarioName === (settingsObj && settingsObj.scenarioName)
              }
              onClick={() => addScenarioToDB()}
            >
              Copy
            </CopyScenarioBtn>
            <CopyScenarioBtn
              style={{ marginLeft: '5px', background: 'var(--grey-1)' }}
              onClick={() => setShowCopyContainer(false)}
            >
              Cancel
            </CopyScenarioBtn>
          </SelectScenarioWrapper>
        )}

        {scenarioToEdit === '' && (
          <SelectScenarioWrapper>
            <FrLabel width={'auto'} margin={'0'}>
              Import Scenario
            </FrLabel>

            <FrInput
              onChange={e => setSheetUrl(e.target.value)}
              value={sheetUrl}
              placeholder="Enter google sheet URL/ID..."
              margin={'0 10px'}
              background={'var(--grey-5)'}
            />
            {!importedScenario && (
              <CopyScenarioBtn
                disabled={!sheetUrl || sheetUrl.length < 1}
                onClick={() => fetchSheet(sheetUrl)}
              >
                Import
              </CopyScenarioBtn>
            )}
            {importedScenario && (
              <CopyScenarioBtn onClick={() => changeScenario('')}>Undo Import</CopyScenarioBtn>
            )}
          </SelectScenarioWrapper>
        )}
      </SelectScenarioContainer>
    );
  };

  const moveExchange = (currentOrder, targetVl, questionId) => {
    let copy = {
      ...tempVoiceLines,
    };
    copy[questionId].order = targetVl.order;
    copy[targetVl.id].order = currentOrder;
    props.firebase
      .newScenario(scenarioToEdit)
      .child('voiceLines')
      .update({ ...copy });
  };

  const changeVisibility = visible => {
    props.firebase
      .newClient(state.selectedClient)
      .child('settings')
      .child('visible')
      .update({ [scenarioToEdit]: !visible });
  };

  return (
    <DialogueEditorContainer>
      <RowWrapper>
        {sheetErr && (
          <h5>
            <ErrorMsg>{sheetErr}</ErrorMsg>
          </h5>
        )}
        {audioCopyErr && (
          <h5>
            <ErrorMsg>{audioCopyErr}</ErrorMsg>
          </h5>
        )}
        {noEdit && (
          <h5>
            <ErrorMsg>Edit disabled: This scenario belongs to another client</ErrorMsg>
          </h5>
        )}
      </RowWrapper>

      {state.scenarios && !loading && (
        <>
          <DialogueTitle>{!scenarioToEdit ? 'Create scenario' : 'Settings'}</DialogueTitle>
          <RowWrapper padding={'0 0 20px 0'}>{selectExistingScenario()}</RowWrapper>
          <ScenarioSettings
            saveSettings={saveScenarioSettings}
            settings={settingsObj}
            noEdit={noEdit}
            importedScenario={importedScenario}
            scenarioToEdit={scenarioToEdit}
            addScenarioToDB={addScenarioToDB}
            generateScenarioId={generateScenarioId}
            setGoogleSheet={setGoogleSheet}
            googleSheet={googleSheet}
            authUser={props.authUser}
            aiEnabled={aiEnabled}
          />
        </>
      )}

      {(!state.scenarios || loading) && (
        <Loading loadingText={DEFAULTMSG} width={'80px'} containerHeight={'400px'} />
      )}

      {settingsObj && !loading && (
        <>
          <DialogueTitle>
            Dialogue <br />
            {missingCharacterVoiceNames && (
              <span style={{ color: 'var(--status-red)', fontSize: '16px' }}>
                *Update voice settings to edit AI script
              </span>
            )}
          </DialogueTitle>
          <DialogueMenu
            aiEnabled={aiEnabled && tempVoiceLines === null}
            scriptEnabled={hasModes ? false : true}
            mode={mode}
            setMode={setMode}
            isSuperAdmin={
              props.authUser && props.authUser.claims && props.authUser.claims.superAdmin
            }
          />
          <DialogueContainer>
            <DialogueDescription mode={mode} />

            {settingsObj && settingsObj.characters && mode !== 'script' && (
              <ExchangesList
                mode={mode}
                scenarioId={scenarioToEdit}
                characters={settingsObj.characters}
                locale={settingsObj.locale}
                viewMode={missingCharacterVoiceNames ? true : false}
                hasModes={hasModes}
                isVisible={isVisible}
                changeVisibility={changeVisibility}
              />
            )}

            {scenarioToEdit &&
              scenarioToEdit !== '' &&
              settingsObj &&
              state.scenarios &&
              !loading &&
              mode === 'script' && (
                <Dialogue>
                  <ul
                    style={{
                      transition: '1s',
                      width: '100%',
                      minHeight: '500px',
                    }}
                  >
                    {dialogueExchanges &&
                      dialogueExchanges.map((exchange, index) => (
                        <div
                          key={index}
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            alignContent: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <ExchangeWrapper index={index}>
                            {exchange.bot && (
                              <DialogueTextWrapper bot={true}>
                                <DialogueStatement
                                  disableEditing={importedScenario || noEdit}
                                  newDialogueStatement={false}
                                  editing={editing}
                                  setEditing={setEditing}
                                  question={true}
                                  charName={
                                    settingsObj &&
                                    settingsObj.characters &&
                                    settingsObj.characters.character_1 &&
                                    settingsObj.characters.character_1.displayName
                                  }
                                  scenarioToEdit={scenarioToEdit}
                                  statement={exchange.bot}
                                  saveStatement={saveStatement}
                                />
                              </DialogueTextWrapper>
                            )}
                            {exchange.teacher && (
                              <DialogueTextWrapper bot={false}>
                                <DialogueStatement
                                  disableEditing={importedScenario || noEdit}
                                  newDialogueStatement={false}
                                  editing={editing}
                                  setEditing={setEditing}
                                  charName={
                                    settingsObj &&
                                    settingsObj.characters &&
                                    settingsObj.characters.character_2 &&
                                    settingsObj.characters.character_2.displayName
                                  }
                                  statement={exchange.teacher}
                                  scenarioToEdit={scenarioToEdit}
                                  saveStatement={saveStatement}
                                />
                              </DialogueTextWrapper>
                            )}

                            {!exchange.teacher && !importedScenario && !noEdit && (
                              <DialogueTextWrapper bot={false}>
                                <DialogueStatement
                                  newDialogueStatement={true}
                                  setAddNewStatment={setAddNewStatment}
                                  editing={editing}
                                  setEditing={setEditing}
                                  question={false}
                                  charName={
                                    settingsObj &&
                                    settingsObj.characters &&
                                    settingsObj.characters.character_2 &&
                                    settingsObj.characters.character_2.displayName
                                  }
                                  scenarioToEdit={scenarioToEdit}
                                  statement={{}}
                                  saveStatement={saveStatement}
                                />
                              </DialogueTextWrapper>
                            )}
                          </ExchangeWrapper>
                          {exchange.bot && !importedScenario && !noEdit && (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                alignContent: 'center',
                                justifyContent: 'center',
                                position: 'relative',
                              }}
                            >
                              <MoveExchangeContainer>
                                <button
                                  disabled={index === 0 || noEdit}
                                  onClick={() =>
                                    moveExchange(
                                      exchange.bot.order,
                                      dialogueExchanges[index - 1].bot,
                                      exchange.bot.id,
                                    )
                                  }
                                >
                                  <ChevUp />
                                </button>
                                <button
                                  disabled={index === dialogueExchanges.length - 1 || noEdit}
                                  onClick={() =>
                                    moveExchange(
                                      exchange.bot.order,
                                      dialogueExchanges[index + 1].bot,
                                      exchange.bot.id,
                                    )
                                  }
                                >
                                  <ChevDown />
                                </button>
                              </MoveExchangeContainer>
                              {dialogueExchanges.length - 1 === index && (
                                <DeleteExchangeBtn
                                  disable={noEdit}
                                  onClick={() => deleteExchange(exchange.bot, exchange.teacher)}
                                >
                                  <Trash />
                                </DeleteExchangeBtn>
                              )}
                            </div>
                          )}
                        </div>
                      ))}

                    {(addNewStatement || !dialogueExchanges) && !importedScenario && !noEdit && (
                      <ExchangeWrapper>
                        <CancelNewExchange onClick={() => setAddNewStatment(false)}>
                          <Delete />
                        </CancelNewExchange>
                        <DialogueTextWrapper bot={newStatementIsQuestion()}>
                          <DialogueStatement
                            newDialogueStatement={true}
                            setAddNewStatment={setAddNewStatment}
                            editing={editing}
                            setEditing={setEditing}
                            question={
                              dialogueExchanges &&
                              dialogueExchanges[dialogueExchanges.length - 1].bot &&
                              !dialogueExchanges[dialogueExchanges.length - 1].teacher
                                ? false
                                : true
                            }
                            charName={
                              dialogueExchanges &&
                              dialogueExchanges[dialogueExchanges.length - 1].bot &&
                              !dialogueExchanges[dialogueExchanges.length - 1].teacher
                                ? settingsObj.characters.character_2.displayName
                                : settingsObj.characters.character_1.displayName
                            }
                            scenarioToEdit={scenarioToEdit}
                            statement={{}}
                            saveStatement={saveStatement}
                          />
                        </DialogueTextWrapper>
                      </ExchangeWrapper>
                    )}

                    {!importedScenario &&
                      !noEdit &&
                      !addNewStatement &&
                      dialogueExchanges &&
                      dialogueExchanges[dialogueExchanges.length - 1].bot &&
                      dialogueExchanges[dialogueExchanges.length - 1].teacher && (
                        <AddNewStatementWrapper>
                          <button onClick={() => setAddNewStatment(true)}>
                            <Plus /> <h5>Add dialogue</h5>
                          </button>
                        </AddNewStatementWrapper>
                      )}
                  </ul>
                </Dialogue>
              )}
          </DialogueContainer>
        </>
      )}

      {settingsObj && tempVoiceLines && !hasModes && !noEdit && !importedScenario && (
        <TrainingContainer>
          <FrButton
            onClick={() => changeVisibility(isVisible)}
            disabled={tempVoiceLines === null || noEdit}
            background={!isVisible ? 'var(--key-color-2)' : 'var(--grey-2)'}
            margin={'0 5px 0 0'}
          >
            {!isVisible ? 'Publish' : 'Unpublish'}
          </FrButton>
          <FrButton
            onClick={() =>
              props.history.push(ROUTES.SCENARIOS + ROUTES.SCENARIO + '/' + props.match.params.id)
            }
            disabled={!isVisible}
          >
            View this scenario
          </FrButton>
        </TrainingContainer>
      )}
    </DialogueEditorContainer>
  );
};

export const DialogueDescription = ({ mode }) => (
  <DescriptionContainer>
    {mode === 'script' && (
      <>
        <p>
          In this mode, the bot will only follow the scripted dialogue in the order shown below,
          regardless of what the player really says.
        </p>
        <p style={{ marginTop: '10px' }}>
          Write down what the bot should say, and what the player is supposed to respond. When you
          are happy with the texts, record all statements as OGG audio files and upload to each
          statement.
        </p>
      </>
    )}
    {mode === 'explain' && (
      <p>
        Write topics that should be mentioned for a good explain element of the dialogue. Give
        multiple correct answers for what the player can say. Give at least one example of what the
        bot can respond to this. Make examples short and to the point.
      </p>
    )}
    {mode === 'ask' && (
      <p>
        Ask questions about the bot to understand their needs and key information. The player should
        remember to ask these questions and to remember their answer.
      </p>
    )}
    {mode === 'answer' && (
      <p>
        In this scenario element, the player needs to answer random questions from the bot. The
        player needs to memorize appropriate answers and can practice on them like flash cards. Add
        as many bot questions as you want along with acceptable answers from the player.
      </p>
    )}
    {mode === 'generic' && (
      <p>
        These are various responses the bot can say both inside and outside any scenario. Feel free
        to customize them but you may need to know the internals of the bot to understand when a
        response would be used.
      </p>
    )}
  </DescriptionContainer>
);

export const DialogueMenu = ({ mode, setMode, isSuperAdmin, aiEnabled, scriptEnabled }) => {
  return (
    <DialogueMenuUL>
      {scriptEnabled && (
        <ListItem selected={mode === 'script'} onClick={() => setMode('script')}>
          Script
        </ListItem>
      )}
      {aiEnabled && (
        <>
          <ListItem selected={mode === 'explain'} onClick={() => setMode('explain')}>
            Explain
          </ListItem>
          <ListItem selected={mode === 'ask'} onClick={() => setMode('ask')}>
            Ask
          </ListItem>
          <ListItem selected={mode === 'answer'} onClick={() => setMode('answer')}>
            Answer
          </ListItem>
          {isSuperAdmin && (
            <ListItem selected={mode === 'generic'} onClick={() => setMode('generic')}>
              Bot Responses
            </ListItem>
          )}
        </>
      )}
    </DialogueMenuUL>
  );
};

const condition = authUser =>
  authUser && (authUser.claims.clientAdmin || authUser.claims.superAdmin);

export default compose(withAuthorization(condition), withFirebase)(ScenarioEditor);

// import CollapsibleContainer from '../../components/CollapsibleContainer';

/* {state.scenarios && !loading && (
        <CollapsibleContainer
          label={!scenarioToEdit ? 'Create scenario' : 'Settings'}
          isOpen={!scenarioToEdit || !dialogueExchanges}
          disabled={!scenarioToEdit}
          padding={'10px 0 20px 0'}
        >
        </CollapsibleContainer>
      )} */

// props.firebase.newScenario(scenarioToEdit).on('value', snapshot => {
//   const scenObj = snapshot.val();

//   if (scenObj && scenObj.settings && Object.keys(scenObj.settings).length >= 3) {
//     let settings = scenObj.settings;
//     setScenarioToCopy(scenarioToEdit);
//     setImportedScenario(false);
//     setSettingsObj(settings);
//     setGoogleSheet(scenObj.googleSheet ? scenObj.googleSheet : null);

//     if (scenObj.voiceLines) {
//       setTempVoiceLines(scenObj.voiceLines);
//     } else {
//       setTempVoiceLines(null);
//     }
//   }

// if (
//   scenObj &&
//   scenObj.googleSheet &&
//   (!scenObj.settings || !scenObj.settings.scenarioName) &&
//   !scenObj.voiceLines
// ) {
//   setImportedScenario(true);
//   getScenarioVoiceLinesFromSheet(scenObj.googleSheet);
//   getScenarioSettingsFromSheet(scenObj.googleSheet)
//   setScenarioToCopy(false);
// });

// useEffect(() => {
//   if (settingsSaved === true) {
//     let savedTimer = setTimeout(() => {
//       setSettingsSaved(false);
//     }, 2000);
//     return () => clearTimeout(savedTimer);
//   }
//   // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [settingsSaved]);
