import React, { useEffect, useContext, useState } from 'react';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import { compose } from 'recompose';
import { AdminFilterContext } from './AdminFilterContext';
import { SuperAdminBar } from './style';
import { FrButton } from '../DesignSystem/style';
import { ErrorCircle } from 'styled-icons/boxicons-regular/ErrorCircle';
import styled from 'styled-components';
import { smoothIn } from '../../styles/keyframes';
// import { settingsFormat } from '../../helpFunctions/scenario';
// import { Check } from 'styled-icons/boxicons-regular/Check';
// import Loading from '../Loading';

const AdminFilter = props => {
  const { dispatch, state } = useContext(AdminFilterContext);
  const [resetComplete, setResetComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  // Map scenarios with active client
  const scenariosMappedwithClient = (scenarios, clientSettings, authUser) => {
    if (scenarios && scenarios.length > 0 && clientSettings) {
      const tempScenarios = scenarios.map(scen => ({
        ...scen,
        visible:
          clientSettings.visible && clientSettings.visible[scen.id] !== undefined
            ? clientSettings.visible[scen.id]
            : null,
        mandatory:
          clientSettings.mandatory && clientSettings.mandatory[scen.id] !== undefined
            ? clientSettings.mandatory[scen.id]
            : null,
      }));

      if (authUser.claims && authUser.claims.superAdmin) {
        if (authUser.claims.superAdmin) {
          return tempScenarios;
        }
      } else if (authUser.claims.clientAdmin) {
        return tempScenarios.filter(scenario => scenario.visible !== null);
      } else {
        return tempScenarios.filter(scenario => scenario.visible === true);
      }
    } else {
      return [];
    }
  };

  // Get scenarios
  const getNewScenarios = clientSettings => {
    // dispatch({ type: 'SET_LOADING', loading: true });
    props.firebase.newScenarios().on('value', snapshot => {
      const scenariosObject = snapshot.val();
      if (scenariosObject) {
        const scenariosList = Object.keys(scenariosObject).map(key => ({
          ...scenariosObject[key],
          id: key,
        }));
        let filteredScenarios = scenariosList.filter(
          scen => scen.settings && scen.settings.scenarioName,
        );
        dispatch({
          type: 'SET_SCENARIOS',
          scenarios: scenariosMappedwithClient(filteredScenarios, clientSettings, props.authUser),
        });
      }
    });
  };

  // Get client
  const getClient = client => {
    props.firebase.newClient(client).on(
      'value',
      snapshot => {
        const clientObject = snapshot.val();
        if (clientObject) {
          if (clientObject.settings && clientObject.settings.visible) {
            getNewScenarios(clientObject.settings);
          } else {
            clientObject.settings = { visible: {}, mandatory: {} };
            getNewScenarios(clientObject.settings);
          }
        } else {
          dispatch({ type: 'SET_SCENARIOS', scenarios: [] });
        }
      },
      error => {
        console.error(error);
        dispatch({ type: 'SET_SCENARIOS', scenarios: [] });
      },
    );
  };

  // Get sessions
  const getUserSessions = () => {
    props.firebase.userSessions(state.selectedClient, state.selectedUser).on('value', snapshot => {
      const sessionObject = snapshot.val();
      if (sessionObject) {
        const sessionsList = Object.keys(sessionObject).map(key => ({
          clientId: state.selectedClient,
          ...sessionObject[key],
        }));
        dispatch({ type: 'SET_SESSIONS', sessions: sessionsList });
      } else {
        dispatch({ type: 'SET_SESSIONS', sessions: null });
      }
    });
  };

  // Get all clients
  const getClients = () => {
    props.firebase.newClients().on(
      'value',
      snapshot => {
        const clientsObject = snapshot.val();
        if (clientsObject) {
          const clientsList = Object.keys(clientsObject).map(key => ({
            id: key,
            ...clientsObject[key],
          }));
          dispatch({ type: 'SET_CLIENTS', clients: clientsList });
        } else {
          dispatch({ type: 'SET_CLIENTS', clients: [] });
        }
      },
      error => {
        dispatch({ type: 'SET_CLIENTS', clients: [] });
        console.error(error);
      },
    );
  };

  // Get all users - ( should be client specific later if !superAdmin )
  const getUsers = () => {
    props.firebase.newUsers().once(
      'value',
      snapshot => {
        const usersObject = snapshot.val();
        if (usersObject) {
          const users = Object.keys(usersObject).map(key => ({
            ...usersObject[key],
            id: key,
          }));
          dispatch({ type: 'SET_USERS', users: users });
        } else {
          dispatch({ type: 'SET_USERS', users: [] });
        }
      },
      error => {
        dispatch({ type: 'SET_USERS', users: [] });
        console.error(error);
      },
    );
  };

  const changeClient = () => {
    props.firebase
      .newUser(props.authUser.userId)
      .child('clients')
      .update({
        [Object.keys(props.authUser.clients)[0]]: null,
        [state.selectedClient]: true,
      });
  };

  useEffect(() => {
    dispatch({
      type: 'SET_SELECTED_CLIENT',
      selectedClient: Object.keys(props.authUser.clients)[0],
    });
    dispatch({ type: 'SET_SELECTED_USER', selectedUser: props.authUser.userId });

    return () => {
      dispatch({ type: 'RESET_STATE' });
      props.firebase.userSessions().off();
      props.firebase.newClients().off();
      props.firebase.newClient().off();
      props.firebase.newScenarios().off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (resetComplete) {
        setResetComplete(false);
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [resetComplete]);

  useEffect(() => {
    if (state.selectedUser && state.selectedClient) {
      if (props.authUser && props.authUser.claims && props.authUser.claims.superAdmin) {
        getClients();
      }
      if (
        props.authUser &&
        props.authUser.claims &&
        (props.authUser.claims.clientAdmin || props.authUser.claims.superAdmin)
      ) {
        getUsers();
      }
      getClient(state.selectedClient);
      getUserSessions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedUser, state.selectedClient]);

  const onChange = e => {
    dispatch({
      type: 'SET_SELECTED_CLIENT',
      selectedClient: e.target.value,
    });
  };

  // Resets all sessions from the demo account
  const resetDemoSessions = () => {
    setLoading(true);
    props.firebase
      .doClearDemoSessions({ client_id: 'fictiverealitycom', user_id: 'demofictiverealitycom' })
      .then(res => {
        console.log(res);
        setResetComplete(true);
        setLoading(false);
      })
      .catch(err => {
        if (err) {
          console.error(err);
          setLoading(false);
        }
      });
  };

  const sendVerificationEmail = () => {
    if (!emailSent) {
      props.firebase.doSendEmailVerification();
      setEmailSent(true);
    }
  };

  return (
    <>
      {state.clients &&
        state.selectedClient &&
        props.authUser &&
        props.authUser.claims &&
        props.authUser.claims.superAdmin && (
          <SuperAdminBar>
            <div>
              <h5>Using as</h5>
              <select
                data-cy="inputClient"
                onChange={e => onChange(e)}
                value={state.selectedClient}
              >
                {state.clients.map((client, index) => (
                  <option value={client.id} key={index}>
                    {client.id}
                  </option>
                ))}
              </select>
              {props.authUser.clients && (
                <FrButton
                  onClick={() => changeClient()}
                  margin={'0 0 0 10px'}
                  disabled={props.authUser.clients[state.selectedClient] === true}
                >
                  Permanently join client
                </FrButton>
              )}
            </div>
            <FrButton
              onClick={() => resetDemoSessions()}
              background={resetComplete ? 'var(--status-green)' : 'var(--grey-4)'}
              color={resetComplete ? 'white' : 'var(--grey-1)'}
              disabled={loading}
            >
              {resetComplete ? 'Reset Complete!' : 'Reset Demo'}
            </FrButton>
          </SuperAdminBar>
        )}

      {props.firebase &&
      props.firebase.auth &&
      props.firebase.auth.currentUser &&
      props.firebase.auth.currentUser.emailVerified === false ? (
      <ToastMessageContainer>
        {!emailSent && (
          <div>
            <ErrorCircle />
            <h5>Your email is not verified, check your mailbox for verification email.</h5>
          </div>
        )}
        <div>
          <h5>I have verified my email adress</h5>
          <button onClick={() => window.location.reload()}>Done</button>
          <h5>Can't find it?</h5>
          <button disabled={emailSent} onClick={() => sendVerificationEmail()}>
            {emailSent ? 'Email sent' : 'Resend email'}
          </button>
        </div>
      </ToastMessageContainer>
      ) : null}
    </>
  );
};

const ToastMessageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  padding: 5px;
  background: var(--status-yellow);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  animation: ${smoothIn} 0.4s;

  div {
    display: flex;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    h5 {
      margin: 0 5px;
    }

    svg {
      width: 25px;
    }

    button {
      border-radius: 20px;
      padding: 4px 15px;
      border: none;
      outline: none;
      font-weight: 600;
      color: var(--grey);
      transition: 0.4s;
      &:hover {
        transition: 0.4s;
        color: var(--key-color-2);
      }
    }
  }
`;

const condition = authUser => !!authUser;

export default compose(withAuthorization(condition), withFirebase)(AdminFilter);
