import React, { useState, useEffect } from 'react';
import { QuestionCircle } from 'styled-icons/fa-regular/QuestionCircle';
import TextareaAutosize from 'react-textarea-autosize';
import { CloudUpload } from 'styled-icons/boxicons-solid/CloudUpload';
import { Pause } from 'styled-icons/boxicons-regular/Pause';
import { Play } from 'styled-icons/boxicons-regular/Play';
import { Edit } from 'styled-icons/boxicons-regular/Edit';
import { Delete } from 'styled-icons/typicons/Delete';
import { ChevUp, ChevDown } from '../../styles/icons';
import { Star } from 'styled-icons/boxicons-regular/Star';
import { Circle } from 'styled-icons/boxicons-solid/Circle';
import ReactTooltip from 'react-tooltip';
import { withFirebase } from '../../components/Firebase';
import { SelectorContainer } from '../../components/Selector';
// import Selector from '../../components/Selector';
// import { FrError } from '../../components/DesignSystem/style';
import {
  FileInputContainer,
  NewExchangeCharTitle,
  RowWrapper,
  NewExchangeWrapper,
  Statement,
  StatementButton,
  SmallWrapper,
  PlayerButton,
  SaveStatementButton,
  ResetStatementButton,
  CollapsibleContainer,
  ExpandButton,
  ExpandButtonContainer,
  SkippedAnswer,
  ScoreTab,
  AutogeneratedText,
  PlayerButtonWrapper,
  Transcript,
} from './style';

const DialogueStatement = ({
  newDialogueStatement,
  editing,
  setEditing,
  question,
  charName,
  statement,
  scenarioToEdit,
  saveStatement,
  setAddNewStatment,
  disableEditing,
}) => {
  const [newToolTip, setNewToolTip] = useState('');
  const [newHint, setNewHint] = useState('');
  const [newAudioInput, setNewAudioInput] = useState(null);
  const [newMood, setNewMood] = useState('');
  const [audioFile, setAudioFile] = useState(null);
  // const moods = ['Neutral', 'Unaffected', 'Happy', 'Interested', 'InDoubt', 'Bored', 'Irritated'];
  const moods = [
    { id: 'neutral', label: 'Neutral', value: '🙂' },
    { id: 'unaffected', label: 'Unaffected', value: '😐' },
    { id: 'happy', label: 'Happy', value: '😄' },
    { id: 'interested', label: 'Interested', value: '🤓' },
    { id: 'inDoubt', label: 'In doubt', value: '🤔' },
    { id: 'bored', label: 'Bored', value: '😒' },
    { id: 'irritated', label: 'Irritated', value: '😤' },
  ];

  const isSaved =
    statement &&
    (!question ? newHint === statement.hint : true) &&
    newToolTip === statement.toolTip &&
    newMood === statement.mood &&
    newAudioInput === null;

  useEffect(() => {
    if (statement) {
      setNewToolTip(statement.toolTip ? statement.toolTip : '');
      setNewHint(statement.hint ? statement.hint : '');
      setNewMood(statement.mood ? statement.mood : '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statement]);

  const saveChanges = () => {
    let newStatement = {
      order: statement.order ? statement.order : null,
      id: statement.id ? statement.id : null,
      followsLine: statement.followsLine ? statement.followsLine : null,
      mood: newMood,
      hidden: false,
      hint: newHint,
      toolTip: newToolTip,
    };
    if (newAudioInput) {
      setAudioFile(null);
    }
    saveStatement(question, newStatement, newAudioInput);
    setEditing(null);
    if (newDialogueStatement) {
      resetValues();
    }
  };

  const resetValues = () => {
    setNewToolTip(statement.toolTip ? statement.toolTip : '');
    setNewHint(statement.hint ? statement.hint : '');
    setNewMood(statement.mood ? statement.mood : '');
    setNewAudioInput(null);
    setEditing(null);
    if (newDialogueStatement) {
      setAddNewStatment(false);
    }
  };

  return (
    <>
      {(newDialogueStatement || editing === statement.id) && (
        <Statement
          bot={question ? true : false}
          background={question ? 'var(--grey-5)' : 'var(--grey-4)'}
        >
          {editing && editing === statement.id && (
            <ResetStatementButton question={question ? true : false} onClick={() => resetValues()}>
              <Delete />
            </ResetStatementButton>
          )}

          <NewExchangeWrapper>
            {charName && (
              <NewExchangeCharTitle bot={question ? true : false}>
                {question ? charName + ' (bot)' : charName}
              </NewExchangeCharTitle>
            )}

            <h5 style={{ textAlign: 'left', padding: '5px' }}>Subtitles</h5>
            <TextareaAutosize
              required
              minRows={2}
              value={newToolTip}
              onChange={e => setNewToolTip(e.target.value)}
              placeholder="Write statement answer here..."
            />

            {!question && (
              <>
                <h5 style={{ textAlign: 'left', padding: '5px' }}>Hint</h5>
                <input
                  style={{ marginBottom: '5px' }}
                  onChange={e => setNewHint(e.target.value)}
                  value={newHint}
                  placeholder="(Optional) Write a hint to the answer..."
                ></input>
              </>
            )}

            <h5 style={{ textAlign: 'left', padding: '5px' }}>Avatar mood</h5>
            <RowWrapper question={question ? true : false}>
              <SelectorContainer
                margin={'0 0 10px 0'}
                fullWidth={'100%'}
                selectWidth={'50%'}
                background={'white'}
              >
                <select
                  value={newMood}
                  onChange={e => setNewMood(e.target.value)}
                  name="environment"
                >
                  <option value={''}>Select mood</option>
                  {moods.map((mood, index) => (
                    <option key={index} value={mood.id}>
                      {mood.label + ' ' + mood.value}
                    </option>
                  ))}
                </select>
              </SelectorContainer>
            </RowWrapper>

            <h5 style={{ textAlign: 'left', padding: '10px 5px 5px' }}>Audio</h5>
            <ExchangeFileInput
              selectedAudio={newAudioInput}
              setSelectedAudio={setNewAudioInput}
              audio={audioFile}
              settings={
                statement
                  ? {
                      text: newToolTip,
                      voiceLinePath: `${scenarioToEdit}/audio/${statement.id}.ogg`,
                      voiceLine: statement.id,
                    }
                  : null
              }
            />
          </NewExchangeWrapper>

          <SaveStatementButton
            disabled={isSaved}
            question={question}
            onClick={() => saveChanges(statement.id)}
          >
            {isSaved ? 'Saved' : 'Save'}
          </SaveStatementButton>
        </Statement>
      )}

      {editing !== statement.id && !newDialogueStatement && (
        <Statement
          background={question === true ? 'var(--grey-5)' : 'var(--grey-4)'}
          small
          bot={question ? true : false}
        >
          {charName && (
            <NewExchangeCharTitle bot={question ? true : false}>
              {question ? charName + ' (bot)' : charName}
            </NewExchangeCharTitle>
          )}

          <SmallWrapper question={question ? true : false}>
            {scenarioToEdit && (
              <AudioComponent
                scenarioToEdit={scenarioToEdit}
                statement={statement}
                setAudioFile={setAudioFile}
              />
            )}

            {statement.toolTip && <p>{statement.toolTip}</p>}

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
              }}
            >
              {!question && newHint && (
                <>
                  <ReactTooltip multiline={true} html={true} />
                  <QuestionCircle
                    style={{ width: '22px', marginBottom: '5px', color: 'var(--grey-1)' }}
                    data-tip={newHint}
                  />
                </>
              )}
              {editing !== statement.id && !disableEditing && (
                <StatementButton
                  disabled={editing !== null}
                  onClick={() => setEditing(statement.id)}
                >
                  <Edit style={{ width: '25px', color: 'var(--grey-1)' }} />
                </StatementButton>
              )}
            </div>
          </SmallWrapper>
        </Statement>
      )}
    </>
  );
};

const PlayerStatementComponent = ({
  charName,
  activeSession,
  exchange,
  bestAnswer,
  transcriptions,
  firebase,
}) => {
  const [showBest, setShowBest] = useState(false);
  const [showExample, setShowExample] = useState(false);
  const scoreColor = ['var(--red)', 'var(--yellow)', 'var(--green)', 'var(--key-color-2)'];
  const scoreLabel = ['Poor', 'Moderate', 'Great', 'Best'];
  const [transcript, setTranscript] = useState(null);
  const [bestTranscript, setBestTranscript] = useState(null);

  useEffect(() => {
    if (transcriptions && transcriptions[0] && transcriptions[0].transcript) {
      setTranscript(transcriptions[0].transcript);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcriptions]);

  useEffect(() => {
    if (bestAnswer && activeSession && exchange && exchange.bot && exchange.bot.id) {
      firebase
        .newSession(activeSession.client_id, activeSession.user_id, bestAnswer)
        .child('transcriptions')
        .child('answerto_' + exchange.bot.id)
        .child('transcript')
        .once('value', snapshot => {
          const tempTranscript = snapshot.val();
          if (tempTranscript) {
            setBestTranscript(tempTranscript);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bestAnswer, activeSession, exchange]);

  return (
    <>
      {exchange && (
        <Statement
          small
          background={exchange.player ? 'var(--key-color-2-gradient)' : 'var(--grey-5)'}
          bot={false}
        >
          {charName && (
            <NewExchangeCharTitle
              color={exchange.player ? 'var(--key-color-2)' : 'var(--grey-2)'}
              bot={false}
            >
              {charName}
            </NewExchangeCharTitle>
          )}

          {exchange.player ? (
            <SmallWrapper question={false}>
              {activeSession && (
                <AudioComponent
                  activeSession={activeSession}
                  statement={exchange.player.parameter}
                  isPlayer={true}
                />
              )}

              {transcript ? (
                <Transcript>{transcript}</Transcript>
              ) : (
                <Transcript noTranscript>No transcript available</Transcript>
              )}

              <div></div>
            </SmallWrapper>
          ) : (
            <SkippedAnswer> Skipped </SkippedAnswer>
          )}

          {exchange.score !== null && (
            <ScoreTab color={scoreColor[exchange.score / 10]}>
              {exchange.score === 30 ? <Star /> : <Circle />}
              {exchange.score !== undefined ? scoreLabel[exchange.score / 10] : 'Ungraded'}
            </ScoreTab>
          )}

          {transcript && <AutogeneratedText>Autogenerated text</AutogeneratedText>}
        </Statement>
      )}

      <ExpandButtonContainer>
        <ExpandButton disabled={!bestAnswer} show={showBest} onClick={() => setShowBest(!showBest)}>
          {/* <span>
            <Star /> Best Answer
          </span> */}
          Best Answer
          {!showBest ? <ChevDown /> : <ChevUp />}
        </ExpandButton>
        <ExpandButton
          disabled={!exchange.teacher}
          show={showExample}
          onClick={() => setShowExample(!showExample)}
        >
          Example Answer {!showExample ? <ChevDown /> : <ChevUp />}
        </ExpandButton>
      </ExpandButtonContainer>

      {bestAnswer && exchange && (
        <CollapsibleContainer show={showBest}>
          <Statement small background={'var(--grey-5)'} bot={false}>
            {charName && <NewExchangeCharTitle bot={false}>{'Best Answer'}</NewExchangeCharTitle>}
            <SmallWrapper question={false}>
              {activeSession && (
                <AudioComponent
                  bestAnswer={bestAnswer}
                  activeSession={activeSession}
                  statement={'answerto_' + exchange.bot.id}
                />
              )}

              {bestTranscript ? (
                <Transcript color={'var(--grey-1)'}>{transcript}</Transcript>
              ) : (
                <Transcript color={'var(--grey-1)'} noTranscript>
                  No transcript available
                </Transcript>
              )}

              <div></div>
            </SmallWrapper>
          </Statement>
          {bestTranscript && (
            <AutogeneratedText style={{ bottom: '0' }}>Autogenerated text</AutogeneratedText>
          )}
        </CollapsibleContainer>
      )}
      <CollapsibleContainer show={showExample}>
        {exchange && exchange.teacher && activeSession && (
          <DialogueStatement
            disableEditing={true}
            question={false}
            charName={'Example Answer'}
            scenarioToEdit={activeSession.scenario_id}
            statement={exchange.teacher}
          />
        )}
      </CollapsibleContainer>
    </>
  );
};

const PlayerStatement = withFirebase(PlayerStatementComponent);

export const useAudio = url => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [playing, toggle];
};

const AudioPlayer = ({ url, question, isPlayer }) => {
  const [playing, toggle] = useAudio(url);

  return (
    <PlayerButton isPlayer={isPlayer} question={question ? true : false} onClick={toggle}>
      {playing ? <Pause /> : <Play style={{ marginLeft: '3px' }} />}
    </PlayerButton>
  );
};

const AudioPlayerComponent = ({
  statement,
  question,
  activeSession,
  scenarioToEdit,
  bestAnswer,
  firebase,
  setAudioFile,
  isPlayer,
}) => {
  const [audio, setAudio] = useState(null);
  const [noAudio, setNoAudio] = useState(false);
  const [loading, setLoading] = useState(false);
  const isSafari = navigator.vendor.includes('Apple');

  const getScenarioAudioFile = () => {
    firebase
      .scenarioVoiceLine(scenarioToEdit, statement.id + (isSafari === true ? '.mp3' : '.ogg'))
      .getDownloadURL()
      .then(url => {
        setAudio(url);
        setAudioFile(url);
        setNoAudio(false);
        setLoading(false);
      })
      .catch(() => {
        setAudio(null);
        setNoAudio(true);
        setAudioFile(null);
        setLoading(false);
      });
  };

  const getUserAudioFile = () => {
    firebase
      .answerToVoiceLineAudio(
        activeSession.client_id,
        activeSession.user_id,
        bestAnswer ? bestAnswer : activeSession.id,
        statement + '.wav',
      )
      .getDownloadURL()
      .then(url => {
        setAudio(url);
        setNoAudio(false);
        setLoading(false);
      })
      .catch(() => {
        setNoAudio(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (statement) {
      setLoading(true);
      let audioTimer = setTimeout(() => {
        if (statement && activeSession) {
          getUserAudioFile();
        } else {
          getScenarioAudioFile();
        }
      }, 3000);
      return () => clearTimeout(audioTimer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statement]);

  return (
    <PlayerButtonWrapper>
      {audio && !loading && <AudioPlayer isPlayer={isPlayer} question={question} url={audio} />}
      {(noAudio || loading) && (
        <PlayerButton isPlayer={isPlayer} disabled={true}>
          <Play style={{ marginLeft: '3px' }} />
        </PlayerButton>
      )}
    </PlayerButtonWrapper>
  );
};

const FileInput = ({ selectedAudio, setSelectedAudio, audio, settings, firebase }) => {
  const [fileName, setFileName] = useState(null);
  const [audioPreview, setAudioPreview] = useState(null);
  // const [loading, setLoading] = useState(false);
  // const [autogenerateError, setAutoGenerateError] = useState(null);

  useEffect(() => {
    if (audio && !audioPreview) {
      setAudioPreview(audio);
      setFileName(settings.voiceLine + '.ogg');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio]);

  useEffect(() => {
    if (!selectedAudio && fileName && audioPreview) {
      setFileName(null);
      setAudioPreview(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAudio]);

  const onChange = audioFile => {
    if (audioFile) {
      let blob = window.URL || window.webkitURL;
      setAudioPreview(null);
      setFileName(audioFile.name);
      setSelectedAudio(audioFile);
      setAudioPreview(blob.createObjectURL(audioFile));
    }
  };

  // const generateAudio = () => {
  //   setLoading(true);
  //   firebase
  //     .doGenerateSpeech({ text: settings.text, voiceLinePath: settings.voiceLinePath })
  //     .then(res => {
  //       if (res && res.data && res.data.url) {
  //         setAudioPreview(res.data.url[0]);
  //         setFileName(settings.voiceLine + '.ogg');
  //       } else {
  //         setAutoGenerateError('Audio transcript is not available');
  //       }
  //       setLoading(false);
  //     })
  //     .catch(err => {
  //       if (err) {
  //         setAutoGenerateError('Audio transcript is not available');
  //         setLoading(false);
  //       }
  //     });
  // };

  return (
    <FileInputContainer>
      <div>
        <label>
          <input type="file" accept=".ogg" onChange={e => onChange(e.target.files[0])} />
          <CloudUpload /> {audioPreview ? 'Replace .ogg audio' : 'Upload .ogg audio'}
        </label>
        {/* {settings && settings.text && (
          <button disabled={loading} onClick={() => generateAudio()}>
            {loading ? 'Loading...' : 'Autogenerate from text'}
          </button>
        )} */}
      </div>

      {/* {autogenerateError && <FrError>{autogenerateError}</FrError>} */}
      {audioPreview && (
        <audio controls key={audioPreview}>
          <source src={audioPreview} type="audio/wav" />
          Your browser does not support the audio element.
        </audio>
      )}
      {fileName && <p>{fileName}</p>}
    </FileInputContainer>
  );
};

const ExchangeFileInput = withFirebase(FileInput);

const AudioComponent = withFirebase(AudioPlayerComponent);

export default DialogueStatement;

export { PlayerStatement };

/* <Selector
    selectWidth={'50%'}
    arr={moods}
    selectedEntity={newMood}
    setSelectedEntity={setNewMood}
    defaultValText={'Select character mood'}
/> */
